import { JackpotConfig } from "../../../../constants/converters/jackpotConfig";
import { actions } from "../../common";
const baseUrl = "/api/v1/jackpots";
export default {
	...actions(`${baseUrl}/jackpots`, JackpotConfig),
	async loadListByGroup({ dispatch, commit }, groupId) {
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/groups/${groupId}/jackpots`,
					protectedPath: true,
					query: {
						order: "desc",
						order_by: "level_id",
						per_page: 500,
						page: 1,
					},
					payload: {
						win_stamp: { eq: null },
						deleted: { eq: null },
					},
				},
				{ root: true }
			);
			let data = response.data.data;

			if (JackpotConfig !== null) {
				data = data.map((item) => JackpotConfig.fromDTO(item));
			}

			commit("list", data);
			commit("pagination", response.data._meta_data.pagination);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load list by group", type: "error" },
				{ root: true }
			);

			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
};
