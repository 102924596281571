import { constants } from "../../../constants/constants";

export const state = () =>
	JSON.parse(
		sessionStorage.getItem(constants.breadcrumbsSessionStorageKey)
	) ?? {
		lastCrumb: undefined,
		poppedCrumbs: [],
		history: [],
		currentCrumbIndex: 0,
	};
