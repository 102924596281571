const state = {
	jackpotLevelList: {
		data: [],
		isLoading: false,
	},
	filteredLevelList: {
		data: [],
		isLoading: false,
	},
	createdLevel: {
		data: {
			id: undefined,
			name: "",
			created: undefined,
			deleted: undefined,
		},
		isLoading: false,
	},
	updatedLevel: {
		data: {
			id: undefined,
			name: "",
			created: undefined,
			deleted: undefined,
		},
		isLoading: false,
	},
	levelDetail: {
		data: {
			id: undefined,
			name: "",
			created: undefined,
			deleted: undefined,
		},
		isLoading: false,
	},
	invalidatedLevel: {
		data: {
			id: undefined,
			name: "",
			created: undefined,
			deleted: undefined,
		},
		isLoading: false,
	},
};

const getters = {
	jackpotLevelList: (state) => {
		return state["jackpotLevelList"];
	},
	getFilteredLevelList: (state) => {
		return state["filteredLevelList"];
	},
	getCreatedLevel: (state) => {
		return state["createdLevelData"];
	},
	getUpdatedLevel: (state) => {
		return state["updatedLevel"];
	},
	getLevelDetail: (state) => {
		return state["levelDetail"];
	},
	getInvalidatedLevel: (state) => {
		return state["invalidatedLevel"];
	},
};

const actions = {
	/** LIST LEVELS
	 * [bronze, silver , etc...]
	 * */
	async loadJackpotLevelList({ commit, dispatch }, queryParams = {}) {
		commit("setJackpotLevelListLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/levels",
				params: queryParams,
				protectedPath: true,
			});
			commit("setJackpotLevelListData", res.data);
			commit("setJackpotLevelListLoading", false);
		} catch (error) {
			commit("setJackpotLevelListData", undefined);
			commit("setJackpotLevelListLoading", false);
			console.error(" LOAD LEVEL LIST ERROR ", error);
		}
	},

	/** LIST FILTERED LEVELS
	 * [bronze, silver , etc...]
	 * */
	async loadFilteredLevelList(
		{ commit, dispatch },
		{ bodyPayload, queryParams = {} }
	) {
		commit("setFilteredLevelListLoading", true);
		try {
			const res = await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/levels",
				payload: bodyPayload,
				query: queryParams,
				protectedPath: true,
			});
			commit("setFilteredLevelListData", res.data);
			commit("setFilteredLevelListLoading", false);
		} catch (error) {
			commit("setFilteredLevelListData", undefined);
			commit("setFilteredLevelListLoading", false);
			console.error(" LOAD FILTERED LEVEL LIST ERROR ", error);
		}
	},

	/** DEFINE NEW LEVEL
	 * bronze, silver , etc...
	 * */
	async createLevel({ commit, dispatch }, bodyPayload) {
		commit("setCreatedLevelLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/levels",
				payload: bodyPayload,
				protectedPath: true,
			});
			commit("setCreatedLevelData", res.data);
			commit("setCreatedLevelLoading", false);
		} catch (error) {
			commit("setCreatedLevelData", undefined);
			commit("setCreatedLevelLoading", false);
			console.error(" CREATE LEVEL ERROR ", error);
		}
	},

	/** UPDATE LEVELS
	 * [bronze, silver , etc...]
	 * */
	async updateLevel({ commit, dispatch }, { level_name, bodyPayload }) {
		commit("setUpdatedLevelLoading", true);
		try {
			const res = await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/levels/" + level_name,
				payload: bodyPayload,
				protectedPath: true,
			});
			commit("setUpdatedLevelData", res.data);
			commit("setUpdatedLevelLoading", false);
		} catch (error) {
			commit("setUpdatedLevelData", undefined);
			commit("setUpdatedLevelLoading", false);
			console.error(" UPDATE LEVEL ERROR ", error);
		}
	},

	/** LEVEL DETAIL
	 * [bronze, silver , etc...]
	 * */
	async loadLevelDetail({ commit, dispatch }, level_name) {
		commit("setLevelDetailLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/levels/" + level_name,
				protectedPath: true,
			});
			commit("setLevelDetailData", res.data);
			commit("setLevelDetailLoading", false);
		} catch (error) {
			commit("setLevelDetailData", undefined);
			commit("setLevelDetailLoading", false);
			console.error(" LOAD LEVEL DETAIL ERROR ", error);
		}
	},

	/** INVALIDATE LEVEL
	 * [bronze, silver , etc...]
	 * */
	async invalidateLevel({ commit, dispatch }, level_name) {
		commit("setInvalidatedLevelLoading", true);
		try {
			const res = await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/levels/" + level_name,
				protectedPath: true,
			});
			commit("setInvalidatedLevelData", res.data);
			commit("setInvalidatedLevelLoading", false);
		} catch (error) {
			commit("setInvalidatedLevelData", undefined);
			commit("setInvalidatedLevelLoading", false);
			console.error(" INVALIDATE LEVEL ERROR ", error);
		}
	},
};

const mutations = {
	setJackpotLevelListData(state, data) {
		state.jackpotLevelList.data = data;
	},
	setJackpotLevelListLoading(state, value) {
		state.jackpotLevelList.isLoading = value;
	},
	setFilteredLevelListData(state, data) {
		state.filteredLevelList.data = data;
	},
	setFilteredLevelListLoading(state, value) {
		state.filteredLevelList.isLoading = value;
	},
	setCreatedLevelData(state, data) {
		state.createdLevel.data = data;
	},
	setCreatedLevelLoading(state, value) {
		state.createdLevel.isLoading = value;
	},
	setUpdatedLevelData(state, data) {
		state.updatedLevel.data = data;
	},
	setUpdatedLevelLoading(state, value) {
		state.updatedLevel.isLoading = value;
	},
	setLevelDetailData(state, data) {
		state.levelDetail.data = data;
	},
	setLevelDetailLoading(state, value) {
		state.levelDetail.isLoading = value;
	},
	setInvalidatedLevelData(state, data) {
		state.invalidatedLevel.data = data;
	},
	setInvalidatedLevelLoading(state, value) {
		state.invalidatedLevel.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
