const state = {
	groupsOnCasinoGet: {
		data: [],
		_meta_data: {
			order: "desc",
			order_by: "group_id",
			order_by_matched: "group_id",
			page: 1,
			per_page: 10,
			total_pages: 1,
			total_records: 1,
		},
	},
	groupsOnCasinoPost: {
		data: [],
		_meta_data: {
			_filter: {
				deleted: {
					eq: null,
				},
			},
			pagination: {
				order: "desc",
				order_by: "group_id",
				order_by_matched: "group_id",
				page: 1,
				per_page: 10,
				total_pages: 1,
				total_records: 1,
			},
		},
	},
};

const getters = {
	getGroupsOnCasinoGet: (state) => {
		return state["groupsOnCasinoGet"];
	},
	getGroupsOnCasinoPost: (state) => {
		return state["groupsOnCasinoPost"];
	},
};

const actions = {
	async loadGroupsOnCasinoGet(
		{ commit, dispatch },
		{ casino_id, queryParams }
	) {
		const res = await dispatch("apiCall/loganGet", {
			path: "/api/v1/jackpots/casinos/" + casino_id + "/groups",
			query: queryParams,
			protectedPath: true,
		});
		commit("setGroupsOnCasinoGet", res.data);
	},

	async loadGroupsOnCasinoPost(
		{ commit, dispatch },
		{ casino_id, bodyPayload, queryParams }
	) {
		const res = await dispatch("apiCall/loganPost", {
			path: "/api/v1/jackpots/casinos/" + casino_id + "/groups",
			payload: bodyPayload,
			query: queryParams,
			protectedPath: true,
		});
		commit("setGroupsOnCasinoPost", res.data);
	},
};

const mutations = {
	setGroupsOnCasinoGet(state, data) {
		state.groupsOnCasinoGet.data = data.data;
		state.groupsOnCasinoGet._meta_data = data._meta_data;
	},
	setGroupsOnCasinoPost(state, data) {
		state.groupsOnCasinoPost.data = data.data;
		state.groupsOnCasinoPost._meta_data = data._meta_data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
