import { constants } from "../../../constants/constants";
import router from "../../../router";
import getters from "./getters";
export default {
	pushCrumb(state, props) {
		state.lastCrumb = state.history[state.history.length - 1];
		const res = {
			...props,
			breadcrumbsIndex:
				props.params.breadcrumbs.index ?? state.currentCrumbIndex,
		};
		state.history.push(res);
		state.history = state.history.slice(-50);
		state.currentCrumbIndex++;
		sessionStorage.setItem(
			constants.breadcrumbsSessionStorageKey,
			JSON.stringify(state)
		);
	},

	setCrumbs(state, props) {
		state.lastCrumb = state.history[state.history.length - 1];
		if (Array.isArray(props)) {
			const res = props.map((e, breadcrumbsIndex) => ({
				...e,
				breadcrumbsIndex,
			}));
			state.history.push(res);
			state.currentCrumbIndex = res.length;
		} else {
			state.history.push(props);
			state.currentCrumbIndex = props.params.breadcrumbs.hard
				? 1
				: state.currentCrumbIndex + 1;
		}
		state.history = state.history.slice(-50);
		sessionStorage.setItem(
			constants.breadcrumbsSessionStorageKey,
			JSON.stringify(state)
		);
	},

	goToCrumb(state, index) {
		const { name, params, breadcrumbsIndex } =
			getters.shortCrumbsFromHistory(state)[index];
		router.push({
			name,
			params: {
				...params,
				breadcrumbs: {
					...params.breadcrumbs,
					index: breadcrumbsIndex,
				},
				query: {
					...params.query,
				},
			},
			query: {
				...params.query,
			},
		});
		sessionStorage.setItem(
			constants.breadcrumbsSessionStorageKey,
			JSON.stringify(state)
		);
		// state.crumbs = state.crumbs.slice(0, index + 1);
	},
	backToCrumb(state, index) {
		const tempHistory = [...state.history].reverse().slice(index).reverse();
		state.history = [...tempHistory];
		sessionStorage.setItem(
			constants.breadcrumbsSessionStorageKey,
			JSON.stringify(state)
		);
	},
	popCrumb(state) {
		state.lastCrumb = state.history[state.history.length - 1];
		state.poppedCrumbs.push(state.history.pop());
		if (state.lastCrumb.hard) {
			state.currentCrumbIndex =
				getters
					.crumbsFromHistory(state)
					.reduce(
						(prev, next) =>
							next.breadcrumbsIndex > prev ? next.breadcrumbsIndex : prev,
						0
					) + 1;
		}
		sessionStorage.setItem(
			constants.breadcrumbsSessionStorageKey,
			JSON.stringify(state)
		);
	},
	unPopCrumb(state) {
		state.lastCrumb = state.history[state.history.length - 1];
		state.history.push(state.poppedCrumbs.pop());
		sessionStorage.setItem(
			constants.breadcrumbsSessionStorageKey,
			JSON.stringify(state)
		);
	},
	clearPoppedCrumbs(state) {
		state.poppedCrumbs = [];
	},
};
