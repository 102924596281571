export default {
	init(state) {
		state.ticker = setInterval(() => {
			state.subscribers.forEach((fn) => {
				fn();
			});
		}, 10000);
	},
	subscribe(state, fn) {
		if (typeof fn !== "function") {
			console.error("not a fn");
			return;
		}
		state.subscribers.push(fn);
	},
	unsubscribe(state, fn) {
		if (typeof fn !== "function") {
			console.error("not a fn");
			return;
		}
		state.subscribers = state.subscribers.filter((el) => el != fn);
	},
};
