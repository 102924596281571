export default {
	list(state, payload) {
		state.list = payload;
	},
	updated(state, payload) {
		state.updated = payload;
	},
	topSessions(state, payload) {
		state.topSessions = payload;
	},
	topPlayers(state, payload) {
		state.topPlayers = payload;
	},
	listLoading(state, payload) {
		state.listLoading = payload;
	},
	topsLoading(state, payload) {
		state.topsLoading = payload;
	},
};
