export default {
	lastCrumb(state) {
		const tmp = state.history.flatMap((el) => el);
		return tmp[tmp.length - 2];
		// return state.history[state.history.length - 2];
	},
	currentCrumb(state) {
		// return state.history[state.history.length - 1];
		const tmp = state.history.flatMap((el) => el);
		return tmp[tmp.length - 1];
	},
	lastPoppedCrumb(state) {
		const tmp = state.poppedCrumbs.flatMap((el) => el);
		return tmp[tmp.length - 1];
	},
	crumbsFromHistory(state) {
		const tmp = [...state.history].flatMap((el) => el).reverse();
		return tmp
			.slice(0, tmp.findIndex((el) => el.params.breadcrumbs.hard) + 1)
			.reverse();
	},
	getHistory(state) {
		return state.history;
	},
	shortCrumbsFromHistory(state) {
		const tmp = [...state.history].flatMap((el) => el).reverse();
		const res = tmp
			.slice(0, tmp.findIndex((el) => el.params.breadcrumbs.hard) + 1)
			.reverse();

		for (let i = 0; i < state.currentCrumbIndex; i++) {
			const index = res.findIndex((el) => el.breadcrumbsIndex === i);
			const lastIndex = res.findLastIndex((el) => el.breadcrumbsIndex === i);
			res.splice(index, lastIndex - index);
		}
		return res.map((el, index) => ({ ...el, index }));
	},
};
