import { getters } from "../../common";
export default {
	...getters,
	casinos(state) {
		return state.casinos;
	},
	games(state) {
		return state.games;
	},
	casinoGroupMap(state) {
		const cToGMap = new Map();
		state.filteredGroups.forEach((group) =>
			group.casinos.forEach((casinoId) =>
				cToGMap.has(casinoId)
					? cToGMap.set(casinoId, cToGMap.get(casinoId).concat([group.name]))
					: cToGMap.set(casinoId, [group.name])
			)
		);
		return cToGMap;
	},
	getFilteredGroups(state) {
		const m = state.filteredGroups.reduce((agg, cur) => {
			agg.set(cur.name, 1);
			return agg;
		}, new Map());
		return Array.from(m.keys());
	},
};
