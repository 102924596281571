import qs from "qs";
import _ from "lodash";

import { mutations } from "../common";
export default {
	...mutations,
	query(state, payload) {
		state.list.metadata.query = payload;
	},
	pagination(state, payload) {
		state.list.metadata.pagination = payload;
	},
	filter(state, payload) {
		state.list.metadata.filter = payload;
	},
};
