import { state as defaultState } from "../common";
export const state = () => ({
	list: {
		data: [],
		metadata: {
			filter: {},
			pagination: {
				order: "asc",
				order_by: "id",
				page: 1,
				per_page: 10,
				total_pages: 0,
				total_records: 0,
			},
		},
	},
	detail: [],
});
