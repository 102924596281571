import { permControl } from "../../../constants/helpers";
import { constants } from "../../../constants/constants";

const baseUrl = "/api/v1/jackpots";
export default {
	async loadCasinos({ dispatch, commit }) {
		try {
			const res = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/meta/casinos",
					protectedPath: true,
				},
				{ root: true }
			);
			commit("casinos", res.data);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/meta/casinos`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load casinos", type: "error" },
				{ root: true }
			);
			commit("casinos", []);
			return [];
		}
	},
	async loadGames({ dispatch, commit, rootGetters }) {
		try {
			const res = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/meta/games",
					protectedPath: true,
				},
				{ root: true }
			);
			commit("games", res.data);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/meta/games`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load games", type: "error" },
				{ root: true }
			);

			commit("games", []);
			return [];
		}
	},
	async loadAllowedGames({ dispatch, commit, rootGetters }) {
		const studios = rootGetters.allowedStudios.map((el) => el.name);
		const listPerm = rootGetters.allowedSections;
		const hasPerm = permControl(listPerm, {
			key: "meta_games.list",
			val: constants.permissions.DELETE,
		});

		try {
			const res = await dispatch(
				"apiCall/loganPost",
				{
					path: "/api/v1/meta/game_studios/games",
					payload: { studios: studios },
					protectedPath: true,
				},
				{ root: true }
			);
			commit(
				"allowedGames",
				hasPerm ? res.data : res.data.filter((el) => el.removed === false)
			);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/meta/game_studios/games`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load allowed games", type: "error" },
				{ root: true }
			);

			commit("allowedGames", []);
			return [];
		}
	},
	async loadStudios({ dispatch, commit }) {
		try {
			const res = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/meta/game_studios",
					protectedPath: true,
				},
				{ root: true }
			);
			commit("gameStudios", res.data);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/meta/game_studios`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load game studios", type: "error" },
				{ root: true }
			);

			commit("gameStudios", []);
			return [];
		}
	},
	// ...actions(`${baseUrl}/jackpots`),
	// async loadListByGroup({ dispatch, commit }, groupId) {
	// 	try {
	// 		const response = await dispatch(
	// 			"apiCall/loganPost",
	// 			{
	// 				path: `${baseUrl}/groups/${groupId}/jackpots`,
	// 				protectedPath: true,
	// 				payload: {
	// 					win_stamp: { eq: null },
	// 					deleted: { eq: null },
	// 				},
	// 			},
	// 			{ root: true }
	// 		);
	// 		let data = response.data.data;

	// 		commit("list", data);
	// 		commit("pagination", response.data._meta_data.pagination);
	// 	} catch (e) {
	// 		console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
	// 		commit("list", []);
	// 		commit("pagination", {
	// 			total_pages: 0,
	// 			total_records: 0,
	// 		});
	// 	}
	// },
};
