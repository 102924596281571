export class Session {
	static fromDTO(dto) {
		return {
			...dto,
			netto: dto.netto / 100,
			sum_bet: dto.sum_bet / 100,
			sum_win: dto.sum_win / 100,
		};
	}
	static detailFromDTO(dto) {
		let _device;
		switch (dto.device) {
			case 0:
				_device = "Exhibition";
				break;
			case 1:
				_device = "Desktop";
				break;
			case 2:
				_device = "Phone";
				break;
			case 3:
				_device = "Tablet";
				break;
			default:
				_device = "Unknown";
		}
		return {
			...dto,
			netto: dto.netto / 100,
			sum_bet: dto.sum_bet / 100,
			sum_win: dto.sum_win / 100,
			min_bet: dto.min_bet / 100,
			max_bet: dto.max_bet / 100,
			start_balance: dto.start_balance / 100,
			balance: dto.balance / 100,
			avg_bet: dto.avg_bet / 100,
			_device,
		};
	}
}
