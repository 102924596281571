export const constants = {
	breadcrumbsSessionStorageKey: "KAJOT:Logan2:breadcrumbs",
	authLocalStorageKey: "KAJOT:Logan2:auth",
	settingsLocalStorageKey: "KAJOT:Logan2:userSettings",
	authRedirectLocalStorageKey: "KAJOT:Logan2:authRedirect",
	footerLocalStorageKey: "KAJOT:Logan2:footers",
	permissions: {
		READ: 0b0001,
		CREATE: 0b0100,
		EDIT: 0b0010,
		DELETE: 0b1000,
	},
};
export const OFFER_RULE_ACTIONS = {
	ROUNDS: "limit_spins",
	BET: "bet_amount",
	LOOSE: "loose_amount",
	SESSIONS: "start_session",
};
export const OFFER_RULE_MODES = {
	ONCE: "one_time",
	RECURRING: "recurring",
};
export const FEATURE_FLAGS = {
	FREE_GAMES_GAMBLE_SUPPORT: false,
};
export const ROUND_FLAGS = {
	joker_bonus: {
		title: "JB",
		tooltip: "Joker Bonus",
	},
	free_round: {
		title: "FR",
		tooltip: "Free Round",
	},
	auto_start: {
		title: "A",
		tooltip: "Auto Start",
		icon: "mdi-refresh-auto",
	},
	sound: {
		title: "S",
		tooltip: "Sound",
		icon: "mdi-volume-mute",
	},
};
