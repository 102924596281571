export class JackpotGroupConfig {
	static fromDTO(params) {
		const contrib = Math.round(params?.data?.contrib * 100 * 100) / 100;
		return {
			...params.data,
			contrib,
		};
	}
	static toDTO(params) {
		const contrib = params?.contrib / 100;
		const res = {
			...params,
			contrib,
		};
		delete res.id;
		return res;
	}
}
