export default {
	list(state) {
		return state.list;
	},
	updated(state) {
		return state.updated;
	},
	getTopSessions(state) {
		return state.topSessions;
	},
	getTopPlayers(state) {
		return state.topPlayers;
	},
	listLoading(state) {
		return state.listLoading;
	},
	getTopsLoading(state) {
		return state.topsLoading;
	},
};
