export class JackpotConfig {
	static fromDTO(params) {
		let contrib_bet_range = null;
		let contrib_ratio = null;
		let shares = null;
		let trim = null;

		if (
			params.contrib_bet_range !== null &&
			params.contrib_bet_range !== undefined
		) {
			contrib_bet_range = params.contrib_bet_range.map((val) => val * 100);
		}
		if (params.contrib_ratio !== null && params.contrib_ratio !== undefined) {
			contrib_ratio = params.contrib_ratio * 100;
		}
		if (params.shares !== null && params.shares !== undefined) {
			shares = params.shares.map((val) => val * 100);
		}
		if (params.trim !== null && params.trim !== undefined) {
			trim = params.trim * 100;
		}
		return {
			...params,
			contrib_bet_range,
			contrib_ratio,
			shares,
			trim,
		};
	}
	static toDTO(params) {
		let contrib_bet_range = undefined;
		let contrib_ratio = undefined;
		let shares = undefined;
		let trim = undefined;
		let mean = undefined;
		let variance = undefined;
		let k = undefined;

		if (
			params.contrib_bet_range !== null &&
			params.contrib_bet_range !== undefined
		) {
			contrib_bet_range = params.contrib_bet_range.map((val) => val / 100);
		}
		if (params.contrib_ratio !== null && params.contrib_ratio !== undefined) {
			contrib_ratio = params.contrib_ratio / 100;
		}
		if (params.shares !== null && params.shares !== undefined) {
			shares = params.shares.map((val) => val / 100);
		}
		if (params.trim !== null && params.trim !== undefined) {
			trim = params.trim / 100;
		}
		if (params.mean !== null && params.mean !== undefined) {
			mean = params.mean;
		}
		if (params.variance !== null && params.variance !== undefined) {
			variance = params.variance;
		}
		if (params.k !== null && params.k !== undefined) {
			k = params.k;
		}
		const res = {
			...params,
			contrib_bet_range,
			contrib_ratio,
			k,
			mean,
			shares,
			trim,
			variance,
		};
		delete res.created;
		delete res.updated;
		delete res.deleted;
		delete res.id;
		return res;
	}
}
