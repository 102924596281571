import * as fns from "date-fns";

export class Dashboard {
	static handleAggregation(acc, entry, date) {
		const desc = fns.format(date, "yyyy-MM-dd-HH");
		acc[desc] = acc[desc] || {
			date,
			year: fns.getYear(date),
			month: fns.getMonth(date),
			week: fns.getWeek(date),
			day: fns.getDate(date),
		};

		Object.entries(entry).forEach(([key, value]) => {
			if (key !== "time_stamp") {
				acc[desc][key] = (acc[desc][key] ?? 0) + value;
			}
		});

		return acc;
	}

	static computedMapper(value) {
		return {
			...value,
			avg_win: value.win_sum / value.round,
			avg_bet: value.bet_sum / value.round,
			rtp: value.win_sum / value.bet_sum,
			avg_length: value.round / value.session,
			netto: value.bet_sum - value.win_sum,
		};
	}

	static fromDTO(object) {
		const firstDate = fns.startOfMonth(fns.addMonths(new Date(), -2));
		const lastDate = Math.max(
			fns.endOfMonth(new Date()),
			fns.endOfWeek(new Date())
		);
		const dist = fns.differenceInHours(lastDate, firstDate);
		const m = new Map();
		for (let i = 0; i < dist; i++) {
			const tmp = fns.addHours(firstDate, i);
			m.set(tmp.toISOString(), {
				time_stamp: tmp.toISOString(),
				bet_sum: 0,
				win_sum: 0,
				round: 0,
				auto_start: 0,
				bonus_round: 0,
				session: 0,
				new_player: 0,
				mobile: 0,
				desktop: 0,
				tablet: 0,
				landscape: 0,
				portrait: 0,
				right: 0,
				left: 0,
				sound: 0,
			});
		}
		for (const o of object) {
			m.set(o.time_stamp, o);
		}

		return new DashboardData(Array.from(m.values()));
	}

	static yearFilter(a) {
		const quarterA = new Date(a.time_stamp).getFullYear();
		const quarterNow = new Date().getFullYear();
		const monthA = new Date(a.time_stamp).getMonth();
		const monthNow = new Date().getMonth();
		const dayA = new Date(a.time_stamp).getDate();
		const dayNow = new Date().getDate();
		const hourA = new Date(a.time_stamp).getHours();
		const hourNow = new Date().getHours();

		return (
			quarterA < quarterNow < monthA < monthNow ||
			dayA < dayNow ||
			(dayA == dayNow && hourA <= hourNow)
		);
	}

	static quarterFilter(a) {
		const monthA = new Date(a.time_stamp).getMonth();
		const monthNow = new Date().getMonth();
		const dayA = new Date(a.time_stamp).getDate();
		const dayNow = new Date().getDate();
		const hourA = new Date(a.time_stamp).getHours();
		const hourNow = new Date().getHours();

		return (
			monthA < monthNow || dayA < dayNow || (dayA == dayNow && hourA <= hourNow)
		);
	}

	static monthFilter(a) {
		const dayA = new Date(a.time_stamp).getDate();
		const dayNow = new Date().getDate();
		const hourA = new Date(a.time_stamp).getHours();
		const hourNow = new Date().getHours();

		return dayA < dayNow || (dayA == dayNow && hourA <= hourNow);
	}

	static weekFilter(a) {
		const dayA = (fns.getDay(new Date(a.time_stamp)) + 6) % 7;
		const dayNow = (fns.getDay(new Date()) + 6) % 7;
		const hourA = new Date(a.time_stamp).getHours();
		const hourNow = new Date().getHours();

		return dayA < dayNow || (dayA == dayNow && hourA <= hourNow);
	}
	static dayFilter(a) {
		const hourA = new Date(a.time_stamp).getHours();
		const hourNow = new Date().getHours();

		return hourA <= hourNow;
	}
}

export class DashboardData {
	constructor(list) {
		this.list = list;

		this.yearlyGlobalCache = null;
		this.yearlyPartialCache = null;

		this.quarterlyGlobalCache = null;
		this.quarterlyPartialCache = null;

		this.monthlyGlobalCache = null;
		this.monthlyPartialCache = null;

		this.weeklyPartialCache = null;
		this.weeklyGlobalCache = null;

		this.dailyPartialCache = null;
		this.dailyGlobalCache = null;

		this.hourlyCache = null;
	}

	get yearlyGlobal() {
		if (this.yearlyGlobalCache) return this.yearlyGlobalCache;

		const res = this.list.reduce((acc, entry) => {
			const date = fns.startOfYear(fns.parseISO(entry.time_stamp));

			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.yearlyGlobalCache = res;

		return res;
	}

	get yearlyPartial() {
		if (this.yearlyPartialCache) return this.yearlyPartialCache;

		const res = this.list.filter(Dashboard.yearFilter).reduce((acc, entry) => {
			const date = fns.startOfYear(fns.parseISO(entry.time_stamp));

			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.yearlyPartialCache = res;

		return res;
	}

	get quarterlyGlobal() {
		if (this.quarterlyGlobalCache) return this.quarterlyGlobalCache;

		const res = this.list.reduce((acc, entry) => {
			const date = fns.startOfQuarter(fns.parseISO(entry.time_stamp));

			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.quarterlyGlobalCache = res;

		return res;
	}

	get quarterlyPartial() {
		if (this.quarterlyPartialCache) return this.quarterlyPartialCache;

		const res = this.list
			.filter(Dashboard.quarterFilter)
			.reduce((acc, entry) => {
				const date = fns.startOfQuarter(fns.parseISO(entry.time_stamp));

				return Dashboard.handleAggregation(acc, entry, date);
			}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.quarterlyPartialCache = res;

		return res;
	}

	get monthlyGlobal() {
		if (this.monthlyGlobalCache) return this.monthlyGlobalCache;

		const res = this.list.reduce((acc, entry) => {
			const date = fns.startOfMonth(fns.parseISO(entry.time_stamp));

			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.monthlyGlobalCache = res;

		return res;
	}

	get monthlyPartial() {
		if (this.monthlyPartialCache) return this.monthlyPartialCache;

		const res = this.list.filter(Dashboard.monthFilter).reduce((acc, entry) => {
			const date = fns.startOfMonth(fns.parseISO(entry.time_stamp));

			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.monthlyPartialCache = res;

		return res;
	}

	get weeklyGlobal() {
		if (this.weeklyGlobalCache) return this.weeklyGlobalCache;

		const res = this.list.reduce((acc, entry) => {
			const date = fns.startOfWeek(fns.parseISO(entry.time_stamp));
			return Dashboard.handleAggregation(acc, entry, date);
		}, {});

		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.weeklyGlobalCache = res;

		return res;
	}

	get weeklyPartial() {
		if (this.weeklyPartialCache) return this.weeklyPartialCache;

		const res = this.list.filter(Dashboard.weekFilter).reduce((acc, entry) => {
			const date = fns.startOfWeek(fns.parseISO(entry.time_stamp));
			return Dashboard.handleAggregation(acc, entry, date);
		}, {});

		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.weeklyPartialCache = res;

		return res;
	}

	get dailyGlobal() {
		if (this.dailyGlobalCache) return this.dailyGlobalCache;

		const res = this.list.reduce((acc, entry) => {
			const date = fns.startOfDay(fns.parseISO(entry.time_stamp));
			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.dailyGlobalCache = res;

		return res;
	}

	get dailyPartial() {
		if (this.dailyPartialCache) return this.dailyPartialCache;

		const res = this.list.filter(Dashboard.dayFilter).reduce((acc, entry) => {
			const date = fns.startOfDay(fns.parseISO(entry.time_stamp));
			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.dailyPartialCache = res;

		return res;
	}

	get hourly() {
		if (this.hourlyCache) return this.hourlyCache;

		const res = this.list.reduce((acc, entry) => {
			const date = fns.parseISO(entry.time_stamp);
			return Dashboard.handleAggregation(acc, entry, date);
		}, {});
		Object.entries(res).forEach(([key, val]) => {
			res[key] = Dashboard.computedMapper(val);
		});

		this.hourlyCache = res;

		return res;
	}
}
