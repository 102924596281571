const state = {
	createdTheme: {},
	filteredThemesList: [],
	themesList: [],
	specificTheme: [],

	defaultScheme: {
		bronze: {
			borderColors: ["#cd722b", "#ffff6f", "#cd722b"],
			borderColorStops: [0, 50, 100],
			backgroundColors: ["#000000", "#663915", "#000000"],
			backgroundColorStops: [0, 50, 100],
			textColors: ["#cd722b", "#ffff6f", "#cd722b"],
			textColorStops: [0, 50, 100],
			textStroke: "#ffff6f",
			animationTint: "#ffff6f",
			iconTint: "#ffff6f",
			iconUrl: "bronze_bonus_cards_GS.png",
			visible_name: "Bronze",
		},
		silver: {
			borderColors: ["#8e929a", "#e6e6e8", "#8e929a"],
			borderColorStops: [0, 50, 100],
			backgroundColors: ["#000000", "#6f5c24", "#000000"],
			backgroundColorStops: [0, 50, 100],
			textColors: ["#8e929a", "#e6e6e8", "#8e929a"],
			textColorStops: [0, 50, 100],
			textStroke: "#e6e6e8",
			animationTint: "#e6e6e8",
			iconTint: "#e6e6e8",
			iconUrl: "silver_bonus_cards_GS.png",
			visible_name: "Silver",
		},
		gold: {
			borderColors: ["#deb949", "#fef0b4", "#deb949"],
			borderColorStops: [0, 50, 100],
			backgroundColors: ["#000000", "#6f5c24", "#000000"],
			backgroundColorStops: [0, 50, 100],
			textColors: ["#deb949", "#fef0b4", "#deb949"],
			textColorStops: [0, 50, 100],
			textStroke: "#fef0b4",
			animationTint: "#fef0b4",
			iconTint: "#fef0b4",
			iconUrl: "golden_bonus_cards_GS.png",
			visible_name: "Gold",
		},
		diamond: {
			borderColors: ["#0363a4", "#98dffa", "#0363a4"],
			borderColorStops: [0, 50, 100],
			backgroundColors: ["#000000", "#013152", "#000000"],
			backgroundColorStops: [0, 50, 100],
			textColors: ["#0363a4", "#98dffa", "#0363a4"],
			textColorStops: [0, 50, 100],
			textStroke: "#98dffa",
			animationTint: "#98dffa",
			iconTint: "#98dffa",
			iconUrl: "diamond_bonus_cards_GS.png",
			visible_name: "Diamond",
		},
		blankScheme: {
			borderColors: ["#336699", "#99ccff", "#996633"],
			borderColorStops: [0, 50, 100],
			backgroundColors: ["#003366", "#336699", "#663300"],
			backgroundColorStops: [0, 50, 100],
			textColors: ["#9966a4", "#98dffa", "#0363a4"],
			textColorStops: [0, 50, 100],
			textStroke: "#98ff00",
			animationTint: "#99ff33",
			iconTint: "#99ff66",
			iconUrl: "",
			visible_name: "",
		},
		veryBlankScheme: {
			borderColors: [],
			borderColorStops: [],
			backgroundColors: [],
			backgroundColorStops: [],
			textColors: [],
			textColorStops: [],
			textStroke: "",
			animationTint: "",
			iconTint: "",
			iconUrl: "",
			visible_name: "",
		},
	},

	schemeDescription: {
		blackNWhiteIconTxt: " Is Bonus Level Icon in black and white ?:",
		borderColor1Txt: "Border Dark Color",
		borderColor2Txt: "Border Highlight Color",
		backgroundColor1Txt: "Background Dark Color",
		backgroundColor2Txt: "Background Highlight Color",
	},
};

const getters = {
	getCreatedTheme: (state) => {
		return state["createdTheme"];
	},
	getFilteredThemesList: (state) => {
		return state["filteredThemesList"];
	},
	getThemesList: (state) => {
		return state["themesList"];
	},
	getSpecificTheme: (state) => {
		return state["specificTheme"];
	},
	getDefaultScheme: (state) => {
		return state["defaultScheme"];
	},
	getSchemeDescription: (state) => {
		return state["schemeDescription"];
	},
};

const actions = {
	async createTheme({ commit, dispatch }, { params, themeName }) {
		try {
			await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/themes/" + themeName,
				payload: params,
				protectedPath: true,
			})
				.then(async (resp) => {
					await commit("setCreatedTheme", resp);
				})
				.catch((erro) => {
					console.error(
						" js - JACKPOT THEMES , CREATE THEME ERROR !, RESPONSE FROM DISPATCH ",
						erro
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" js CREATE THEME error ", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create theme", type: "error" },
				{ root: true }
			);
		}
	},

	async loadFilteredThemesList(
		{ commit, dispatch },
		{ bodyPayload = {}, queryParams = {} }
	) {
		try {
			await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/themes",
				payload: bodyPayload,
				query: queryParams,
				protectedPath: true,
			})
				.then(
					(response) => {
						commit("setFilteredThemesList", response);
					},
					(rejection) => {
						console.warn(
							" - JACKPOT THEMES, LOAD THEME LIST, REJECTION after dispatch ",
							rejection
						);
					}
				)
				.catch((erro) => {
					console.error(
						" js - LOAD THEME LIST, CATCH ERROR after dispatch ",
						erro
					);
					dispatch(
						"notification/setMessage",
						{ message: "Failed to load Group Themes list", type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" js - JACKPOT THEMES, catch error after try ", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load Group Themes list", type: "error" },
				{ root: true }
			);
		}
	},

	async loadThemesList({ commit, dispatch }, params = "") {
		try {
			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/themes",
				params: params,
				protectedPath: true,
			})
				.then(
					(response) => {
						commit("setThemesList", response);
					},
					(rejection) => {
						console.warn(
							" js - LOAD THEMEs LIST get REJECTION after dispatch ",
							rejection
						);
					}
				)
				.catch((erro) => {
					console.error(
						" js - LOAD THEMEs LIST get CATCH error after dispatch ",
						erro
					);
					dispatch(
						"notification/setMessage",
						{ message: "Failed to load Group Themes list", type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" js - JACKPOT THEMES, catch error after try ", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load Group Themes list", type: "error" },
				{ root: true }
			);
		}
	},

	async loadSpecificTheme({ commit, dispatch }, themeName) {
		try {
			let msgType = "info";

			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/themes/" + themeName,
				protectedPath: true,
			})
				.then(
					(responseResolved) => {
						commit("setSpecificTheme", responseResolved);
						if (responseResolved.status != 200) {
							msgType = "warning";
						}
					},
					(responseRejected) => {
						console.warn(
							" js load SPECIFIC THEME ",
							"\n REJECTION after dispatch ",
							responseRejected
						);
					}
				)
				.catch((erro) => {
					console.error(
						" js load SPECIFIC THEME ",
						"\n CATCH error after dispatch ",
						erro
					);
					dispatch(
						"notification/setMessage",
						{ message: erro, type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(
				"js ee JACKPOT THEMES ee js\n catch error after try ",
				error
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load Theme", type: "error" },
				{ root: true }
			);
		}
	},

	async removeTheme({ commit, dispatch }, theme) {
		try {
			await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/themes/" + theme.name,
				protectedPath: true,
			});
		} catch (e) {
			console.error("removing theme failed", e);
		}
	},

	async restoreTheme({ commit, dispatch }, theme) {
		try {
			const payload = { deleted: null };
			await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/themes/" + theme.name,
				payload,
				protectedPath: true,
			});
		} catch (e) {
			console.error("removing theme failed", e);
		}
	},

	themeResetState({ commit }) {
		commit("themes_RESET_STATE");
	},
};

const mutations = {
	setCreatedTheme(state, theme) {
		state.createdTheme = theme;
	},
	setFilteredThemesList(state, list) {
		state.filteredThemesList = list;
	},
	setThemesList(state, list) {
		state.themesList = list;
	},
	setSpecificTheme(state, specificTheme) {
		state.specificTheme = specificTheme;
	},
	themes_RESET_STATE(state) {
		state.specificTheme = [];
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
