import { JackpotGroupConfig } from "../../../../constants/converters/jackpotGroupConfig";
import { actions } from "../../common";
export default {
	...actions("/api/v1/jackpots/group_configs", JackpotGroupConfig),
	async loadList(
		{ commit, dispatch, getters },
		{ query = {}, payload = {} } = { query: {}, payload: {} }
	) {
		try {
			const q = { ...getters.pagination, ...query };
			const p = { ...getters.filter, ...payload };
			const response = await dispatch(
				"apiCall/loganPut",
				{
					path: "/api/v1/jackpots/group_configs",
					query: {
						order: q.order,
						order_by: q.order_by,
						per_page: q.per_page,
						page: q.page,
					},
					payload: p,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data;

			data = data.map((item) => JackpotGroupConfig.fromDTO({ data: item }));

			commit("list", data);
			return response.data._meta_data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/jackpots/group_configs`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load group config list", type: "error" },
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	// 	async updateSpecificGroupConfig(
	// 		{ commit, dispatch, getters },
	// 		{ groupConfigId, bodyPayload }
	// 	) {
	// 		try {
	// 			const q = { ...getters.pagination, ...query };
	// 			const p = { ...getters.filter, ...payload };
	// 			const response = await dispatch(
	// 				"apiCall/loganPut",
	// 				{
	// 					path: "/api/v1/jackpots/group_configs",
	// 					query: {
	// 						order: q.order,
	// 						order_by: q.order_by,
	// 						per_page: q.per_page,
	// 						page: q.page,
	// 					},
	// 					payload: p,
	// 					protectedPath: true,
	// 				},
	// 				{ root: true }
	// 			);
	// 			let data = response.data.data;

	// 			data = data.map((item) => JackpotGroupConfig.fromDTO({ data: item }));

	// 			commit("list", data);
	// 			return response.data._meta_data.pagination;
	// 		} catch (e) {
	// 			if (e.message === "canceled") {
	// 				throw e;
	// 			}
	// 			console.error(
	// 				`Error ocurred while fetching list from /api/v1/jackpots/group_configs`,
	// 				e
	// 			);
	// 			dispatch(
	// 				"notification/setMessage",
	// 				{ message: "Failed to load group config list", type: "error" },
	// 				{ root: true }
	// 			);
	// 			commit("list", []);
	// 			commit("pagination", {
	// 				total_pages: 0,
	// 				total_records: 0,
	// 			});
	// 		}
	// }
};
