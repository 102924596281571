const state = {
	casinosInGroup: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	getAddedCasinosInGroup: (state) => {
		return state["casinosInGroup"];
	},
};

const actions = {
	async setCasinosInGroup({ commit, dispatch }, { groupId, casinos }) {
		commit("setCasinosInGroupLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/groups/" + groupId + "/casinos",
				payload: { casino_ids: casinos },
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: `Updated casinos in group`, type: "success" },
				{ root: true }
			);
			commit("setCasinosInGroupData", res);
			commit("setCasinosInGroupLoading", false);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: `Failed to update casinos in group`, type: "error" },
				{ root: true }
			);
			commit("setCasinosInGroupLoading", false);
		}
	},
};

const mutations = {
	setCasinosInGroupData(state, data) {
		state.casinosInGroup.data = data;
	},
	setCasinosInGroupLoading(state, value) {
		state.casinosInGroup.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
