import "../../../../definitions/statistics.js";
import _ from "lodash";
import BatchWorker from "worker-loader!../../../../script/batchWorker.worker";
import { clone } from "../../../../constants/helpers";

export default {
	async loadScopeList(
		{ commit, dispatch, rootGetters },
		{ scope, payload, tabKey, retry }
	) {
		const type = "scope";
		const p = { ...payload };
		commit("filter", payload);
		if (!p?.casinos || p.casinos?.length == 0) {
			if (p.categories && p.categories?.length > 0) {
				const cass = p.categories.reduce((agg, curr) => {
					agg.push(
						rootGetters.casinoCategoryMap.get(curr)
							? rootGetters.casinoCategoryMap
									.get(curr)
									.map((el) => el.casino_id)
							: []
					);
					return agg;
				}, []);
				p.casinos = _.intersection(...cass);
			}
		}
		if (p.currencies?.length == 0) {
			delete p.currencies;
		}
		delete p.categories;
		try {
			const worker = new BatchWorker();
			commit("worker", { worker, type });

			if (!retry) {
				await commit("dataPartial", {
					tabKey: tabKey,
					clear: true,
					progress: 0,
				});
			} else {
				p.casinos = retry;
			}

			worker.postMessage({
				filter: p,
				path: `${rootGetters.loganUrl}/api/v1/statistics/${scope}`,
				token: rootGetters[`apiCall/getAccessToken`],
				type: "loadData",
			});

			worker.addEventListener("message", async (e) => {
				if (e.data.type === "allDataLoaded") {
					commit("worker", { worker: null, type });
					commit("failedPartial", {
						tabKey,
						failed: e.data.failed,
						casinos: e.data.casinos,
					});
					return;
				}
				let data = e.data.results.data;

				await commit("dataPartial", {
					tabKey: tabKey,
					data: clone(data),
					progress: e.data.progress,
					aggregateBy: "date_from",
				});
			});
			return type;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/statistics/${scope}`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load list", type: "error" },
				{ root: true }
			);
			return [];
		}
	},
	async loadGameList(
		{ commit, dispatch, rootGetters },
		{ payload, tabKey, retry = false }
	) {
		const type = "game";
		commit("filter", payload);
		const p = { ...payload };
		if (!p?.casinos || p.casinos?.length == 0) {
			if (!p.categories && p.categories?.length > 0) {
				const cass = p.categories.reduce((agg, curr) => {
					agg.push(
						rootGetters.casinoCategoryMap.get(curr)
							? rootGetters.casinoCategoryMap
									.get(curr)
									.map((el) => el.casino_id)
							: []
					);
					return agg;
				}, []);
				p.casinos = _.intersection(...cass);
			}
		}
		if (p.currencies?.length == 0) {
			delete p.currencies;
		}
		delete p.categories;
		try {
			const worker = new BatchWorker();
			commit("worker", { worker, type });

			if (!retry) {
				await commit("dataPartial", {
					tabKey: tabKey,
					progress: 0,
					clear: true,
				});
			} else {
				p.casinos = retry;
			}

			worker.postMessage({
				filter: p,
				path: `${rootGetters.loganUrl}/api/v1/statistics/games/stat`,
				token: rootGetters[`apiCall/getAccessToken`],
				aggregateBy: "technical",
				type: "loadData",
			});

			worker.addEventListener("message", async (e) => {
				if (e.data.type === "allDataLoaded") {
					commit("worker", { worker: null, type });
					commit("failedPartial", {
						tabKey,
						failed: e.data.failed,
						casinos: e.data.casinos,
					});
					return;
				}
				let data = e.data.results.data;

				await commit("dataPartial", {
					tabKey: tabKey,
					data: clone(data),
					progress: e.data.progress,
					aggregateBy: "name",
				});
			});
			return type;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/statistics/`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load games list", type: "error" },
				{ root: true }
			);

			return [];
		}
	},
	async loadGameCurrencyList(
		{ commit, dispatch, rootGetters },
		{ gameId, payload, tabKey, retry = false }
	) {
		const type = "currency";
		commit("filter", payload);
		const p = { ...payload };
		if (!p?.casinos || p.casinos?.length == 0) {
			if (p.categories && p.categories?.length > 0) {
				const cass = p.categories.reduce((agg, curr) => {
					agg.push(
						rootGetters.casinoCategoryMap.get(curr)
							? rootGetters.casinoCategoryMap
									.get(curr)
									.map((el) => el.casino_id)
							: []
					);
					return agg;
				}, []);
				p.casinos = _.intersection(...cass);
			}
		}
		if (p.currencies?.length == 0) {
			delete p.currencies;
		}
		delete p.categories;
		try {
			if (!retry) {
				await commit("dataPartial", {
					tabKey: tabKey,
					progress: 0,
					clear: true,
				});
			} else {
				p.casinos = retry;
			}

			const worker = new BatchWorker();
			commit("worker", { worker, type });

			worker.postMessage({
				filter: p,
				path: `${rootGetters.loganUrl}/api/v1/statistics/games/${gameId}/currencies`,
				token: rootGetters[`apiCall/getAccessToken`],
				aggregateBy: "currency",
				type: "loadData",
			});

			worker.addEventListener("message", async (e) => {
				if (e.data.type === "allDataLoaded") {
					commit("worker", { worker: null, type });
					commit("failedPartial", {
						tabKey,
						failed: e.data.failed,
						casinos: e.data.casinos,
					});
					return;
				}
				let data = e.data.results.data;

				await commit("dataPartial", {
					tabKey: tabKey,
					data: clone(data),
					progress: e.data.progress,
					aggregateBy: "currency",
				});
			});
			return type;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from /api/v1/statistics/`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load currencies list", type: "error" },
				{ root: true }
			);

			return [];
		}
	},
};
