const state = {
	createdJokerBonus: {
		data: {},
		isLoading: false,
	},
	jokerBonusList: {
		data: [],
		isLoading: false,
	},
	filteredJokerBonusList: {
		data: [],
		isLoading: false,
	},

	jokerBonusDetail: {
		data: {},
		isLoading: false,
	},
	updatedJokerBonus: {
		data: {},
		isLoading: false,
	},
	updatedJokerBonusConfigAttributes: {
		data: {},
		isLoading: false,
	},
	jokerBonusConfigAttributes: {
		data: {},
		isLoading: false,
	},
	deactivatedJokerBonusConfigAttributes: {
		data: {},
		isLoading: false,
	},
};

const getters = {
	createdJokerBonus: (state) => {
		return state["createdJokerBonus"];
	},
	jokerBonusList: (state) => {
		return state["jokerBonusList"];
	},
	filteredJokerBonusList: (state) => {
		return state["filteredJokerBonusList"];
	},

	getJokerBonusDetail: (state) => {
		return state["jokerBonusDetail"];
	},
	getUpdatedJokerBonus: (state) => {
		return state["updatedJokerBonus"];
	},
	getUpdatedJokerBonusConfigAttributes: (state) => {
		return state["updatedJokerBonusConfigAttributes"];
	},
	getJokerBonusConfigAttributes: (state) => {
		return state["jokerBonusConfigAttributes"];
	},
	getDeactivatedJokerBonusConfigAttributes: (state) => {
		return state["deactivatedJokerBonusConfigAttributes"];
	},
};

const actions = {
	/** ADD NEW JACKPOT */
	async createJokerBonus({ commit, dispatch }, data) {
		commit("setCreatedJokerBonusLoading", true);
		try {
			await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/jackpots",
				payload: data,
				protectedPath: true,
			}).then((response) => {
				commit("setCreatedJokerBonusData", response);
				commit("setCreatedJokerBonusLoading", false);
			});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error("notification/setMessage", {
				message: error.response.data.message,
				type: "error",
			});
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create Joker Bonus", type: "error" },
				{ root: true }
			);
			commit("setCreatedJokerBonusLoading", false);
		}
	},
	/** LIST JACKPOTS WITH CONFIG */
	async loadJokerBonusList({ commit, dispatch }, params = {}) {
		commit("setJokerBonusListLoading", true);
		try {
			let resp = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/jackpots",
				params: params,
				protectedPath: true,
			});
			commit("setJokerBonusListData", resp);
			commit("setJokerBonusListLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error("notification/setMessage", {
				message: error.response.data.message,
				type: "error",
			});
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load Joker Bonus list", type: "error" },
				{ root: true }
			);
			commit("setJokerBonusListLoading", false);
		}
	},
	/** FILTERED LISTING OF JACKPOTS WITH CONFIG */
	async loadFilteredJokerBonusList(
		{ commit, dispatch },
		{ bodyPayload, queryParams = {} }
	) {
		commit("setFilteredJokerBonusListLoading", true);
		try {
			let resp = await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/jackpots",
				payload: bodyPayload,
				query: queryParams,
				protectedPath: true,
			});
			commit("setFilteredJokerBonusListData", resp);
			commit("setFilteredJokerBonusListLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error("notification/setMessage", {
				message: error.response.data.message,
				type: "error",
			});
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load filtered Joker Bonus list", type: "error" },
				{ root: true }
			);
			commit("setFilteredJokerBonusListLoading", false);
		}
	},
	/**
	 *  JACKPOT DETAIL
	 * */
	async loadJokerBonusDetail({ commit, dispatch }, jokerBonusId) {
		commit("setJokerBonusDetailLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/jackpots/" + jokerBonusId,
				protectedPath: true,
			});
			commit("setJokerBonusDetail", res.data);
			commit("setJokerBonusDetailLoading", false);
		} catch (error) {
			commit("setJokerBonusDetail", undefined);
			commit("setJokerBonusDetailLoading", false);
		}
	},
	/** UPDATE ANY JACKPOT FIELDS */
	async updateJokerBonus({ commit, dispatch }, { jokerBonusId, bodyPayload }) {
		commit("setUpdatedJokerBonusLoading", true);
		try {
			const res = await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/jackpots/" + jokerBonusId,
				payload: bodyPayload,
				protectedPath: true,
			});
			commit("setUpdatedJokerBonus", res.data);
			commit("setUpdatedJokerBonusLoading", false);
		} catch (error) {
			commit("setUpdatedJokerBonus", undefined);
			commit("setUpdatedJokerBonusLoading", false);
		}
	},
	/** DEACTIVATE AN ACTIVE JACKPOT */
	async deactivateJokerBonus({ commit, dispatch }, jokerBonusId) {
		try {
			/** OLD WAY OF DEACTIVATING JokerBonusS is deprecated */
			await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/jackpots/" + jokerBonusId,
				protectedPath: true,
			}).then(
				(response) => {
					console.warn(
						" response from deactivated Joker Bonus request \n",
						response
					);
					console.info(response.data.message);
				},
				(rejection) => {
					console.error(
						" REJECTION from deactivated Joker Bonus request \n",
						rejection
					);
				}
			);
		} catch (error) {
			console.error(
				" WARNING !!!, THERE WAS AN ERROR WHILE DEACTIVATING Joker Bonus \n",
				error
			);
		}
	},

	/**
	 *  JOKER BONUS'S CONFIG UPDATE, GET, DELETE
	 */
	/** UPDATE ONE OR MORE JACKPOT'S CONFIG ATTRIBUTES */
	async updateJokerBonusConfigAttributes(
		{ commit, dispatch },
		{ jokerBonusId, bodyPayload }
	) {
		commit("setUpdatedJokerBonusConfigAttributesLoading", true);
		try {
			await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/jackpots/" + jokerBonusId + "/config",
				payload: bodyPayload,
				protectedPath: true,
			}).then(
				(response) => {
					commit("setUpdatedJokerBonusConfigAttributesData", response.data);
					commit("setUpdatedJokerBonusConfigAttributesLoading", false);
				},
				(rejection) => {
					commit("setUpdatedJokerBonusConfigAttributesLoading", false);
					console.error(
						" REJECTION from PATCH Joker Bonus'S CONFIG request \n",
						rejection
					);
				}
			);
		} catch (error) {
			console.error(
				" WARNING !!!, THERE WAS AN ERROR WHILE DEACTIVATING Joker Bonus'S CONFIG \n",
				error
			);
			commit("setUpdatedJokerBonusConfigAttributesLoading", false);
		}
	},
	/** SHOW CONFIG OF JACKPOT */
	async loadJokerBonusConfigAttributes({ commit, dispatch }, jokerBonusId) {
		commit("setJokerBonusConfigAttributesLoading", true);
		try {
			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/jackpots/" + jokerBonusId + "/config",
				protectedPath: true,
			}).then(
				(response) => {
					commit("setJokerBonusConfigAttributesData", response.data);
					commit("setJokerBonusConfigAttributesLoading", false);
				},
				(rejection) => {
					commit("setJokerBonusConfigAttributesLoading", false);
					console.error(
						" REJECTION from GET Joker Bonus'S CONFIG request \n",
						rejection
					);
				}
			);
		} catch (error) {
			console.error(
				" WARNING !!!, THERE WAS AN ERROR WHILE GET Joker Bonus'S CONFIG \n",
				error
			);
			commit("setLoadedJokerBonusConfigAttributesLoading", false);
		}
	},
	/** DEACTIVATE JACKPOT'S CONFIG */
	async deactivateJokerBonusConfigAttributes(
		{ commit, dispatch },
		jokerBonusId
	) {
		commit("setDeactivatedJokerBonusConfigAttributesLoading", true);
		try {
			await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/jackpots/" + jokerBonusId + "/config",
				protectedPath: true,
			}).then(
				(response) => {
					commit("setDeactivatedJokerBonusConfigAttributesData", response.data);
					commit("setDeactivatedJokerBonusConfigAttributesLoading", false);
				},
				(rejection) => {
					commit("setDeactivatedJokerBonusConfigAttributesLoading", false);
					console.error(
						" REJECTION from DELETE Joker Bonus'S CONFIG request \n",
						rejection
					);
				}
			);
		} catch (error) {
			console.error(
				" WARNING !!!, THERE WAS AN ERROR WHILE DELETE Joker Bonus'S CONFIG \n",
				error
			);
			commit("setDeactivatedJokerBonusConfigAttributesLoading", false);
		}
	},
};

const mutations = {
	setCreatedJokerBonusData(state, data) {
		state.createdJokerBonus.data = data;
	},
	setCreatedJokerBonusLoading(state, value) {
		state.createdJokerBonus.isLoading = value;
	},
	setJokerBonusListData(state, list) {
		state.jokerBonusList.data = list;
	},
	setJokerBonusListLoading(state, value) {
		state.jokerBonusList.isLoading = value;
	},
	setFilteredJokerBonusListData(state, list) {
		state.filteredJokerBonusList.data = list;
	},
	setFilteredJokerBonusListLoading(state, value) {
		state.filteredJokerBonusList.isLoading = value;
	},

	setJokerBonusDetail(state, data) {
		state.jokerBonusDetail.data = data;
	},
	setJokerBonusDetailLoading(state, value) {
		state.jokerBonusDetail.isLoading = value;
	},
	setUpdatedJokerBonus(state, data) {
		state.updatedJokerBonus.data = data;
	},
	setUpdatedJokerBonusLoading(state, value) {
		state.updatedJokerBonus.isLoading = value;
	},
	setUpdatedJokerBonusConfigAttributesData(state, data) {
		state.updatedJokerBonusConfigAttributes.data = data;
	},
	setUpdatedJokerBonusConfigAttributesLoading(state, value) {
		state.updatedJokerBonusConfigAttributes.isLoading = value;
	},
	setJokerBonusConfigAttributesData(state, data) {
		state.jokerBonusConfigAttributes.data = data;
	},
	setJokerBonusConfigAttributesLoading(state, value) {
		state.jokerBonusConfigAttributes.isLoading = value;
	},
	setDeactivatedJokerBonusConfigAttributesData(state, data) {
		state.deactivatedJokerBonusConfigAttributes.data = data;
	},
	setDeactivatedJokerBonusConfigAttributesLoading(state, value) {
		state.deactivatedJokerBonusConfigAttributes.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
