import { getters } from "../common";
export default {
	...getters,
	games(state) {
		return state.gamesList;
	},
	bets(state) {
		return state.bets;
	},
	players(state) {
		return state.players;
	},
	playerOffers(state) {
		return state.playerOffers;
	},
};
