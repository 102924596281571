import { state as defaultState } from "../common";
export const state = () => ({
	...defaultState(),
	list: {
		data: [],
		metadata: {
			filter: {},
			config: [
				{
					rKey: "session_mongo_id",
					value: "session_mongo_id",
					type: "eq",
				},
				{
					value: "pagination",
					type: "pagination",
				},
			],
			pagination: {
				order: "asc",
				order_by: "id",
				page: 1,
				per_page: 10,
				pointers: [
					{
						rKey: "round_id",
						value: ["round_id"],
						type: "ge",
					},
				],
			},
			query: {
				previous: null,
				next: null,
				first: null,
				last: null,
			},
		},
	},
});
