// import { JackpotConfig } from "../../../../constants/converters/jackpotConfig";
import { actions } from "../../common";
import _ from "lodash";
import { JackpotTicket } from "../../../../constants/converters/jackpotTicket";
export default {
	...actions("/api/v1/jackpots/paid_bonuses"),
	async loadList({ commit, dispatch, getters }) {
		try {
			const query = getters.pagination;
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/jackpots/paid_bonuses",
					params: {
						order: query.order,
						order_by: query.order_by,
						per_page: query.per_page,
						page: query.page,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data;

			commit(
				"list",
				data.data.map((item) => JackpotTicket.fromDTO(item))
			);
			return data._meta_data.pagination;
		} catch (e) {
			if (e?.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from ${"/api/v1/jackpots/paid_bonuses"}`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load bonus tickets", type: "error" },
				{ root: true }
			);

			commit("list", []);
		}
	},
	async loadFilteredList({ commit, dispatch, getters, rootGetters }) {
		try {
			const query = getters.pagination;
			if (_.isEqual(getters.filter, {})) return;
			let payload = {
				...getters.filter,
			};

			//Show collected or show uncollected are in special states
			const collectedVars =
				payload.player_casino_id.eq === null ||
				payload.player_casino_id.ne === null;

			//If casinos and collection switches are empty
			if (
				(!payload.player_casino_id ||
					!payload.player_casino_id.of ||
					payload.player_casino_id.of.length === 0) &&
				!collectedVars
			) {
				payload.player_casino_id = {
					of: [null, ...rootGetters.allowedCasinos.map((el) => el.casino_id)],
				};
			}

			//If groups are empty
			if (!payload.group_name || !payload.group_name.of) {
				const allowedGroups = rootGetters[`jackpotGroup2/getFilteredGroups`];

				payload.group_name = {
					of:
						allowedGroups?.length === 0 || allowedGroups === undefined
							? [undefined]
							: allowedGroups,
				};
			} else if (payload.group_name.of.length === 0) {
				payload.group_name = {
					of: [undefined],
				};
			}

			const response = await dispatch(
				"apiCall/loganPut",
				{
					path: "/api/v1/jackpots/paid_bonuses",
					query: {
						order: query.order,
						order_by: query.order_by,
						per_page: query.per_page,
						page: query.page,
					},
					payload: payload,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data;

			commit(
				"list",
				data.data.map((item) => JackpotTicket.fromDTO(item))
			);
			return data._meta_data.pagination;
		} catch (e) {
			if (e?.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from ${"/api/v1/jackpots/paid_bonuses"}`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load bonus tickets", type: "error" },
				{ root: true }
			);

			commit("list", []);
		}
	},
};
