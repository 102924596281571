import { mutations } from "../common";
export default {
	...mutations,
	gamesList(state, payload) {
		state.gamesList = payload;
	},
	bets(state, payload) {
		state.bets = payload;
	},
	players(state, payload) {
		state.players = payload;
	},
	playerOffers(state, payload) {
		state.playerOffers = payload;
	},
};
