var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-fade-transition",
    [
      !_vm.destroy
        ? _c("LoganLoading", [
            _c("div", { staticClass: "mt-5" }, [
              _vm._v(_vm._s(_vm.loadingMessage)),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }