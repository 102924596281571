const state = {
	gamesInGroup: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	getAddedGamesInGroup: (state) => {
		return state["gamesInGroup"];
	},
};

const actions = {
	async setGamesInGroup({ commit, dispatch }, { groupId, games }) {
		commit("setGamesInGroupLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/groups/" + groupId + "/games",
				payload: { game_names: games },
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: `Updated games in group `, type: "success" },
				{ root: true }
			);
			commit("setGamesInGroupData", res);
			commit("setGamesInGroupLoading", false);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: `Failed to update games in group`, type: "error" },
				{ root: true }
			);
			commit("setGamesInGroupLoading", false);
		}
	},
};

const mutations = {
	setGamesInGroupData(state, data) {
		state.gamesInGroup.data = data;
	},
	setGamesInGroupLoading(state, value) {
		state.gamesInGroup.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
