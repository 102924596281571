import Vue from "vue";
import Router, { RouterView } from "vue-router";

import { constants } from "./constants/constants";
import { permControl } from "./constants/helpers";
import store from "./store";
import _ from "lodash";
import LoganAuthLoading from "./views/LoganAuthLoading.vue";

function lazyLoadSessions(view) {
	return () => import(`./views/sessions/${view}.vue`);
}

function lazyLoad(view) {
	return () => import(`@/views/${view}.vue`);
}
Vue.use(Router);

const permissions = constants.permissions;

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/login",
			name: "login",
			meta: {
				commander: false,
			},
			component: LoganAuthLoading,
		},
		{
			path: "/authorize",
			name: "authorize",
			meta: {
				commander: false,
			},
			component: LoganAuthLoading,
		},
		{
			path: "/logout",
			name: "logout",
			meta: {
				commander: false,
				defaultBreadcrumbs: { title: "Logout", hard: true },
			},
		},
		{
			path: "/frame",
			name: "frame",
			meta: {
				commander: false,
				defaultBreadcrumbs: { title: "KHRONOS Frame", hard: true },
			},
			component: lazyLoad("sessions/FrameViewer"),
		},
		{
			path: "/fs",
			name: "fullscreen",
			redirect: "casino/:casino/dashboard",
			meta: {
				commander: false,
			},
			children: [
				{
					path: "casino/:casino(\\d+)",
					name: "fscasino",
					meta: {
						commander: false,
					},
					redirect: "casino/:casino/dashboard",
					component: lazyLoad("LoganView"),
					children: [
						{
							path: "dashboard",
							name: "Fullscreen Dashboard",
							props: {
								fs: true,
							},
							component: lazyLoad("LoganHome2"),
						},
					],
				},
			],
			component: lazyLoad("LoganFsWrap"),
		},
		{
			path: "/",
			redirect: `casino/findCasino/dashboard`,
			meta: {
				commander: false,
			},
			component: lazyLoad("LoganDashboard"),
			children: [
				{
					path: "casino/:casino(\\d+)",
					name: "casino",
					meta: {
						commander: false,
					},
					redirect: "casino/:casino/dashboard",
					component: lazyLoad("LoganView"),
					children: [
						{
							path: "dashboard",
							name: "home",
							meta: {
								defaultBreadcrumbs: { title: "Home", hard: true },
							},
							component: lazyLoad("LoganHome2"),
						},
						{
							path: "sessions",
							name: "sessions",
							meta: {
								section: "Session",
								defaultBreadcrumbs: { title: "Sessions", hard: true },
								disallow: (userPerm) =>
									!permControl(userPerm, {
										key: "session.list",
										val: permissions.READ,
									}),
							},
							component: lazyLoadSessions("SessionList"),
						},
						{
							path: "sessions/:session_id",
							name: "session-detail",
							meta: {
								section: "Session",
								onCasinoChange: {
									name: "sessions",
								},
								defaultBreadcrumbs: [
									{
										title: "Sessions",
										hard: true,
										link: "/sessions",
										name: "sessions",
									},
									{
										title: "Session {session_id}",
										link: "/sessions/{session_id}",
										name: "session-detail",
									},
								],
								disallow: (userPerm) =>
									!permControl(userPerm, {
										key: "session.detail",
										val: permissions.READ,
									}),
							},
							component: lazyLoadSessions("SessionDetail"),
						},
						{
							path: "players",
							name: "players",
							meta: {
								section: "Player",
								defaultBreadcrumbs: { title: "Players", hard: true },
								disallow: (userPerm) =>
									!permControl(userPerm, {
										key: "player.list",
										val: permissions.READ,
									}),
							},
							component: lazyLoad("players/PlayerList"),
						},
						{
							path: "players/:player_id",
							name: "player-detail",
							meta: {
								section: "Player",
								onCasinoChange: {
									name: "players",
								},
								defaultBreadcrumbs: [
									{
										title: "Players",
										hard: true,
										link: "/players",
										name: "players",
									},
									{
										title: "Player {player_id}",
										link: "/players/{player_id}",
										name: "player-detail",
									},
								],
								disallow: (userPerm) => {
									!permControl(userPerm, {
										key: "player.details",
										val: permissions.READ,
									});
								},
							},
							component: lazyLoad("players/PlayerDetail"),
						},
						{
							path: "freeGames",
							name: "free-games",
							meta: {
								defaultBreadcrumbs: { title: "Free Games", hard: true },
								disallow: (userPerm) =>
									!permControl(userPerm, {
										key: "free_games.list",
										val: permissions.READ,
									}),
							},
							component: lazyLoad("freeGames/freeGamesList"),
						},
						{
							path: "freeGames/:offer_id",
							name: "free-game-detail",
							meta: {
								onCasinoChange: {
									name: "free-games",
								},
								defaultBreadcrumbs: { title: "Free Games", hard: true },
								disallow: (userPerm) =>
									!permControl(userPerm, {
										key: "free_games.detail",
										val: permissions.READ,
									}),
							},
							component: lazyLoad("freeGames/offerDetail"),
						},
						{
							path: "freeGames/:offer_id/list",
							name: "free-games-blackwhitelist",
							meta: {
								onCasinoChange: {
									name: "free-games",
								},
								defaultBreadcrumbs: { title: "Free Games", hard: true },
								disallow: (userPerm) =>
									!permControl(userPerm, {
										key: "free_games.detail",
										val: permissions.READ,
									}),
							},
							component: lazyLoad("freeGames/ListEdit"),
						},
					],
				},
				{
					path: "/contact",
					name: "contact",
					meta: {
						defaultBreadcrumbs: { title: "Contact", hard: true },
					},
					component: lazyLoad("LoganContact"),
				},
				{
					path: "/rounds",
					name: "rounds",
					meta: {
						commander: false,
						section: "Rounds",
						defaultBreadcrumbs: { title: "Rounds", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "session.detail",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("rounds/RoundList"),
				},
				{
					path: "/statistics/general",
					name: "statistics-general",
					meta: {
						section: "Statistics",
						defaultBreadcrumbs: { title: "General Statistics", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "statistic",
								val: permissions.READ,
							}) &&
							!permControl(userPerm, {
								key: "analytics",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("statistics/StatisticsGeneral"),
				},
				{
					path: "/statistics/daily",
					name: "statistics-daily",
					meta: {
						section: "Statistics",
						defaultBreadcrumbs: { title: "Daily Statistics", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "statistic",
								val: permissions.READ,
							}) &&
							!permControl(userPerm, {
								key: "analytics",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("statistics/StatisticsDaily"),
				},
				{
					path: "/statistics/monthly",
					name: "statistics-monthly",
					meta: {
						section: "Statistics",
						defaultBreadcrumbs: { title: "Monthly Statistics", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "statistic",
								val: permissions.READ,
							}) &&
							!permControl(userPerm, {
								key: "analytics",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("statistics/StatisticsMonthly"),
				},
				{
					path: "/management/users",
					name: "user-management",
					meta: {
						section: "Management",
						defaultBreadcrumbs: { title: "Users", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "management.users.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("users/UserManagement"),
				},
				{
					path: "/management/groups",
					name: "permission-management",
					meta: {
						section: "Management",
						defaultBreadcrumbs: { title: "Permission Management", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "management.groups.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("permissionGroups/PermissionsManagementList"),
				},
				{
					path: "/management/casinos",
					name: "casino-management",
					meta: {
						section: "Management",
						defaultBreadcrumbs: { title: "Casino Management", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "management.groups.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("permissionGroups/CasinoManagementList"),
				},
				{
					path: "/management/categories",
					name: "category-management",
					meta: {
						section: "Management",
						defaultBreadcrumbs: { title: "Casino Categories", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "meta_casinos.detail",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("categories/CategoryManagement"),
				},
				{
					path: "/management/studios",
					name: "studio-management",
					meta: {
						section: "Management",
						defaultBreadcrumbs: { title: "Studio Management", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "management.groups.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("permissionGroups/StudioManagementList"),
				},
				{
					path: "/management/audit",
					name: "audit",
					meta: {
						section: "Management",
						defaultBreadcrumbs: { title: "Audit Log", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "audit",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("audit/AuditList"),
				},
				{
					path: "management/users/:user_id",
					name: "user-detail",
					props: true,
					meta: {
						defaultBreadcrumbs: [
							{
								title: "Users",
								hard: true,
								link: "/users",
								name: "user-management",
							},
							{
								title: "User {user_id}",
								link: "/users/{user_id}",
								name: "user-detail",
							},
						],
						disallow: (userPerm, route) => {
							if (route.params.user_id == store.getters["userId"]) {
								return false;
							}
							return !permControl(userPerm, {
								key: "management.users.detail",
								val: permissions.READ,
							});
						},
					},
					component: lazyLoad("users/UserDetail"),
				},
				{
					path: "/profile",
					name: "my-profile",
					props: () => ({
						user_id: store.getters["userId"],
					}),
					meta: {
						defaultBreadcrumbs: [
							{
								title: "My Profile",
								hard: true,
								link: "/profile",
								name: "my-profile",
							},
						],
					},
					component: lazyLoad("users/UserDetail"),
				},
				{
					path: "/jackpots/groups",
					name: "bonus-groups",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: { title: "Groups", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.groups.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("jackpots/JackpotGroupsList"),
				},
				{
					path: "/jackpots/groups/:jackpot_group_id",
					name: "jackpot-group-detail",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: [
							{
								title: "Groups",
								hard: true,
								link: "/jackpots/groups",
								name: "bonus-groups",
							},
							{
								title: "{jackpot_group_id}",
								link: "/jackpots/groups/{jackpot_group_id}",
								name: "jackpot-group-detail",
							},
						],
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.groups.detail",
								val: permissions.READ | permissions.EDIT,
							}),
					},
					component: lazyLoad("jackpots/JackpotGroupDetail"),
				},
				{
					path: "/jackpots/groups/:jackpot_group_id/edit_theme/:group_theme_name",
					name: "group-theme",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: [
							{
								title: "Groups",
								hard: true,
								link: "/jackpots/groups",
								name: "bonus-groups",
							},
							{
								title: "{jackpot_group_id}",
								link: "/jackpots/groups/{jackpot_group_id}",
								name: "jackpot-group-detail",
							},
							{
								title: "Edit Theme",
								link: "/jackpots/groups/{jackpot_group_id}/edit_theme",
								name: "group-theme",
							},
						],
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.group_themes.detail",
								val: permissions.READ | permissions.EDIT,
							}),
					},
					component: lazyLoad("jackpots/EditGroupTheme"),
				},
				{
					path: "/jackpots/groups/:jackpot_group_id/:jackpot_id",
					name: "jackpot-detail",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: [
							{
								title: "Groups",
								hard: true,
								link: "/jackpots/groups",
								name: "bonus-groups",
							},
							{
								title: "{jackpot_group_id}",
								link: "/jackpots/groups/{jackpot_group_id}",
								name: "jackpot-group-detail",
							},
							{
								title: "{jackpot_id}",
								link: "/jackpots/groups/{jackpot_group_id}/{jackpot_id}",
								name: "jackpot-detail",
							},
						],
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.groups.detail",
								val: permissions.READ | permissions.EDIT,
							}),
					},
					component: lazyLoad("jackpots/JackpotDetail"),
				},
				{
					path: "/jackpots/group_configs",
					name: "bonus-group-configs",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: { title: "Group Configs", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.group_configs.detail",
								val: permissions.READ | permissions.EDIT,
							}),
					},
					component: lazyLoad("jackpots/JackpotGroupConfigsList"),
				},
				{
					path: "/jackpots/group_themes",
					name: "bonus-group-themes",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: { title: "Group Themes", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.group_themes.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("jackpots/JackpotGroupThemesList"),
				},
				{
					path: "/jackpots/group_themes/:theme_name",
					name: "theme-detail",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: [
							{
								title: "Group Themes",
								hard: true,
								link: "/jackpots/group_themes",
								name: "bonus-group-themes",
							},
							{
								title: "{theme_name}",
								link: "/jackpots/group_themes/{theme_name}",
								name: "theme-detail",
							},
						],
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.group_themes.detail",
								val: permissions.EDIT | permissions.READ,
							}),
					},
					component: lazyLoad("jackpots/EditGroupTheme"),
				},
				{
					path: "/jackpots/bonus_configs",
					name: "bonus-bonus-configs",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: { title: "Bonus Config", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.bonus_config.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("jackpots/JackpotBonusConfigsList"),
				},
				{
					path: "/jackpots/bonus_configs/:bonus_config_id",
					name: "bonus-config-detail",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: [
							{
								title: "Bonus Config",
								hard: true,
								link: "/jackpots/bonus_configs",
								name: "bonus-bonus-configs",
							},
							{
								title: "{bonus_config_id}",
								link: "/jackpots/bonus_configs/{bonus_config_id}",
								name: "bonus-config-detail",
							},
						],
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.bonus_config.detail",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("jackpots/BonusConfigDetail"),
				},
				{
					path: "/jackpots/levels",
					name: "bonus-levels",
					meta: {
						section: "Jackpot",
						defaultBreadcrumbs: { title: "Bonus Levels", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.levels.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("levels/JackpotLevelsList"),
				},
				{
					path: "/jackpots/paid",
					name: "bonus-paid-bonuses",
					meta: {
						defaultBreadcrumbs: { title: "Paid Bonuses", hard: true },
						disallow: (userPerm) =>
							!permControl(userPerm, {
								key: "bonus.paid_bonuses.list",
								val: permissions.READ,
							}),
					},
					component: lazyLoad("jackpots/JackpotPaidBonusesList"),
				},
			],
		},
		{
			path: "/404",
			name: "404",
			meta: {
				commander: false,
			},
			component: lazyLoad("errorPages/LoganError404"),
		},
		{
			path: "/401",
			name: "401",
			meta: {
				commander: false,
			},
			component: lazyLoad("errorPages/LoganError401"),
		},
		{
			path: "/autherror",
			name: "AuthError",
			meta: {
				commander: false,
			},
			component: lazyLoad("errorPages/LoganErrorNetwork"),
		},
		{
			path: "*",
			meta: {
				commander: false,
			},
			component: lazyLoad("errorPages/LoganError404"),
		},
	],
});
router.afterEach((to, from) => {
	function replaceStringParams(src, params) {
		return Object.entries(params).reduce(
			(prev, [key, value]) => prev?.replace(`{${key}}`, value),
			src
		);
	}
	if (to.params.breadcrumbs) {
		store.commit("breadcrumbs/clearPoppedCrumbs");
		if (to.params.breadcrumbs.hard) {
			if (to.params.breadcrumbs.stack) {
				store.commit("breadcrumbs/setCrumbs", to.params.breadcrumbs.stack);
			} else {
				store.commit("breadcrumbs/setCrumbs", {
					title: to.params?.breadcrumbs?.title,
					name: to.name,
					params: to.params,
					link: to.fullPath,
				});
			}
		} else {
			let replacable = [...store.getters["breadcrumbs/shortCrumbsFromHistory"]]
				.reverse()
				.findIndex((el) => {
					const currentQuery = to.params?.query ?? {};
					const breadcrumQuery = el.params?.query ?? {};
					const queryMatch = _.isEqual(currentQuery, breadcrumQuery);
					return (
						el.title &&
						to.params.breadcrumbs.title &&
						el.title === to.params.breadcrumbs.title &&
						queryMatch
					);
				});
			if (replacable != -1) {
				store.commit("breadcrumbs/backToCrumb", replacable);
				return;
			}
			store.commit("breadcrumbs/pushCrumb", {
				title: to.params?.breadcrumbs?.title,
				name: to.name,
				params: to.params,
				link: to.fullPath,
			});
		}
	} else {
		//breadcrumbs not set in navigation
		//either its history navigation, refresh
		//or user put in url manually
		const last = store.getters["breadcrumbs/lastCrumb"];
		const lastPopped = store.getters["breadcrumbs/lastPoppedCrumb"];
		const current = store.getters["breadcrumbs/currentCrumb"];
		if (to.fullPath === last?.link) {
			//back button
			store.commit("breadcrumbs/popCrumb");
		} else if (to.fullPath === lastPopped?.link) {
			//forward button
			store.commit("breadcrumbs/unPopCrumb");
		} else if (to.fullPath !== current?.link) {
			//user put in url manually, use default breadcrumbs
			store.commit("breadcrumbs/clearPoppedCrumbs");
			if (Array.isArray(to.meta.defaultBreadcrumbs))
				store.commit(
					"breadcrumbs/setCrumbs",
					to.meta.defaultBreadcrumbs.map((el, index) => ({
						link: replaceStringParams(el.link, to.params),
						title: replaceStringParams(el.title, to.params),
						params: {
							...to.params,
							breadcrumbs: {
								...el,
								link: replaceStringParams(el.link, to.params),
								title: replaceStringParams(el.title, to.params),
							},
						},
						name: el.name,
					}))
				);
			else
				store.commit("breadcrumbs/setCrumbs", {
					link: to.fullPath,
					title: replaceStringParams(
						to.meta?.defaultBreadcrumbs?.title,
						to.params
					),
					params: {
						...to.params,
						breadcrumbs: {
							...to.meta.defaultBreadcrumbs,
						},
					},
					name: to.meta?.defaultBreadcrumbs?.name,
				});
		}
		//page was refreshed, nothing needs to happen here
	}
});
router.beforeEach(async (to, from, next) => {
	//check if user has valid login
	await store.dispatch("apiCall/cancelOnNavigation");
	const loginValid = await store.dispatch("apiCall/checkTokenExpiry");

	if (to.path === "/authorize" && !loginValid) {
		if (!to.query.state || !to.query.session_state || !to.query.code) {
			next({ name: "login" });
			return;
		}
		next();
		return;
	}

	if (to.name === "login" && !loginValid) {
		await store.dispatch("loadConfigFile");
		next();

		try {
			window.location.href = store.getters.catUrl + "/api/v1/auth/login";
		} catch (error) {
			next({ name: "AuthError" });
		}

		//don't care about login with guarding
		return;
	}

	if (!loginValid) {
		//if login invalid, clear user store and route to login page
		store.dispatch("logout", to);
		next({ name: "login" });
		return;
	}

	const userFlags = store.getters.userPerm;
	const allowedCasinos = store.getters.allowedCasinos;
	const allowedGames = store.getters["meta2/allowedGames"];
	const allowedStudios = store.getters.allowedStudios;
	const currentCasino = store.getters.currentCasino;

	//check if user has any Logan permissions
	if (
		(!allowedCasinos ||
			!allowedStudios ||
			!allowedGames ||
			!userFlags ||
			Object.keys(userFlags).length == 0 ||
			allowedCasinos.length == 0 ||
			!permControl(userFlags, {
				key: "meta_casinos.list",
				val: permissions.READ,
			}) ||
			!permControl(userFlags, {
				key: "meta_games.list",
				val: permissions.READ,
			})) &&
		to.name != "401"
	) {
		store.commit("logout");
		next({ name: "401" });
		return;
	}
	//check route guard, if route has one
	if (to.meta.disallow?.(userFlags, to)) {
		//user doesn't have required permissions, navigate to 401 page
		next({ name: "401" });
		return;
	}

	if (to.name === "rounds") {
		const searchKeys =
			to.params?.searchKeys ??
			JSON.parse(sessionStorage.getItem("roundSearchKeys") ?? `[]`);
		if (!searchKeys || searchKeys?.length == 0) {
			next({ name: "404" });
		}
	}

	if (loginValid) {
		if (to.path === "/authorize" || to.path === "/login") {
			router.push({
				name: "home",
				params: { casino: currentCasino ?? allowedCasinos[0].casino_id },
			});
			return;
		}
		if (to.path === "/autherror") {
			next({
				name: "home",
				params: { casino: currentCasino ?? allowedCasinos[0].casino_id },
			});
			return;
		}
	}

	if (
		to.name === from.name &&
		to.params.casino &&
		from.params.casino &&
		to.params.casino != from.params.casino &&
		from.meta.onCasinoChange
	) {
		router.push({
			name: from.meta.onCasinoChange.name,
			params: { casino: to.params.casino },
		});
		return;
	}

	if (to.path.includes("findCasino")) {
		if (currentCasino) {
			next({ path: to.path.replace("findCasino", currentCasino) });
		} else {
			next({
				path: to.path.replace("findCasino", allowedCasinos[0].casino_id),
			});
		}
		return;
	}

	if (to.params.casino) {
		const toCasino = allowedCasinos.find(
			(el) => el.casino_id == to.params.casino
		);
		if (!toCasino) {
			next({ name: "401" });
			return;
		}
		await store.dispatch("setupCurrentCasino", toCasino);
	}

	//all checks passed, let user to page
	next();
});

export default router;
