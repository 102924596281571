export class JackpotGroup {
	static fromDTO(params) {
		if (params.contrib == undefined) return { ...params };
		const contrib = params.contrib * 100;
		return {
			...params,
			contrib,
		};
	}
	static toDTO(params) {
		// eslint-disable-next-line no-debugger
		// debugger;
		// const contrib = params.contrib / 100;
		return {
			...params,
			// contrib,
		};
	}
}
