import { getters } from "../common";
export default {
	...getters,
	query(state) {
		return state.list.metadata.query;
	},
	getQsConfig(state) {
		return state.list.metadata.config;
	},
	isFirst(state) {
		return state.list.metadata.query?.previous?.query_string === null;
	},
	isLast(state) {
		return state.list.metadata.query?.next?.query_string === null;
	},
};
