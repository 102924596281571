export class Player {
	static fromDTO(dto) {
		if (dto.minor_units !== null && dto.minor_units !== undefined) {
			dto.sum_bet = dto.sum_bet / 10 ** dto.minor_units;
			dto.sum_win = dto.sum_win / 10 ** dto.minor_units;
			dto.balance = dto.balance / 10 ** dto.minor_units;
			dto.bet_per_game = dto.bet_per_game / 10 ** dto.minor_units;
		}
		return {
			...dto,
			balance: dto.balance,
			bet_per_game: dto.bet_per_game,
			sum_bet: dto.sum_bet,
			sum_win: dto.sum_win,
		};
	}
}
