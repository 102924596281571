<template>
	<div>
		<v-slide-y-reverse-transition>
			<v-hover
				v-if="messagesToShow.length > 0"
				v-slot="{ hover }">
				<v-snackbar
					bottom
					:value="true"
					right
					:timeout="-1"
					color="transparent"
					elevation="0"
					class="pa-0 mb-6">
					<div
						class="notification"
						:style="`${
							hover
								? `min-height: ${Math.min(messagesToShow.length, 5) * 60}px;
									max-height: ${Math.min(messagesToShow.length, 5) * 60}px;
									 min-width: min(560px, 90vw)`
								: ''
						}`">
						<v-scroll-y-transition group>
							<v-alert
								:icon="false"
								v-for="(message, index) of messagesToShow"
								:key="message.id"
								v-bind="getType(message)"
								:style="{
									transform: hover
										? ''
										: `scale(${
												1 - (messagesToShow.length - 1 - index) * 0.08
										  })`,
									bottom: `${
										(messagesToShow.length - 1 - index) * (hover ? 70 : 20)
									}px`,
								}"
								style="
									transform-origin: bottom center;
									padding-top: 17px !important;
									width: min(560px, 90vw);
								"
								@input="(e) => removeFromErrors(e, message)"
								dismissible
								elevation="12"
								class="ma-0 slide notification"
								:class="{ unique: message.noteType === 'unique' }">
								{{ message.noteMessage }}
								<template #prepend>
									<v-icon
										size="20"
										class="mr-2">
										{{ getIcon(message) }}
									</v-icon>
								</template>
								<template #close="{ toggle }">
									<v-progress-circular
										size="30"
										:value="100 - compTime(message.created)"
										color="primary">
										<v-icon
											@click="toggle"
											size="26">
											mdi-close-circle
										</v-icon>
									</v-progress-circular>
								</template>
							</v-alert>
						</v-scroll-y-transition>
					</div>
				</v-snackbar>
			</v-hover>
		</v-slide-y-reverse-transition>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	props: {
		showCount: {
			type: Number,
			default: 5,
		},
	},
	data() {
		return {
			noteMessage: "",
			nMessages: [],
			show: true,
			now: 0,
			interval: undefined,
		};
	},
	mounted() {
		this.updateNow();
		this.interval = setInterval(() => this.updateNow(), 50);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		compTime() {
			return (x) => {
				const res = ((this.now - x) / (this.errorDuration * 1000 - 100)) * 100;
				return res;
			};
		},
		typeComputed() {
			if (this.noteType === "unique") return;
			return { type: this.noteType };
		},
		computedUnique() {
			if (this.noteType !== "unique") return "";
			return "color: black; font-weight: bolder !important; background: linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 216, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%)";
		},
		messagesToShow() {
			return this.arrayOfMessages.slice(-this.showCount);
		},
		...mapGetters("notification", ["arrayOfMessages", "errorDuration"]),
	},
	methods: {
		...mapActions({
			removeMessage: "notification/removeMessage",
		}),
		updateNow() {
			this.now = Date.now();
		},
		getIcon(msg) {
			switch (msg.noteType) {
				case "unique":
					return "mdi-unicorn";
				case "success":
					return "mdi-check-circle";
				default:
					return msg.noteType;
			}
		},
		getType(msg) {
			if (msg.noteType === "unique") return;
			return { type: msg.noteType };
		},
		removeFromErrors(e, message) {
			this.removeMessage(message);
		},
	},
	watch: {
		noteType(newVal, oldVal) {},
		arrayOfMessages: {
			handler(newVal, oldVal) {
				this.nMessages = newVal;
			},
			deep: true,
		},
		errorMessage(newValue, oldValue) {
			if (newValue != "") {
				this.noteMessage = newValue;
				this.show = true;
			}
		},
	},
};
</script>

<style lang="sass" scoped>
.unique
	color: black
	font-weight: bolder !important
	background: linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 216, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%)
.notification
	position: absolute !important
	bottom: 0
	right: 0

	// TODO: PICK ANIMATION
.slide
	transition: all 0.25s cubic-bezier(0.45, 0.05, 0.55, 0.95)
	animation: fly 0.25s ease-out

@keyframes fly
	0%
		transform: translate(0, 100%)
		opacity: 0
	40%
		opacity: 0
	100%
		transform: translate(0, 0)
		opacity: 1

::v-deep .v-progress-circular__overlay
	transition: all .125s linear
</style>
