<template>
	<v-fade-transition>
		<LoganLoading v-if="!destroy">
			<div class="mt-5">{{ loadingMessage }}</div>
		</LoganLoading>
	</v-fade-transition>
</template>
<script>
import { constants } from "../constants/constants";
import LoganLoading from "./LoganLoading.vue";
import { loadingMessages } from "../constants/loading";
export default {
	components: {
		LoganLoading,
	},
	computed: {
		loadingMessage() {
			if (Math.floor(Math.random() * 10) < 1)
				return loadingMessages[
					Math.floor(Math.random() * loadingMessages.length)
				];
			if (this.$route.name == "login") return "Redirecting to Keycloak...";
			return "Authorizing with Keycloak...";
		},
	},
	data() {
		return {
			destroy: false,
		};
	},
	async created() {
		window.addEventListener("beforeunload", () => {
			this.destroy = true;
		});

		if (this.$route.name == "login") return;
		await this.$store.dispatch("loadConfigFile");
		await this.$store.dispatch("login", { query: this.$route.query });
		try {
			const navObj = JSON.parse(
				localStorage.getItem(constants.authRedirectLocalStorageKey) ?? {}
			);
			const navName =
				Object.keys(navObj).length === 0 || navObj?.name === "authorize"
					? { name: "home" }
					: navObj;
			localStorage.removeItem(constants.authRedirectLocalStorageKey);
			this.$router.push(navName);
		} catch (e) {
			console.error("Unable to navigate to previous navitem");
			console.error("Navigating to dashboard");
			localStorage.removeItem(constants.authRedirectLocalStorageKey);
			this.$router.push({ name: "home" });
		}
	},
};
</script>
