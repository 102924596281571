import { permControl } from "../../../constants/helpers";

const baseUrl = "/api/v1/janosik";
const internalUrl = "/internal/casinos";
const bwlUrl = "/bwl/casinos";
const casinosUrl = "/casinos";
export default {
	//TODO: [L2-124] HANDLE BE ERRORS
	async loadList(
		{ commit, dispatch, getters, rootGetters },
		{ payload = {} } = { payload: {} }
	) {
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/offers/filter`,
					payload: {
						order_by: getters.pagination.order_by,
						order_as: getters.pagination.order,
						pagination: {
							limit: getters.pagination.per_page,
							page: getters.pagination.page,
						},
						...payload,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data.filter(
				(el) =>
					!el.is_removed ||
					permControl(rootGetters.userPerm, {
						key: "free_games.list",
						val: 0b1000,
					})
			);

			commit("list", data);
			return response.data.pagination;
		} catch (e) {
			if (e?.message === "canceled") throw e;
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load free games list", type: "error" },
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});

			return { total_records: 0 };
		}
	},
	async loadDetail({ commit, dispatch, rootGetters }, { offer_code }) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/get_offer`,
					params: {
						offer_code,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			const data = response.data;

			commit("detail", data);
			// return response.data._meta_data.pagination;
		} catch (e) {
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load free games detail", type: "error" },
				{ root: true }
			);
			commit("detail", e.response.status);
		}
	},
	async loadOffersPerPlayer(
		{ commit, dispatch, rootGetters },
		{ playerId, noErr = false, casino_id = rootGetters.currentCasino }
	) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}${internalUrl}/${casino_id}/players/${playerId}/offers`,
					protectedPath: true,
				},
				{ root: true }
			);
			commit("playerOffers", response.data);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			if (!noErr) {
				dispatch(
					"notification/setMessage",
					{ message: "Failed to load player offers", type: "error" },
					{ root: true }
				);
			}
			commit("playerOffers", []);
			return [];
		}
	},
	async loadBWList({ dispatch, rootGetters }, { offerCode }) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}${bwlUrl}/${rootGetters.currentCasino}/offers/${offerCode}/list/search`,
					protectedPath: true,
				},
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			return { players: [], list_type: -1 };
		}
	},
	async loadGames({ commit, dispatch, rootGetters }, currency) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}${casinosUrl}/${rootGetters.currentCasino}/get_games`,
					params: { currency },
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.games;
			commit("gamesList", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load games list", type: "error" },
				{ root: true }
			);
		}
	},
	async loadBets({ commit, dispatch, rootGetters }, { game_id, currency }) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}${casinosUrl}/${rootGetters.currentCasino}/get_game_configuration`,
					params: { game_id, currency },
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.bets;
			commit("bets", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load bets", type: "error" },
				{ root: true }
			);
		}
	},
	async createBWList(
		{ dispatch, rootGetters },
		{ offerCode, listMode, players }
	) {
		try {
			const response = await dispatch(
				"apiCall/loganPut",
				{
					path: `${baseUrl}${bwlUrl}/${rootGetters.currentCasino}/offers/${offerCode}`,
					payload: { list_type: listMode, players },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Created B/W list", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create B/W list", type: "error" },
				{ root: true }
			);
		}
	},

	async addToBWList({ dispatch, rootGetters }, { offerCode, playerId }) {
		try {
			const response = await dispatch(
				"apiCall/loganPut",
				{
					path: `${baseUrl}${bwlUrl}/${rootGetters.currentCasino}/offers/${offerCode}/players/${playerId}`,
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Added player to B/W list", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to add player to B/W list", type: "error" },
				{ root: true }
			);
		}
	},
	async deleteBWList({ dispatch, rootGetters }, { offerCode, listMode }) {
		try {
			const response = await dispatch(
				"apiCall/loganDelete",
				{
					path: `${baseUrl}${bwlUrl}/${rootGetters.currentCasino}/offers/${offerCode}`,
					payload: { list_type: listMode },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Deleted B/W list", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to delete B/W list", type: "error" },
				{ root: true }
			);
		}
	},
	async deleteFromBWList({ dispatch, rootGetters }, { offerCode, playerId }) {
		try {
			const response = await dispatch(
				"apiCall/loganDelete",
				{
					path: `${baseUrl}${bwlUrl}/${rootGetters.currentCasino}/offers/${offerCode}/players/${playerId}`,
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Removed player from B/W list", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to remove player from B/W list", type: "error" },
				{ root: true }
			);
		}
	},
	async createOffer({ dispatch, rootGetters }, payload) {
		try {
			const p = { ...payload };
			delete p.offer_code;
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}${casinosUrl}/${rootGetters.currentCasino}/create_offer`,
					payload: p,
					query: { offer_code: payload.offer_code },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Created offer", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create offer", type: "error" },
				{ root: true }
			);
		}
	},

	async createRule({ dispatch, rootGetters }, { offerCode, rule }) {
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/offer_rule`,
					payload: {
						...rule,
					},
					query: { offer_code: offerCode },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Created rule", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create rule", type: "error" },
				{ root: true }
			);
		}
	},

	async removeRule({ dispatch, rootGetters }, { offerCode }) {
		try {
			const response = await dispatch(
				"apiCall/loganDelete",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/offer_rule`,
					params: {
						offer_code: offerCode,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Removed rule", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to remove rule", type: "error" },
				{ root: true }
			);
		}
	},
	async updateRule({ dispatch, rootGetters }, { offerCode, rule }) {
		try {
			const response = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/offer_rule`,
					payload: {
						...rule,
					},
					query: {
						offer_code: offerCode,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Updated offer rule", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update offer rule", type: "error" },
				{ root: true }
			);
		}
	},

	async loadPlayersPerOffer({ commit, dispatch, rootGetters }, offer_code) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/players_per_offer`,
					params: { offer_code },
					protectedPath: true,
				},
				{ root: true }
			);
			commit("players", response.data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load players", type: "error" },
				{ root: true }
			);
		}
	},
	async updateOffer({ dispatch, rootGetters }, offer) {
		try {
			const tmp = { ...offer };
			delete tmp.offer_code;
			delete tmp.name;
			delete tmp.gamble_enabled;
			delete tmp.games;
			delete tmp.internal;
			const response = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/modify_offer`,
					payload: {
						...tmp,
						// is_removed: false,
					},
					query: {
						offer_code: offer.offer_code,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			if ("is_removed" in offer) {
				dispatch(
					"notification/setMessage",
					{
						message: offer.is_removed ? "Removed offer" : "Restored offer",
						type: "success",
					},
					{ root: true }
				);
			} else {
				dispatch(
					"notification/setMessage",
					{ message: "Updated offer", type: "success" },
					{ root: true }
				);
			}
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update offer", type: "error" },
				{ root: true }
			);
		}
	},
	async addPlayerToOffer({ dispatch, rootGetters }, payload) {
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}${casinosUrl}/${rootGetters.currentCasino}/add_player`,
					query: {
						offer_code: payload.offer_code,
						player_id: payload.player_id,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Added player to offer", type: "success" },
				{ root: true }
			);

			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to add player to offer", type: "error" },
				{ root: true }
			);
		}
	},

	async addPlayersToOffer({ dispatch, rootGetters }, { offer_code, players }) {
		try {
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}${casinosUrl}/${rootGetters.currentCasino}/add_players`,
					payload: {
						players,
					},
					query: {
						offer_code,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Added player to offer", type: "success" },
				{ root: true }
			);

			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to add player to offer", type: "error" },
				{ root: true }
			);
		}
	},

	async removePlayerFromOffer({ dispatch, rootGetters }, payload) {
		try {
			const response = await dispatch(
				"apiCall/loganDelete",
				{
					path: `${baseUrl}${casinosUrl}/${rootGetters.currentCasino}/remove_player`,
					params: {
						offer_code: payload.offer_code,
						player_id: payload.player_id,
					},
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Removed player from offer", type: "success" },
				{ root: true }
			);

			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to remove player from offer", type: "error" },
				{ root: true }
			);
		}
	},

	async addRoundsToPlayer({ dispatch, rootGetters }, payload) {
		try {
			const response = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/add_free_rounds`,
					query: {
						offer_code: payload.offer_code,
						player_id: payload.player_id,
					},
					payload: { spins_to_add: payload.spins_to_add },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Added rounds to player", type: "success" },
				{ root: true }
			);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to add rounds to player", type: "error" },
				{ root: true }
			);
		}
	},

	async updateGames({ dispatch, rootGetters }, payload) {
		let p = { ...payload };
		delete p.offer_code;
		try {
			const response = await dispatch(
				"apiCall/loganPut",
				{
					path: `${baseUrl}${internalUrl}/${rootGetters.currentCasino}/modify_games`,
					payload: p,
					query: { offer_code: payload.offer_code },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Updated games", type: "success" },
				{ root: true }
			);
			return response.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update games", type: "error" },
				{ root: true }
			);
		}
	},
};
