import { StatisticsConverter } from "../../../../constants/converters/statisticsConverter";
import { getters, rootGetters } from "../../common";
import { clone } from "../../../../constants/helpers";
import _ from "lodash";

export default {
	...getters,
	data(state) {
		return state.data;
	},
	dataPartial: (state) => (userPerm, key, scope, aggregator) => {
		try {
			return aggregate(userPerm, clone(state.data[key]), aggregator, scope);
		} catch (error) {
			return [];
		}
	},
	failedPartial: (state) => (key) => {
		try {
			return state.data[key].failed;
		} catch (error) {
			return [];
		}
	},
	progressPartial: (state) => (key) => {
		try {
			return state.data[key].progress;
		} catch (error) {
			return 0;
		}
	},
	worker: (state) => (scope) => {
		return state[`${scope}Worker`] ?? false;
	},
};

function aggregate(userPerm, data, groupBy) {
	data.aggregated.classic = arrayify(data.aggregated.classic)?.map((el) =>
		StatisticsConverter.fromDTO(el)
	);

	data.aggregated.special = arrayify(data.aggregated.special)?.map((el) =>
		StatisticsConverter.fromDTO(el)
	);

	["free_round", "joker_bonus"].forEach(
		(el) =>
			(data.aggregated[el] = data.aggregated.special.filter(
				(e) => e.flag === el
			))
	);

	data.aggregated.total = data.aggregated.classic.map((el) =>
		StatisticsConverter.globalize(
			userPerm,
			el,
			data.aggregated.special.filter((spec) => el[groupBy] == spec[groupBy]) ??
				{}
		)
	);

	data.global = {
		total: StatisticsConverter.globalize(
			userPerm,
			data.aggregated.classic,
			data.aggregated.special
		),
	};
	["classic", "special"].forEach(
		(el) =>
			(data.global[el] = StatisticsConverter.globalize(
				userPerm,
				data.aggregated[el] ?? {}
			))
	);
	["free_round", "joker_bonus"].forEach(
		(el) =>
			(data.global[el] = StatisticsConverter.globalize(
				userPerm,
				data.aggregated.special.filter((spec) => el === spec.flag) ?? {}
			))
	);

	Object.keys(data.per_casino).forEach((casinoId) => {
		data.per_casino[casinoId].classic = arrayify(
			data.per_casino[casinoId].classic
		)?.map((el) => StatisticsConverter.fromDTO(el));
		data.per_casino[casinoId].special = arrayify(
			data.per_casino[casinoId].special
		)?.map((el) => StatisticsConverter.fromDTO(el));

		["free_round", "joker_bonus"].forEach(
			(el) =>
				(data.per_casino[casinoId][el] = data.per_casino[
					casinoId
				].special.filter((e) => e.flag === el))
		);
		data.per_casino[casinoId].total = data.per_casino[casinoId].classic.map(
			(el) => {
				return StatisticsConverter.globalize(
					userPerm,
					el,
					data.per_casino[casinoId].special.filter(
						(e) => e[groupBy] == el[groupBy]
					)
				);
			}
		);

		data.per_casino[casinoId].global = {
			total: StatisticsConverter.globalize(
				userPerm,
				data.per_casino[casinoId].classic,
				data.per_casino[casinoId].special
			),
		};
		["classic", "special"].forEach(
			(el) =>
				(data.per_casino[casinoId].global[el] = StatisticsConverter.globalize(
					userPerm,
					data.per_casino[casinoId][el]
				))
		);
		["free_round", "joker_bonus"].forEach(
			(el) =>
				(data.per_casino[casinoId].global[el] = StatisticsConverter.globalize(
					userPerm,
					data.per_casino[casinoId].special.filter((e) => e.flag === el)
				))
		);

		if (data.per_casino[casinoId] == undefined) {
			delete data.per_casino[casinoId];
		}
	});
	return data;
}

function arrayify(el) {
	if (!Array.isArray(el)) {
		return [el];
	}
	return el;
}
