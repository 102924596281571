<template>
	<div id="kajot-footer">
		<div class="d-flex justify-center">
			<div id="develop">
				&copy; 2022 Development 777 s. r. o. (
				<a
					class="pa-0 ma-0"
					href="https://www.kajotgames.com">
					Kajot Games
				</a>
				)
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "DashboardCoreFooter",

	data: () => ({}),
	computed: {
		...mapGetters(["getVersion"]),
	},
};
</script>

<style lang="sass">

#version
	position: absolute
	font-size: .825rem
	right: 1rem
	bottom: 0

.justify-self-end
	justify-self: end

#kajot-footer
	height: 20px
	font-size: .825rem
	user-select: none
	background-color: var(--v-menu_background-base)
	position: fixed !important
	bottom: 0 !important
	left: 0 !important
	right: 0 !important

#kajot-footer
	a
		font-weight: 500
		text-decoration: none
		text-transform: uppercase
</style>
