import { state as defaultState } from "../common";
export const state = () => ({
	...defaultState(),
	list: {
		data: [],
		metadata: {
			config: [
				{
					value: "pagination",
					type: "pagination",
				},
			],
			filter: {},
			pagination: {
				order: "+",
				order_by: "id",
				page: 1,
				per_page: 10,
				total_pages: 0,
				total_records: 0,
			},
			query: {
				previous: null,
				next: null,
				first: null,
				last: null,
			},
		},
	},
});
