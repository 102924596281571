import { mutations } from "../../common";
export default {
	...mutations,
	games(state, payload) {
		state.games = payload;
	},
	casinos(state, payload) {
		state.casinos = payload;
	},
	filteredGroups(state, payload) {
		state.filteredGroups = payload;
	},
};
