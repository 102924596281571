import { AxiosError } from "axios";
const baseUrl = "/api/v1/manage";
export default {
	async loadGroupList({ dispatch }) {
		await dispatch("loadList", "sections");
		return;
	},
	async loadCasinoList({ dispatch }) {
		await dispatch("loadList", "casinos");
		return;
	},
	async loadStudioList({ dispatch }) {
		await dispatch("loadList", "studios");
		return;
	},
	async loadList({ dispatch, commit }, partial = undefined) {
		const part = partial ? [partial] : undefined;
		try {
			const res = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/permissions/list`,
					protectedPath: true,
					payload: { apps: ["logan"], filter_by: part },
				},
				{ root: true }
			);

			if (partial == "casinos") {
				commit("casinos", res);
			} else if (partial === "sections") {
				commit("groups", res);
			} else if (partial === "studios") {
				commit("studios", res);
			} else {
				commit("list", res);
			}
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load permission modules", type: "error" },
				{ root: true }
			);

			if (partial == "casinos") {
				commit("casinos", []);
			} else if (partial === "sections") {
				commit("groups", []);
			} else if (partial === "studios") {
				commit("studios", []);
			} else {
				commit("list", []);
			}
			return [];
		}
	},
	async loadDetail({ dispatch, commit }, query) {
		try {
			const res = await dispatch(
				"apiCall/loganPost",
				{
					path: `${baseUrl}/permissions/find`,
					payload: { app_name: "logan", module_name: query },
					protectedPath: true,
				},
				{ root: true }
			);
			commit("detail", res);
			return res.data;
		} catch (_e) {
			if (_e.message === "canceled") {
				throw _e;
			}
			/**
			 * @type {AxiosError<{error:string}>}
			 */
			const e = _e;
			if (e.response.status == 404) {
				commit("detail", { data: {} });
				return [];
			}
			console.error(`Error ocurred while fetching detail from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load permission module", type: "error" },
				{ root: true }
			);
			commit("detail", { data: {} });
			return [];
		}
	},
	async loadInternalDetail({ commit }, query) {
		await commit("detail", query);
		return true;
	},
	async create({ dispatch }, { data }) {
		try {
			let payload = data;
			const res = await dispatch(
				"apiCall/loganPut",
				{
					path: `${baseUrl}/permissions/module`,
					payload,
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: `Updated ${baseUrl.split("/").pop()}`, type: "success" },
				{ root: true }
			);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while creating item at ${baseUrl}/create`,
				e
			);

			dispatch(
				"notification/setMessage",
				{
					message: `Failed to create ${baseUrl.split("/").pop()}`,
					type: "error",
				},
				{ root: true }
			);
			return null;
		}
	},
	async edit({ dispatch }, { data }) {
		try {
			let payload = data;
			const res = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}/permissions/module`,
					payload,
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: `Updated ${baseUrl.split("/").pop()}`, type: "success" },
				{ root: true }
			);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while creating item at ${baseUrl}/create`,
				e
			);

			dispatch(
				"notification/setMessage",
				{
					message: `Failed to create ${baseUrl.split("/").pop()}`,
					type: "error",
				},
				{ root: true }
			);
			return null;
		}
	},
	async delete({ dispatch }, name) {
		try {
			const res = await dispatch(
				"apiCall/loganDelete",
				{
					path: `${baseUrl}/permissions/module`,
					payload: { app_name: "logan", module_name: name },
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: `Updated ${baseUrl.split("/").pop()}`, type: "success" },
				{ root: true }
			);
			return res;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while creating item at ${baseUrl}/create`,
				e
			);

			dispatch(
				"notification/setMessage",
				{
					message: `Failed to create ${baseUrl.split("/").pop()}`,
					type: "error",
				},
				{ root: true }
			);
			return null;
		}
	},
};
