<template>
	<v-app
		id="inspire"
		style="background-color: var(--v-background-base)"
		class="primary pa-0!important"
		:class="{ iOS: isIOS }">
		<transition
			name="fade-transition"
			:duration="{ enter: 0, leave: 150 }"
			appear
			mode="out-in"
			leave-absolute>
			<router-view class="pa-0 ma-0" />
		</transition>

		<ErrorMessage></ErrorMessage>
		<v-footer
			v-if="location != '/frame'"
			app
			bottom
			absolute
			padless
			class="py-0 black">
			<kajot-footer :class="{ loginFooter: $route.path === '/login' }" />
		</v-footer>
	</v-app>
</template>

<script>
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Colors,
	Legend,
	LineElement,
	LinearScale,
	LogarithmicScale,
	PointElement,
	Title,
	Tooltip,
	Filler,
} from "chart.js";
ChartJS.register(
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	ArcElement,
	LogarithmicScale,
	PointElement,
	LineElement,
	Colors,
	Filler
);
import { mapActions, mapMutations } from "vuex";
import ErrorMessage from "./components/shared/ErrorMessage";
import KajotFooter from "./components/shared/Footer.vue";
import { constants } from "./constants/constants";
import { isIOS } from "./constants/helpers";

export default {
	components: {
		ErrorMessage,
		KajotFooter,
	},
	data() {
		return {};
	},
	computed: {
		isIOS,
		authh() {
			return this.$route.name === "authorize";
		},
		location() {
			return window.location.pathname;
		},
	},
	methods: {
		...mapActions(["switchDrawer"]),
		...mapActions({
			loadConfig: "loadConfigFile",
		}),
		...mapMutations("globalTicker", {
			initTicker: "init",
		}),
	},

	async created() {
		this.initTicker();
		const color = localStorage.getItem("logan:color");
		if (color) {
			this.$vuetify.theme.themes.dark.primary = color;
			this.$vuetify.theme.themes.light.primary = color;
		}
		const userSettings = JSON.parse(
			localStorage.getItem(constants.settingsLocalStorageKey)
		);
		const darkMode = userSettings?.dark_mode;
		if (darkMode !== null) {
			this.$vuetify.theme.dark = darkMode;
		}
		const miniMenu = userSettings?.mini_menu;
		this.switchDrawer(miniMenu);
	},
};
</script>

<style media="screen">
a {
	text-decoration: none;
}
</style>
