export default function (baseUrl, convertClass = null) {
	return {
		async loadList(
			{ commit, dispatch, getters },
			{ query = {}, payload = {} } = { query: {}, payload: {} }
		) {
			try {
				const q = { ...getters.pagination, ...query };
				const p = { ...getters.filter, ...payload };
				const response = await dispatch(
					"apiCall/loganPut",
					{
						path: baseUrl,
						query: {
							order: q.order,
							order_by: q.order_by,
							per_page: q.per_page,
							page: q.page,
						},
						payload: p,
						protectedPath: true,
					},
					{ root: true }
				);
				let data = response.data.data;

				if (convertClass !== null) {
					data = data.map((item) => convertClass.fromDTO(item));
				}
				commit("list", data);
				return response.data._meta_data.pagination;
			} catch (e) {
				if (e.message === "canceled") {
					throw e;
				}
				console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
				dispatch(
					"notification/setMessage",
					{
						message: `Failed to load bonus ${baseUrl.split("/").pop()} list`,
						type: "error",
					},
					{ root: true }
				);
				commit("list", []);
				commit("pagination", {
					total_pages: 0,
					total_records: 0,
				});
			}
		},
		async loadDetail({ commit, dispatch }, id) {
			try {
				const response = await dispatch(
					"apiCall/loganGet",
					{
						path: `${baseUrl}/${id}`,
						protectedPath: true,
					},
					{ root: true }
				);
				let data = response.data;

				if (convertClass !== null) {
					data = convertClass.fromDTO(data);
				}
				if (!data) {
					commit("detail", {});
				}
				commit("detail", data);
			} catch (e) {
				if (e.message === "canceled") {
					throw e;
				}
				console.error(
					`Error ocurred while fetching detail from ${baseUrl}/${id}`,
					e
				);
				dispatch(
					"notification/setMessage",
					{
						message: `Failed to load ${baseUrl.split("/").pop()} detail`,
						type: "error",
					},
					{ root: true }
				);
				commit("detail", {});
			}
		},
		async create({ dispatch }, data) {
			try {
				let payload = data;
				if (convertClass !== null) {
					payload = convertClass.toDTO(payload);
				}
				const res = await dispatch(
					"apiCall/loganPost",
					{
						path: baseUrl,
						payload,
						protectedPath: true,
					},
					{ root: true }
				);

				if (convertClass !== null) {
					dispatch(
						"notification/setMessage",
						{ message: `Created bonus config`, type: "success" },
						{ root: true }
					);
					return convertClass.fromDTO(res);
				}
				dispatch(
					"notification/setMessage",
					{ message: `Created ${baseUrl.split("/").pop()}`, type: "success" },
					{ root: true }
				);
				return res;
			} catch (e) {
				if (e.message === "canceled") {
					throw e;
				}
				dispatch(
					"notification/setMessage",
					{
						message: `Failed to create ${baseUrl.split("/").pop()}`,
						type: "error",
					},
					{ root: true }
				);

				console.error(`Error ocurred while creating item at ${baseUrl}`, e);
				return null;
			}
		},
		async update({ dispatch }, { id, data }) {
			try {
				let payload = data;
				if (convertClass !== null) {
					payload = convertClass.toDTO(payload);
				}
				const res = await dispatch(
					"apiCall/loganPatch",
					{
						path: `${baseUrl}/${id}`,
						payload,
						protectedPath: true,
					},
					{ root: true }
				);
				if (convertClass !== null) {
					dispatch(
						"notification/setMessage",
						{ message: `Updated bonus config`, type: "success" },
						{ root: true }
					);
					return convertClass.fromDTO(res.data);
				}
				dispatch(
					"notification/setMessage",
					{ message: `Updated ${baseUrl.split("/").pop()}`, type: "success" },
					{ root: true }
				);
				return res.data;
			} catch (e) {
				if (e.message === "canceled") {
					throw e;
				}
				console.error(
					`Error ocurred while editing item at ${baseUrl}/${id}`,
					e
				);

				dispatch(
					"notification/setMessage",
					{
						message: `Failed to update ${baseUrl.split("/").pop()}`,
						type: "error",
					},
					{ root: true }
				);
				return null;
			}
		},
		async delete({ dispatch }, id) {
			try {
				const res = await dispatch(
					"apiCall/loganDelete",
					{
						path: `${baseUrl}/${id}`,
						protectedPath: true,
					},
					{ root: true }
				);

				// if (convertClass !== null) {
				// 	return convertClass.fromDTO(res.data);
				// }
				dispatch(
					"notification/setMessage",
					{ message: `Deleted ${baseUrl.split("/").pop()}`, type: "success" },
					{ root: true }
				);

				return res.data;
			} catch (e) {
				if (e.message === "canceled") {
					throw e;
				}
				console.error(
					`Error ocurred while deleting item at ${baseUrl}/${id}`,
					e
				);
				dispatch(
					"notification/setMessage",
					{
						message: `Failed to delete ${baseUrl.split("/").pop()}`,
						type: "error",
					},
					{ root: true }
				);

				return null;
			}
		},
		async unDelete({ dispatch }, id) {
			try {
				const res = await dispatch(
					"apiCall/loganPatch",
					{
						path: `${baseUrl}/${id}`,
						protectedPath: true,
						payload: {
							deleted: null,
						},
					},
					{ root: true }
				);
				// if (convertClass !== null) {
				// 	return convertClass.fromDTO(res.data);
				// }

				dispatch(
					"notification/setMessage",
					{ message: `Restored ${baseUrl.split("/").pop()}`, type: "success" },
					{ root: true }
				);

				return res.data;
			} catch (e) {
				if (e.message === "canceled") {
					throw e;
				}
				console.error(
					`Error ocurred while restoring item at ${baseUrl}/${id}`,
					e
				);
				dispatch(
					"notification/setMessage",
					{
						message: `Failed to restore ${baseUrl.split("/").pop()}`,
						type: "error",
					},
					{ root: true }
				);

				return null;
			}
		},
	};
}
