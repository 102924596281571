import FilterFactory from "../../../constants/converters/filterFactory";
import { Round } from "../../../constants/converters/round";

const state = {
	roundSearch: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	roundSearch: (state) => {
		return state["roundSearch"];
	},
};

const actions = {
	async loadRoundSearch(
		{ commit, dispatch, rootGetters },
		{ searchKey, noErr }
	) {
		commit("setRoundSearchLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/casinos/" + rootGetters.currentCasino + "/rounds/search",
				payload: { search_key: searchKey },
				protectedPath: true,
			});
			commit(
				"setRoundSearchData",
				res.data.map((el) => Round.fromDTO(el, rootGetters.currentCasino))
			);
			commit("setRoundSearchLoading", false);
		} catch (error) {
			commit("setRoundSearchData", undefined);
			commit("setRoundSearchLoading", false);
			console.error(" js round search error > \n", error);
		}
	},
	async loadBulkRoundSearch(
		{ commit, dispatch, rootGetters },
		{ searchKeys, searchBy }
	) {
		commit("setRoundSearchLoading", true);
		try {
			const config = [
				{
					type: "or",
					children: searchBy.map((key) => ({
						rKey: key,
						value: "searchKeys",
						type: "list",
					})),
				},
			];
			const url = FilterFactory.from(config, { searchKeys }).build();
			const res = await dispatch("apiCall/loganGet", {
				path: `/api/v2/casinos/${rootGetters.currentCasino}/rounds?${url}`,
				protectedPath: true,
			});
			const data = Round.fromObject(res.data, rootGetters.currentCasino);
			commit("setRoundSearchData", data);
			commit("setRoundSearchLoading", false);
		} catch (error) {
			commit("setRoundSearchData", undefined);
			commit("setRoundSearchLoading", false);
			console.error(" js round search error > \n", error);
		}
	},
};

const mutations = {
	setRoundSearchData(state, data) {
		state.roundSearch.data = data;
	},
	setRoundSearchLoading(state, value) {
		state.roundSearch.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
