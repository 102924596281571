import { JackpotConfig } from "../../../constants/converters/jackpotConfig";

const state = {
	JBConfigsList: {},
	filteredJBConfigsList: [],
	filterForJBConfigsList: {
		deleted: null,
	},
	paginationForJBConfigsList: {
		order: undefined,
		order_by: undefined,
		order_by_matched: undefined,
		page: 1,
		per_page: 10,
		total_pages: undefined,
		total_records: undefined,
	},
	JBConfigDetail: {},
	clearConfigDetail: {
		active_time: null,
		candidates_id: null,
		contrib_bet_range: null,
		contrib_ratio: null,
		count: null,
		created: null,
		deleted: null,
		distribution_id: null,
		id: null,
		jackpot_type_id: null,
		k: null,
		max_value: null,
		mean: null,
		min_contribution: null,
		min_jack: null,
		min_value: null,
		name: null,
		probability_id: null,
		selection_id: null,
		shares: null,
		trim: null,
		updated: null,
		variance: null,
	},
	updatedJBConfigDetail: {},
	createdJBConfig: {},
};

const getters = {
	getJBConfigsList: (state) => {
		return state["JBConfigsList"];
	},
	getFilteredJBConfigsList: (state) => {
		return state["filteredJBConfigsList"];
	},
	getFilterForJBConfigsList: (state) => {
		return state["filterForJBConfigsList"];
	},
	getPaginationForJBConfigsList: (state) => {
		return state["paginationForJBConfigsList"];
	},
	getJBConfigDetail: (state) => {
		return state["JBConfigDetail"];
	},
	getUpdatedJBConfigDetail: (state) => {
		return state["updatedJBConfigDetail"];
	},
	getCreatedJBConfig: (state) => {
		return state["createdJBConfig"];
	},
};

const actions = {
	/** LIST JACKPOTS CONFIGS */

	setupPaginationForJBConfigsList({ commit }, pagination) {
		try {
			commit("setPaginationForJBConfigsList", pagination);
		} catch (error) {
			console.error(
				" set pagination for Joker Bonus Configs list, error ",
				error
			);
		}
	},

	setupFilterForJBConfigsList({ commit }, filter) {
		try {
			commit("setFilterForJBConfigsList", filter);
		} catch (error) {
			console.error(" set filter for Joker Bonus Configs list, error ", error);
		}
	},

	/** FILTERED LISTING OF JACKPOTS CONFIGS */
	async loadFilteredJBConfigs(
		{ commit, dispatch, getters },
		{ filter = {}, queryParams = {} } = {}
	) {
		try {
			if (Object.keys(queryParams).length == 0) {
				queryParams = {
					page: getters.getPaginationForJBConfigsList.page,
					per_page: getters.getPaginationForJBConfigsList.per_page,
					order_by: getters.getPaginationForJBConfigsList.order_by,
					order: getters.getPaginationForJBConfigsList.order,
				};
			}

			const response = await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/jackpot_configs",
				payload: filter,
				query: queryParams,
				protectedPath: true,
			});
			const data = response.data.data.map((item) =>
				JackpotConfig.fromDTO(item)
			);

			commit("setFilteredJBConfigsList", data);
			dispatch("setupFilterForJBConfigsList", response.data._meta_data.filter);
			dispatch(
				"setupPaginationForJBConfigsList",
				response.data._meta_data.pagination
			);
		} catch (error) {
			console.error(
				" js filtered JACKPOT configs \n catch error after dispatch ",
				error
			);
		}
	},

	/** ADD NEW JACPOT CONFIG */
	async addJBConfigNew({ commit, dispatch }, jbConfig) {
		try {
			const payload = JackpotConfig.toDTO(jbConfig);
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/jackpot_configs",
				payload,
				protectedPath: true,
			});
			return res.data;
		} catch (error) {
			console.error(
				" js ADD JACKPOT config, catch ERROR after dispatch",
				error
			);
			return null;
		}
	},
	async addJBConfig({ commit, dispatch }, payload) {
		try {
			await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/jackpot_configs",
				payload: payload,
				protectedPath: true,
			})
				.then(
					(respon) => {
						commit("setCreatedJBConfig", respon);
					},
					(rejection) => {
						console.warn(
							"js ADD JACKPOT cONfig, rejection after dispatch ",
							rejection
						);
					}
				)
				.catch((erro) => {
					console.error("js ADD JACKPOT cONfig, catch after dispatch ", erro);
				});
		} catch (error) {
			console.error(
				" js ADD JACKPOT config, catch ERROR after dispatch",
				error
			);
		}
	},

	/** DETAIL OF JACKPOT CONFIG */
	async loadJBConfigDetail({ commit, dispatch, state }, jackpot_config_id) {
		try {
			commit("setJBConfigDetail", state.clearConfigDetail);
			const response = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/jackpot_configs/" + jackpot_config_id,
				protectedPath: true,
			});

			commit("setJBConfigDetail", JackpotConfig.fromDTO(response.data));
		} catch (erro) {
			console.error(
				" js load JACKPOT config detail, catch ERROR after dispatch",
				erro
			);
		}
	},

	/** UPDATE ONE OR MORE JACKPOT CONFIG ATTRIBUTES */
	async updateJBConfig({ commit, dispatch }, { jackpot_config_id, payload }) {
		try {
			const response = await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/jackpot_configs/" + jackpot_config_id,
				payload: payload,
				protectedPath: true,
			});
			commit("setUpdatedJBConfigDetail", JackpotConfig.fromDTO(response.data));
		} catch (erro) {
			console.error(
				" js load JACKPOT config detail, catch ERROR after dispatch",
				erro
			);
		}
	},

	/** DEACTIVATE JACKPOT CONFIG */
	async deactivateJBConfig({ commit, dispatch }, jackpot_config_id) {
		try {
			await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/jackpot_configs/" + jackpot_config_id,
				protectedPath: true,
			});
		} catch (erro) {
			console.error(
				" js delete JACKPOT config, catch ERROR after dispatch",
				erro
			);
		}
	},
};

const mutations = {
	setJBConfigsList(state, list) {
		state.JBConfigsList = list;
	},
	setFilteredJBConfigsList(state, list) {
		state.filteredJBConfigsList = list;
	},
	setFilterForJBConfigsList(state, filter) {
		state.filterForJBConfigsList = filter;
	},
	setPaginationForJBConfigsList(state, pagination) {
		state.paginationForJBConfigsList = pagination;
	},
	setJBConfigDetail(state, detail) {
		state.JBConfigDetail = detail;
	},
	setUpdatedJBConfigDetail(state, uDetail) {
		state.updatedJBConfigDetail = uDetail;
	},
	setCreatedJBConfig(state, addConfigResponse) {
		state.createdJBConfig = addConfigResponse;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
