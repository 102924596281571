import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@/style/overrides.sass";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

const md_theme = {
	primary: "#36a9e3",
	dark_primary: "#0075ab",
	secondary: "#cccccc",
	background: "#f4f4f4",
	menu_background: "#dddddd",
	nav_background: "#272727",
	defaultbg: "#ffffff",
	"kajot-nav-text": "#f4f4f4",
	accent: "#36a9e3",
	error: "#e9392a",
	success: "#4CAF50",
	info: "#9C27B0",
	kajot_light1: "#47C1F1",
	warning: "#C4B454",
	kajot_dark_dark_gray: "#2D2D2B",
	"kajot-disabled": "#AEACAC",
	"kajot-highlight-row": "#F4F4F4",
	"kajot-text": "#121212",
	"kajot-anti-text": "#e0e0e0",
	"rtp-red": "#FF2400",
	"rtp-orange": "#F78702",
};

const md_theme_dark = {
	primary: "#36a9e3",
	dark_primary: "#0075ab",
	secondary: "#3a3a3a",
	background: "#121212",
	defaultbg: "#1e1e1e",
	menu_background: "#272727",
	nav_background: "#272727",
	"kajot-nav-text": "#f4f4f4",
	accent: "#36a9e3",
	error: "#F44336",
	warning: "#CC9900",
	info: "#607D8B",
	unique: "#E26A2B",
	success: "#4CAF50",
	"kajot-disabled": "#8A8A8A",
	"kajot-highlight-row": "#262626",
	"kajot-text": "#f4f4f4",
	"kajot-anti-text": "#1b1b1b",
	"rtp-red": "#FF2400",
	"rtp-orange": "#F78702",
};

const theme3 = {
	...md_theme_dark,
	primary: "#de7944",
	accent: "#de7944",
};

export default new Vuetify({
	icons: { iconfont: "md" },
	theme: {
		themes: {
			light: md_theme,
			dark: md_theme_dark,
			another: theme3,
		},
		options: { customProperties: true },
	},
});
