const state = {
	jackpotGroupDetail: {
		data: undefined,
		isLoading: true,
	},
	jackpotGroupSettings: {
		data: undefined,
		isLoading: false,
	},
	deletedJackpotGroup: {
		data: undefined,
		isLoading: false,
	},
	jackpotGroupJackpots: {
		data: undefined,
		isLoading: false,
	},
	filteredGroupJackpots: {
		data: undefined,
		isLoading: false,
	},
	jackpotGroupGames: {
		data: undefined,
		isLoading: false,
	},
	jackpotGroupCasinos: {
		data: undefined,
		isLoading: false,
	},
	groupsGroupConfiguration: {
		data: undefined,
		isLoading: false,
	},
	updateGroupsGroupConfiguration: {
		data: undefined,
		isLoading: false,
	},
	deleteGroupsGroupConfiguration: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	jackpotGroupDetail: (state) => {
		return state["jackpotGroupDetail"];
	},
	jackpotGroupSettings: (state) => {
		return state["jackpotGroupSettings"];
	},
	getDeletedJackpotGroup: (state) => {
		return state["deletedJackpotGroup"];
	},
	jackpotGroupJackpots: (state) => {
		return state["jackpotGroupJackpots"];
	},
	getFilteredGroupJackpots: (state) => {
		return state["filteredGroupJackpots"];
	},
	jackpotGroupGames: (state) => {
		return state["jackpotGroupGames"];
	},
	jackpotGroupCasinos: (state) => {
		return state["jackpotGroupCasinos"];
	},
	getGroupsGroupConfiguration: (state) => {
		return state["groupsGroupConfiguration"];
	},
	getUpdatedGroupConfiguration: (state) => {
		return state["updateGroupsGroupConfiguration"];
	},
	getDeletedGroupConfiguration: (state) => {
		return state["deleteGroupsGroupConfiguration"];
	},
};

const actions = {
	/** GROUP DETAIL */
	async loadJackpotGroupDetail({ commit, dispatch }, groupId) {
		commit("setJackpotGroupDetailLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups/" + groupId,
				protectedPath: true,
			});
			commit("setJackpotGroupDetailData", res.data);
			commit("setJackpotGroupDetailLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			dispatch(
				"notification/setMessage",
				{ message: `Failed to load bonus group detail`, type: "error" },
				{ root: true }
			);
			commit("setJackpotGroupDetailData", undefined);
			commit("setJackpotGroupDetailLoading", false);
		}
	},
	/** UPDATE GROUP SETTING */
	async updateJackpotGroupSettings(
		{ commit, dispatch },
		{ groupId, bodyPayload = {} }
	) {
		//, queryParams = ""} ) {
		commit("setJackpotGroupSettingsLoading", true);
		try {
			const res = await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/groups/" + groupId,
				payload: bodyPayload,
				protectedPath: true,
			}); // params: queryParams , protectedPath: true})
			dispatch(
				"notification/setMessage",
				{ message: `Updated bonus group settings`, type: "success" },
				{ root: true }
			);
			commit("setJackpotGroupSettingsData", res.data);
			commit("setJackpotGroupSettingsLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			dispatch(
				"notification/setMessage",
				{ message: `Failed to update bonus group settings`, type: "error" },
				{ root: true }
			);

			commit("setJackpotGroupSettingsData", undefined);
			commit("setJackpotGroupSettingsLoading", false);
		}
	},
	/** INVALIDATE GROUP */
	async deleteJackpotGroup({ commit, dispatch }, groupId) {
		commit("setDeleteJackpotGroupLoading", true);
		try {
			const res = await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/groups/" + groupId,
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: `Deleted bonus group`, type: "success" },
				{ root: true }
			);

			commit("setDeletedJackpotGroupData", res.data);
			commit("setDeleteJackpotGroupLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			dispatch(
				"notification/setMessage",
				{ message: `Failed to delete bonus group`, type: "error" },
				{ root: true }
			);

			commit("setDeletedJackpotGroupData", undefined);
			commit("setDeleteJackpotGroupLoading", false);
		}
	},

	async reactivateJackpotGroup({ commit, dispatch }, groupId) {
		try {
			await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/groups/" + groupId,
				payload: { deleted: null },
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: `Reactivated bonus group`, type: "success" },
				{ root: true }
			);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			dispatch(
				"notification/setMessage",
				{ message: `Failed to reactivate bonus group`, type: "error" },
				{ root: true }
			);
		}
	},
	/** LIST JACKPOTS OF GROUP */
	async loadJackpotGroupJackpots(
		{ commit, dispatch },
		{ groupId, queryParams = {}, groupParams = "" }
	) {
		commit("setJackpotGroupJackpotsLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups/" + groupId + "/jackpots",
				params: queryParams,
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: `Failed to load bonus group list`, type: "error" },
				{ root: true }
			);
			commit("setJackpotGroupJackpotsData", res.data);
			commit("setJackpotGroupJackpotsLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			commit("setJackpotGroupJackpotsData", undefined);
			commit("setJackpotGroupJackpotsLoading", false);
		}
	},
	/** FILTERED LISTING JACKPOTS OF GROUP */
	async loadFilteredGroupJackpots(
		{ commit, dispatch },
		{ groupId, filterParams = {}, queryParams = "" }
	) {
		commit("setFilteredGroupJackpotsLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/groups/" + groupId + "/jackpots",
				payload: filterParams,
				query: queryParams,
				protectedPath: true,
			});
			commit("setFilteredGroupJackpotsData", res.data);
			commit("setFilteredGroupJackpotsLoading", false);
		} catch (error) {
			commit("setFilteredGroupJackpotsData", undefined);
			commit("setFilteredGroupJackpotsLoading", false);
		}
	},
	async loadJackpotGroupGames({ commit, dispatch }, jackpotGroupId) {
		commit("setJackpotGroupGamesLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups/" + jackpotGroupId + "/games",
				protectedPath: true,
			});
			commit("setJackpotGroupGamesData", res.data);
			commit("setJackpotGroupGamesLoading", false);
		} catch (error) {
			commit("setJackpotGroupGamesData", undefined);
			commit("setJackpotGroupGamesLoading", false);
		}
	},
	async loadJackpotGroupCasinos({ commit, dispatch }, jackpotGroupId) {
		commit("setJackpotGroupCasinosLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups/" + jackpotGroupId + "/casinos",
				protectedPath: true,
			});
			commit("setJackpotGroupCasinosData", res.data);
			commit("setJackpotGroupCasinosLoading", false);
		} catch (error) {
			commit("setJackpotGroupCasinosData", undefined);
			commit("setJackpotGroupCasinosLoading", false);
		}
	},

	/** SHOW CONFIGURATION OF GIVEN GROUP */
	async loadGroupsGroupConfiguration({ commit, dispatch }, groupId) {
		commit("setGroupsGroupConfigurationLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups/" + groupId + "/config",
				protectedPath: true,
			});
			commit("setGroupsGroupConfigurationData", res.data);
			commit("setGroupsGroupConfigurationLoading", false);
		} catch (error) {
			commit("setGroupsGroupConfigurationData", undefined);
			commit("setGroupsGroupConfigurationLoading", false);
		}
	},

	/** UPDATE CONFIGURATION OF GROUP */
	async updateGroupsGroupConfiguration(
		{ commit, dispatch },
		{ groupId, bodyPayload = {} }
	) {
		commit("setUpdateGroupsGroupConfigurationLoading", true);
		try {
			const res = await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/groups/" + groupId + "/config",
				payload: bodyPayload,
				protectedPath: true,
			});
			commit("setUpdateGroupsGroupConfigurationData", res.data);
			commit("setUpdateGroupsGroupConfigurationLoading", false);
		} catch (error) {
			commit("setUpdateGroupsGroupConfigurationData", undefined);
			commit("setUpdateGroupsGroupConfigurationLoading", false);
		}
	},

	/** MARK GROUP_CONFIG AS DELETED */
	async deleteGroupsGroupConfiguration({ commit, dispatch }, groupId) {
		commit("setDeleteGroupsGroupConfigurationLoading", true);
		try {
			const res = await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/groups/" + groupId + "/config",
				protectedPath: true,
			});
			commit("setDeleteGroupsGroupConfigurationData", res.data);
			commit("setDeleteGroupsGroupConfigurationLoading", false);
			dispatch(
				"notification/setMessage",
				{ message: `Deleted groups config `, type: "success" },
				{ root: true }
			);
		} catch (error) {
			commit("setDeleteGroupsGroupConfigurationData", undefined);
			commit("setDeleteGroupsGroupConfigurationLoading", false);
		}
	},

	groupDetailResetState({ commit }) {
		try {
			let data = {};
			let value = false;
			commit("setJackpotGroupDetailData", data);
			commit("setJackpotGroupDetailLoading", value);
			commit("setJackpotGroupGamesData", data);
			commit("setJackpotGroupGamesLoading", value);
			commit("setJackpotGroupCasinosData", data);
			commit("setJackpotGroupCasinosLoading", value);
		} catch (error) {
			console.error(" something went wrong during resetting state");
		}
	},
};

const mutations = {
	setJackpotGroupDetailData(state, data) {
		state.jackpotGroupDetail.data = data;
	},
	setJackpotGroupDetailLoading(state, value) {
		state.jackpotGroupDetail.isLoading = value;
	},
	setJackpotGroupSettingsData(state, data) {
		state.jackpotGroupSettings.data = data;
	},
	setJackpotGroupSettingsLoading(state, value) {
		state.jackpotGroupSettings.isLoading = value;
	},
	setDeletedJackpotGroupData(state, data) {
		state.deletedJackpotGroup.data = data;
	},
	setDeleteJackpotGroupLoading(state, value) {
		state.deletedJackpotGroup.isLoading = value;
	},
	setJackpotGroupJackpotsData(state, data) {
		state.jackpotGroupJackpots.data = data;
	},
	setJackpotGroupJackpotsLoading(state, value) {
		state.jackpotGroupJackpots.isLoading = value;
	},
	setFilteredGroupJackpotsData(state, data) {
		state.filteredGroupJackpots.data = data;
	},
	setFilteredGroupJackpotsLoading(state, value) {
		state.filteredGroupJackpots.isLoading = value;
	},
	setJackpotGroupGamesData(state, data) {
		state.jackpotGroupGames.data = data;
	},
	setJackpotGroupGamesLoading(state, value) {
		state.jackpotGroupGames.isLoading = value;
	},
	setJackpotGroupCasinosData(state, data) {
		state.jackpotGroupCasinos.data = data;
	},
	setJackpotGroupCasinosLoading(state, value) {
		state.jackpotGroupCasinos.isLoading = value;
	},
	setGroupsGroupConfigurationData(state, data) {
		state.groupsGroupConfiguration.data = data;
	},
	setGroupsGroupConfigurationLoading(state, value) {
		state.groupsGroupConfiguration.isLoading = value;
	},
	setUpdateGroupsGroupConfigurationData(state, data) {
		state.updateGroupsGroupConfiguration.data = data;
	},
	setUpdateGroupsGroupConfigurationLoading(state, value) {
		state.updateGroupsGroupConfiguration.isLoading = value;
	},
	setDeleteGroupsGroupConfigurationData(state, data) {
		state.deleteGroupsGroupConfiguration.data = data;
	},
	setDeleteGroupsGroupConfigurationLoading(state, value) {
		state.deleteGroupsGroupConfiguration.isLoading = value;
	},
	groupDetail_RESET_STATE(state, { data, value }) {
		state.jackpotGroupDetail.data = data;
		state.jackpotGroupDetail.isLoading = value;
		state.jackpotGroupGames.data = data;
		state.jackpotGroupGames.isLoading = value;
		state.jackpotGroupCasinos.data = data;
		state.jackpotGroupCasinos.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
