export default {
	list(state, val) {
		state.list = val.data;
	},
	studios(state, val) {
		state.studios = val.data;
	},
	casinos(state, val) {
		state.casinos = val.data;
	},
	groups(state, val) {
		state.groups = val.data;
	},
	detail(state, val) {
		state.detail = val.data;
	},
};
