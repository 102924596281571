const state = {
	groupTheme: {},
	groupThemeSetting: {},
};

const getters = {
	getGroupThemeSetting: (state) => {
		return state["groupThemeSetting"];
	},
	getGroupTheme: (state) => {
		return state["groupTheme"];
	},
};

const actions = {
	async loadGroupThemeSetting(
		{ commit, dispatch },
		{ group_id, themeName = "" }
	) {
		try {
			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups/" + group_id + "/themes/" + themeName,
				protectedPath: true,
			})
				.then(
					async (respon) => {
						await commit("setGroupThemeSetting", respon);
					},
					(rejection) => {
						console.warn(
							" js LOAD group THEME setting \n rejection after dispatch ",
							rejection
						);
					}
				)
				.catch((erro) => {
					console.error(" js LOAD GROUP THEME SETTING ERROR ", erro);
					dispatch(
						"notification/setMessage",
						{ message: "Failed to load Group Theme Settings", type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" js LOAD GROUP THEME SETTING catch ERROR ", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load Group Theme Settings", type: "error" },
				{ root: true }
			);
		}
	},

	async setGroupTheme({ commit, dispatch }, { group_id, themeName = "" }) {
		try {
			await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/groups/" + group_id + "/themes/" + themeName,
				protectedPath: true,
			})
				.then(
					(respon) => {
						commit("setGroupTheme", respon);
					},
					(rejection) => {
						console.warn(
							" js set group theme, rejection after dispatch ",
							rejection
						);
					}
				)
				.catch((erro) => {
					console.error(" js LOAD GROUP THEME SETTING catch ", erro);
					dispatch(
						"notification/setMessage",
						{ message: "Failed to set Group Theme", type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" js LOAD GROUP THEME SETTING catch ERROR ", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to set Group Theme", type: "error" },
				{ root: true }
			);
		}
	},

	async removeGroupTheme(
		{ commit, dispatch },
		{ group_id, themeName = "", params = "" }
	) {
		try {
			await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/groups/" + group_id + "/themes/" + themeName,
				params: params,
				protectedPath: true,
			})
				.then(
					(respon) => {},
					(rejection) => {
						console.warn(
							" js REMOVE GROUP THEME rejection after dispatch ",
							rejection
						);
					}
				)
				.catch((erro) => {
					console.error(" js REMOVE GROUP THEME ERROR after dispatch ", erro);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" js REMOVE GROUP THEME, ERROR after dispatch ", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to remove Group Theme", type: "error" },
				{ root: true }
			);
		}
	},

	groupThemesResetState({ commit }) {
		commit("groupThemes_RESET_STATE");
	},
};

const mutations = {
	setGroupThemeSetting(state, list) {
		state.groupThemeSetting = list;
	},
	setGroupTheme(state, theme) {
		state.groupTheme = theme;
	},
	groupThemes_RESET_STATE(state) {
		state.groupThemeSetting = {};
		state.groupTheme = {};
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
