const state = {
	createJackpotGroup: {
		data: {
			created: undefined,
			updated: undefined,
			deleted: undefined,
			group_config_id: -10,
			id: -10,
			name: "",
			theme_id: -10,
		},
		isLoading: false,
	},
	filteredJokerGroupList: {
		data: [],
		isLoading: false,
	},

	groupListMetaDataPagination: {
		filter: {
			deleted: {
				eq: null,
			},
		},
		pagination: {
			order: "desc",
			order_by: "id",
			order_by_matched: "id",
			page: 1,
			per_page: 10,
			total_pages: 1,
			total_records: 0,
		},
	},

	jokerGroupListFilter: {
		payload: {
			// 'deleted': {
			//   'eq' : null
			// }
		},
		query: {
			page: 1,
			per_page: 10,
			order_by: "id",
			order: "desc",
		},
	},

	jokerGroupList: {
		data: {
			data: [],
			pagination: {
				limit: 0,
				page: 1,
				total_pages: 1,
				total_records: 0,
			},
		},
		isLoading: false,
	},

	jokerGroupListTableHeadersAdmin: [
		{ text: "id", value: "id", align: "center" },
		{ text: "Name", value: "name", align: "center" },
		{ text: "Config ID", value: "group_config_id", align: "center" },
		{ text: "Theme ID", value: "theme_id", align: "center" },
		{ text: "Deleted", value: "deleted", align: "center" },
		{ text: "Casinos", value: "casinos", align: "center", sortable: false },
	],

	jokerGroupListTableHeadersUser: [
		{ text: "id", value: "group_id", align: "center" },
		{ text: "Name", value: "name", align: "center" },
		{ text: "Config ID", value: "group_config_id", align: "center" },
		{ text: "Theme ID", value: "theme_id", align: "center" },
		{ text: "Deleted", value: "deleted", align: "center" },
	],

	jokerGroupListTableData: [],
};

const getters = {
	jokerGroupListFilter: (state) => {
		return state["jokerGroupListFilter"];
	},
	jokerGroupListTableHeader: (state, getters) => {
		if (getters.userIsAdmin) {
			return state["jokerGroupListTableHeadersAdmin"];
		}
		return state["jokerGroupListTableHeadersUser"];
	},
	jokerGroupListTableData: (state) => {
		return state["jokerGroupListTableData"];
	},

	jackpotGroupList: (state) => {
		return state["jokerGroupList"];
	},
	createdJackpotGroup: (state) => {
		return state["createJackpotGroup"];
	},
	getFilteredJackpotGroupList: (state) => {
		return state["filteredJokerGroupList"];
	},
	getGroupListMetaDataPagination: (state) => {
		return state["groupListMetaDataPagination"];
	},
};

const actions = {
	async setJokerGroupListFilterPage({ commit, dispatch }, page) {
		commit("setJokerGroupListFilterPage", page);
		await dispatch("loadJokerGroupsList");
	},

	async setJokerGroupListFilterPerPage({ commit, dispatch }, perPage) {
		commit("setJokerGroupListFilterPerPage", perPage);
		await dispatch("loadJokerGroupsList");
	},

	async setJokerGroupListFilterSorting(
		{ commit, getters, dispatch },
		sortingParams
	) {
		if (
			sortingParams.order_by !==
				getters["jokerGroupListFilter"].query.order_by ||
			sortingParams.order !== getters["jokerGroupListFilter"].query.order
		) {
			commit("setJokerGroupListFilterSorting", sortingParams);
			await dispatch("loadJokerGroupsList");
		}
	},

	async setJokerGroupListFilterPayload(
		{ commit, dispatch },
		payload = undefined
	) {
		commit("setJokerGroupListFilterPayload", payload);
	},

	async loadJokerGroupsList({ commit, dispatch, getters, rootGetters }) {
		if (rootGetters.userIsAdmin) {
			await dispatch("loadJokerGroupsListAdmins");
		} else {
			await dispatch("loadJokerGroupsListUser", {
				currentCasino: getters.currentCasino,
			});
		}
	},

	async loadJokerGroupsListAdmins({ commit, dispatch, getters, rootGetters }) {
		await dispatch("loadFilteredJackpotGroupList");
		let tmpTableDataAdmin = [];
		let tmpList = getters.getFilteredJackpotGroupList.data;
		for (let group of tmpList) {
			let tmpGroupLine = {};
			for (let header of getters.jokerGroupListTableHeader) {
				if (header.value == "casinos") {
					let tmpCasinos = [];
					await dispatch("loadJackpotGroupCasinos", group.id);
					if (rootGetters.jackpotGroupCasinos.data?.data?.[0]) {
						for (let casino of rootGetters.jackpotGroupCasinos.data.data) {
							tmpCasinos.push(casino.id);
						}
					}
					tmpGroupLine[header.value] = tmpCasinos.join(", "); //[...tmpCasinos]
				} else {
					tmpGroupLine[header.value] = group[header.value];
				}
			}
			tmpTableDataAdmin.push(tmpGroupLine);
		}
		commit("setFilteredJokerGroupListTable", tmpTableDataAdmin);
	},

	async loadJokerGroupsListUser(
		{ commit, dispatch, getters },
		{ currentCasino, bodyPayload = undefined, queryParams = undefined } = {}
	) {
		let tmpTableDataUser = [];
		if (currentCasino) {
			if (!bodyPayload) {
				bodyPayload = getters.jokerGroupListFilter.payload;
			}
			if (!queryParams) {
				queryParams = getters.jokerGroupListFilter.query;
			}

			await dispatch("loadGroupsOnCasinoPost", {
				casino_id: getters.currentCasino,
				bodyPayload: bodyPayload,
				queryParams: queryParams,
			});
			commit(
				"setGroupListMetaDataPagination",
				getters.getGroupsOnCasinoPost._meta_data.pagination
			);
			tmpTableDataUser = [...getters.getGroupsOnCasinoPost.data];
		}

		commit("setFilteredJokerGroupListTable", tmpTableDataUser);
	},

	async loadJackpotGroupList({ commit, dispatch }, queryParams) {
		commit("setJokerGroupListLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/groups",
				params: queryParams,
				protectedPath: true,
			});
			commit("setJokerGroupListData", res.data);
			commit("setJokerGroupListLoading", false);
		} catch (error) {
			commit("setJokerGroupListData", undefined);
			commit("setJokerGroupListLoading", false);
		}
	},

	async loadFilteredJackpotGroupList(
		{ commit, dispatch, getters },
		{ bodyPayload = undefined, queryParams = undefined } = {}
	) {
		commit("setFilteredJokerGroupListLoading", true);
		if (!bodyPayload) {
			bodyPayload = getters.jokerGroupListFilter.payload;
		}
		if (!queryParams) {
			queryParams = getters.jokerGroupListFilter.query;
		}
		try {
			const res = await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/groups",
				payload: bodyPayload,
				query: queryParams,
				protectedPath: true,
			});
			commit("setFilteredJokerGroupListData", res.data.data);
			commit("setGroupListMetaDataPagination", res.data._meta_data.pagination);
			commit("setFilteredJokerGroupListLoading", false);
		} catch (error) {
			commit("setFilteredJokerGroupListData", undefined);
			commit("setFilteredJokerGroupListLoading", false);
		}
	},

	async createJackpotGroup(
		{ commit, dispatch },
		{ groupName, groupConfigId, groupThemeName = undefined }
	) {
		commit("setCreateJackpotGroupLoading", true);
		try {
			await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/groups",
				payload: {
					name: groupName,
					group_config_id: groupConfigId,
					theme: groupThemeName,
				},
				protectedPath: true,
			}).then(
				(response) => {
					commit("setCreateJackpotGroupData", response.data);
				},
				(rejected) => {
					console.warn("CREATE JACKPOT GROUP REJECTED ", rejected);
				}
			);
			commit("setCreateJackpotGroupLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(
				"%c JACKPOT GROUP LIST, \n actions,\n create jackpot group : ",
				"background:pink, color:black",
				error
			);
			commit("setCreateJackpotGroupLoading", false);
		}
	},
};

const mutations = {
	setJokerGroupListFilterPage(state, page) {
		state.jokerGroupListFilter.query.page = page;
	},
	setJokerGroupListFilterPerPage(state, perPage) {
		state.jokerGroupListFilter.query.per_page = perPage;
	},
	setJokerGroupListFilterSorting(state, sortingParams) {
		state.jokerGroupListFilter.query.order_by = sortingParams.order_by;
		state.jokerGroupListFilter.query.order = sortingParams.order;
	},
	setJokerGroupListFilterPayload(state, payload) {
		state.jokerGroupListFilter.payload = payload;
	},

	setFilteredJokerGroupListTable(state, table) {
		state.jokerGroupListTableData = table;
	},

	setJokerGroupListData(state, data) {
		state.jokerGroupList.data = data;
	},
	setJokerGroupListLoading(state, value) {
		state.jokerGroupList.isLoading = value;
	},
	setCreateJackpotGroupData(state, data) {
		state.createJackpotGroup.data = data;
	},
	setCreateJackpotGroupLoading(state, value) {
		state.createJackpotGroup.isLoading = value;
	},
	setFilteredJokerGroupListData(state, data) {
		state.filteredJokerGroupList.data = data;
	},
	setGroupListMetaDataPagination(state, data) {
		state.groupListMetaDataPagination = data;
	},
	setFilteredJokerGroupListLoading(state, value) {
		state.filteredJokerGroupList.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
