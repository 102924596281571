const state = {
	listGroupConfigs: {
		data: undefined,
		isLoading: false,
	},
	filterGroupConfigs: {
		data: undefined,
		isLoading: false,
	},
	createdGroupConfig: {
		data: undefined,
		isLoading: false,
	},
	specificGroupConfig: {
		data: undefined,
		isLoading: false,
	},
	updateSpecificGroupConfig: {
		data: undefined,
		isLoading: false,
	},
	removeSpecificGroupConfig: {
		data: undefined,
		isLoading: false,
	},
	listGroupsWithConfig: {
		data: undefined,
		isLoading: false,
	},
	filteredGroupsListWithConfig: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	getListOfGroupConfigs: (state) => {
		return state["listGroupConfigs"];
	},
	getFilteredGroupConfigs: (state) => {
		return state["filterGroupConfigs"];
	},
	getCreatedGroupConfig: (state) => {
		return state["createdGroupConfig"];
	},
	getSpecificGroupConfig: (state) => {
		return state["specificGroupConfig"];
	},
	getUpdatedGroupConfig: (state) => {
		return state["updateSpecificGroupConfig"];
	},
	getRemovedGroupConfig: (state) => {
		return state["removeSpecificGroupConfig"];
	},
	getListGroupsWithConfig: (state) => {
		return state["listGroupsWithConfig"];
	},
	getFilteredGroupsWithConfig: (state) => {
		return state["filteredGroupsListWithConfig"];
	},
};

const actions = {
	/** LOAD LIST OF ALL GROUP CONFIGS */
	async loadGroupConfigs({ commit, dispatch }, queryParams) {
		commit("setListGroupConfigsLoading", true);
		try {
			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/group_configs",
				params: queryParams,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setListGroupConfigsData", resolved);
						commit("setListGroupConfigsLoading", false);
					},
					(rejected) => {
						console.warn(" LOAD ALL GROUP CONFIGS , REJECTED ", rejected);
						commit("setListGroupConfigsLoading", false);
					}
				)
				.catch((err) => {
					console.error(" LOADING ALL GROUP CONFIGS ERROR ", err);
				});
		} catch (error) {
			console.error(" LOADING ALL GROUP CONFIGS THROWING AN ERROR ", error);
			commit("setListGroupConfigsLoading", false);
		}
	},

	/** FILTER LIST OF ALL GROUP CONFIGS */
	async filterGroupConfigs(
		{ commit, dispatch },
		{ bodyPayload, queryParams = {} }
	) {
		commit("setFilterGroupConfigsLoading", true);
		try {
			await dispatch("apiCall/loganPut", {
				path: "/api/v1/jackpots/group_configs",
				payload: bodyPayload,
				query: queryParams,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setFilterGroupConfigsData", resolved);
						commit("setFilterGroupConfigsLoading", false);
					},
					(rejected) => {
						console.warn(" FILTER ALL GROUP CONFIGS , REJECTED ", rejected);
						commit("setFilterGroupConfigsLoading", false);
					}
				)
				.catch((err) => {
					console.error(" FILTER ALL GROUP CONFIGS ERROR ", err);
				});
		} catch (error) {
			console.error(" FILTER ALL GROUP CONFIGS THROWING AN ERROR ", error);
			commit("setFilterGroupConfigsLoading", false);
		}
	},

	/** DEFINE NEW GROUP CONFIG */
	async createGroupConfig({ commit, dispatch }, bodyPayload) {
		commit("setCreatedGroupConfigLoading", true);
		try {
			await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/group_configs",
				payload: bodyPayload,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setCreatedGroupConfigData", resolved);
						commit("setCreatedGroupConfigLoading", false);
					},
					(rejected) => {
						console.warn(" CREATE GROUP CONFIG, REJECTED ", rejected);
						commit("setCreatedGroupConfigLoading", false);
					}
				)
				.catch((err) => {
					console.error(" CREATE GROUP CONFIG ERROR ", err);
				});
		} catch (error) {
			console.error(" CREATE GROUP CONFIG THROWING AN ERROR ", error);
			commit("setCreatedGroupConfigLoading", false);
		}
	},

	/** SHOW GROUP CONFIGURATION */
	async loadSpecificGroupConfig({ commit, dispatch }, groupConfigId) {
		commit("setSpecificGroupConfigLoading", true);
		try {
			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/group_configs/" + groupConfigId,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setSpecificGroupConfigData", resolved);
						commit("setSpecificGroupConfigLoading", false);
					},
					(rejected) => {
						console.warn(" SHOW SPECIFIC GROUP CONFIGS, REJECTED ", rejected);
						commit("setSpecificGroupConfigLoading", false);
					}
				)
				.catch((err) => {
					console.error(" SHOW SPECIFIC GROUP CONFIG ERROR ", err);
					dispatch(
						"notification/setMessage",
						{ message: `Failed to load group config`, type: "warning" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" SHOW SPECIFIC GROUP CONFIG THROWING AN ERROR ", error);
			dispatch(
				"notification/setMessage",
				{ message: `Failed to load group config`, type: "warning" },
				{ root: true }
			);
			commit("setSpecificGroupConfigLoading", false);
		}
	},

	/** UPDATE GROUP CONFIG */
	async updateSpecificGroupConfig(
		{ commit, dispatch },
		{ groupConfigId, bodyPayload }
	) {
		commit("setUpdateSpecificGroupConfigLoading", true);
		try {
			await dispatch("apiCall/loganPatch", {
				path: "/api/v1/jackpots/group_configs/" + groupConfigId,
				payload: bodyPayload,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setUpdateSpecificGroupConfigData", resolved);
						commit("setUpdateSpecificGroupConfigLoading", false);
						dispatch(
							"notification/setMessage",
							{ message: `Updated group settings`, type: "success" },
							{ root: true }
						);
					},
					(rejected) => {
						console.warn(" UPDATE GROUP CONFIG, REJECTED ", rejected);
						commit("setUpdateSpecificGroupConfigLoading", false);
						dispatch(
							"notification/setMessage",
							{ message: `Failed to update group settings`, type: "error" },
							{ root: true }
						);
					}
				)
				.catch((err) => {
					console.error(" UPDATE GROUP CONFIG ERROR ", err);
					dispatch(
						"notification/setMessage",
						{ message: `Failed to update group settings`, type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" UPDATE GROUP CONFIG THROWING AN ERROR ", error);
			commit("setUpdateSpecificGroupConfigLoading", false);
		}
	},

	/** REMOVE and unlink CONFIG from GROUP */
	async removeSpecificGroupConfig({ commit, dispatch }, groupConfigId) {
		commit("setRemoveSpecificGroupConfigLoading", true);
		try {
			await dispatch("apiCall/loganDelete", {
				path: "/api/v1/jackpots/group_configs/" + groupConfigId,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setRemoveSpecificGroupConfigData", resolved);
						commit("setRemoveSpecificGroupConfigLoading", false);
						dispatch(
							"notification/setMessage",
							{ message: `Removed group config`, type: "success" },
							{ root: true }
						);
					},
					(rejected) => {
						console.warn(" REMOVE GROUP CONFIG, REJECTED ", rejected);
						commit("setRemoveSpecificGroupConfigLoading", false);
					}
				)
				.catch((err) => {
					console.error(" REMOVE GROUP CONFIG ERROR ", err);
					dispatch(
						"notification/setMessage",
						{ message: `Failed to remove group config`, type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" REMOVE GROUP CONFIG THROWING AN ERROR ", error);
			commit("setRemoveSpecificGroupConfigLoading", false);
			dispatch(
				"notification/setMessage",
				{ message: `Failed to remove group config`, type: "error" },
				{ root: true }
			);
		}
	},

	/** LIST GROUPS ON CONFIG */
	async loadGroupsListWithConfig(
		{ commit, dispatch },
		{ groupConfigId, queryParams = {} }
	) {
		commit("setListGroupsWithConfigLoading", true);
		try {
			await dispatch("apiCall/loganGet", {
				path: "/api/v1/jackpots/group_configs/" + groupConfigId + "/groups",
				params: queryParams,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setListGroupsWithConfigData", resolved);
						commit("setListGroupsWithConfigLoading", false);
					},
					(rejected) => {
						console.warn(" LIST GROUPS WITH CONFIG, REJECTED ", rejected);
						commit("setListGroupsWithConfigLoading", false);
					}
				)
				.catch((err) => {
					console.error(" LIST GROUPS WITH CONFIG ERROR ", err);
					dispatch(
						"notification/setMessage",
						{ message: `Failed to load group config`, type: "error" },
						{ root: true }
					);
				});
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(" LIST GROUPS WITH CONFIG THROWING AN ERROR ", error);
			dispatch(
				"notification/setMessage",
				{ message: `Failed to load group config`, type: "error" },
				{ root: true }
			);

			commit("setListGroupsWithConfigLoading", false);
		}
	},

	/** FILTERED LIST GROUPS USING THE CONFIG */
	async loadFilteredGroupsListWithConfig(
		{ commit, dispatch },
		{ groupConfigId, bodyPayload = {}, queryParams = {} }
	) {
		commit("setFilteredGroupsListWithConfigLoading", true);
		try {
			await dispatch("apiCall/loganPost", {
				path: "/api/v1/jackpots/group_configs/" + groupConfigId + "/groups",
				payload: bodyPayload,
				query: queryParams,
				protectedPath: true,
			})
				.then(
					(resolved) => {
						commit("setFilteredGroupsListWithConfigData", resolved);
						commit("setFilteredGroupsListWithConfigLoading", false);
					},
					(rejected) => {
						console.warn(
							" FILTERED LIST GROUP WITH CONFIG, REJECTED ",
							rejected
						);
						commit("setFilteredGroupsListWithConfigLoading", false);
					}
				)
				.catch((err) => {
					console.error(" FILTERED LIST GROUP WITH CONFIG, ERROR ", err);
				});
		} catch (error) {
			console.error(
				" FILTERED LIST GROUP WITH CONFIG THROWING AN ERROR ",
				error
			);
			commit("setFilteredGroupsListWithConfigLoading", false);
		}
	},
};

const mutations = {
	setListGroupConfigsData(state, data) {
		state.listGroupConfigs.data = data;
	},
	setListGroupConfigsLoading(state, value) {
		state.listGroupConfigs.isLoading = value;
	},
	setFilterGroupConfigsData(state, data) {
		state.filterGroupConfigs.data = data;
	},
	setFilterGroupConfigsLoading(state, value) {
		state.filterGroupConfigs.isLoading = value;
	},
	setCreatedGroupConfigData(state, data) {
		state.createdGroupConfig.data = data;
	},
	setCreatedGroupConfigLoading(state, value) {
		state.createdGroupConfig.isLoading = value;
	},
	setSpecificGroupConfigData(state, data) {
		state.specificGroupConfig.data = data;
	},
	setSpecificGroupConfigLoading(state, value) {
		state.specificGroupConfig.isLoading = value;
	},
	setUpdateSpecificGroupConfigData(state, data) {
		state.updateSpecificGroupConfig.data = data;
	},
	setUpdateSpecificGroupConfigLoading(state, value) {
		state.updateSpecificGroupConfig.isLoading = value;
	},
	setRemoveSpecificGroupConfigData(state, data) {
		state.removeSpecificGroupConfig.data = data;
	},
	setRemoveSpecificGroupConfigLoading(state, value) {
		state.removeSpecificGroupConfig.isLoading = value;
	},
	setListGroupsWithConfigData(state, data) {
		state.listGroupsWithConfig.data = data;
	},
	setListGroupsWithConfigLoading(state, value) {
		state.listGroupsWithConfig.isLoading = value;
	},
	setFilteredGroupsListWithConfigData(state, data) {
		state.filteredGroupsListWithConfig.data = data;
	},
	setFilteredGroupsListWithConfigLoading(state, value) {
		state.filteredGroupsListWithConfig.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
