import { actions } from "../../common";
const baseUrl = "/api/v1/jackpots/levels";
export default {
	...actions(baseUrl),
	async delete({ dispatch }, level_name) {
		try {
			const res = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}/${level_name}`,
					payload: {
						deleted: new Date().toISOString(),
					},
					protectedPath: true,
				},
				{ root: true }
			);

			// if (convertClass !== null) {
			// 	return convertClass.fromDTO(res.data);
			// }
			dispatch(
				"notification/setMessage",
				{ message: `Deleted ${baseUrl.split("/").pop()}`, type: "success" },
				{ root: true }
			);

			return res.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while deleting item at ${baseUrl}/${level_name}`,
				e
			);
			dispatch(
				"notification/setMessage",
				{
					message: `Failed to delete ${baseUrl.split("/").pop()}`,
					type: "error",
				},
				{ root: true }
			);

			return null;
		}
	},
	async restore({ dispatch }, level_name) {
		try {
			const res = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}/${level_name}`,
					payload: {
						deleted: null,
					},
					protectedPath: true,
				},
				{ root: true }
			);

			// if (convertClass !== null) {
			// 	return convertClass.fromDTO(res.data);
			// }
			dispatch(
				"notification/setMessage",
				{ message: `Restored ${baseUrl.split("/").pop()}`, type: "success" },
				{ root: true }
			);

			return res.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while restoring item at ${baseUrl}/${level_name}`,
				e
			);
			dispatch(
				"notification/setMessage",
				{
					message: `Failed to delete ${baseUrl.split("/").pop()}`,
					type: "error",
				},
				{ root: true }
			);

			return null;
		}
	},
};
