export default {
	async loadList({ commit, dispatch }) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/meta/categories",
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data;

			commit("list", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list of categories`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Error ocurred while fetching list of categories`,
					type: "error",
				},
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async create({ commit, dispatch }, category) {
		try {
			await dispatch(
				"apiCall/loganPut",
				{
					path: `/api/v1/meta/categories`,
					payload: { name: category},
					protectedPath: true,
				},
				{ root: true }
			);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list of categories`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Error ocurred while fetching list of categories`,
					type: "error",
				},
				{ root: true }
			);
		}
	},
	async change({ commit, dispatch }, { category, removed }) {
		try {
			await dispatch(
				"apiCall/loganDelete",
				{
					path: `/api/v1/meta/categories/${category}`,
					params: { removed: removed },
					protectedPath: true,
				},
				{ root: true }
			);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list of categories`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Error ocurred while fetching list of categories`,
					type: "error",
				},
				{ root: true }
			);
		}
	},
	async changeCasinos({ commit, dispatch }, { category, payload }) {
		try {
			let res = [];
			if (payload.added?.casinos?.length > 0) {
				res.push(
					dispatch(
						"apiCall/loganPost",
						{
							path: `/api/v1/meta/categories/${category}/add_casinos`,
							payload: payload.added,
							protectedPath: true,
						},
						{ root: true }
					)
				);
			}
			if (payload.removed?.casinos?.length > 0) {
				res.push(
					dispatch(
						"apiCall/loganPost",
						{
							path: `/api/v1/meta/categories/${category}/remove_casinos`,
							payload: payload.removed,
							protectedPath: true,
						},
						{ root: true }
					)
				);
			}
			await Promise.all(res);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list of categories`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Error ocurred while fetching list of categories`,
					type: "error",
				},
				{ root: true }
			);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async loadDetail({ commit, dispatch }, category) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/meta/casinos",
					params: { category: category },
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.casinos;

			commit("detail", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list of categories`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Error ocurred while fetching list of categories`,
					type: "error",
				},
				{ root: true }
			);
			commit("detail", []);
		}
	},
};
