import { mutations } from "../../common";
import _ from "lodash";
import { arrayify, clone } from "../../../../constants/helpers";

export default {
	...mutations,
	data(state, payload) {
		state.data = payload;
	},
	dataPartial(state, payload) {
		const tabKey = payload.tabKey;
		if (payload.clear) {
			state.data[tabKey] = {};
			state.key = ((state.key + 1) % 4) + 1;
		}
		if (!state.data[tabKey]) {
			state.data[tabKey] = payload.data;
		} else {
			const temp = _.merge(state.data[tabKey], payload.data);
			state.data[tabKey].aggregated = sumBy(temp, payload.aggregateBy);
			state.data = clone(state.data);
		}
		state.data[tabKey].progress = payload.progress;
	},
	failedPartial(state, payload) {
		const tabKey = payload.tabKey;
		if (
			!state.data[tabKey] ||
			!state.data[tabKey].failed ||
			state.data[tabKey].failed.length == 0
		) {
			state.data[tabKey].failed = payload.failed;
		} else {
			const failedNow = payload.casinos.filter((el) =>
				payload.failed.includes(el)
			);
			state.data[tabKey].failed = _.unionBy(
				failedNow,
				state.data[tabKey].failed,
				_.identity
			);
		}
		state.key = ((state.key + 1) % 4) + 1;
	},
	worker(state, payload) {
		state[`${payload.type}Worker`]?.terminate();
		state[`${payload.type}Worker`] = payload.worker;
	},
};

function sumBy(data, aggregator) {
	const aggregated = {
		classic: [],
		special: [],
	};

	for (const casino in data.per_casino) {
		const casinoData = data.per_casino[casino];

		if (casinoData?.classic) {
			for (const game of arrayify(casinoData.classic)) {
				const existingGame = aggregated.classic.find(
					(item) => item[aggregator] === game[aggregator]
				);

				if (existingGame) {
					existingGame.sum_bet += game.sum_bet;
					existingGame.sum_win += game.sum_win;
					existingGame.rounds_count += game.rounds_count;
					existingGame.netto += game.netto;
				} else {
					aggregated.classic.push({ ...game });
				}
			}
		}

		if (casinoData?.special) {
			for (const game of arrayify(casinoData.special)) {
				const existingGame = aggregated.special.find(
					(item) =>
						item[aggregator] === game[aggregator] && item.flag === game.flag
				);

				if (existingGame) {
					existingGame.sum_bet += game.sum_bet;
					existingGame.sum_win += game.sum_win;
					existingGame.rounds_count += game.rounds_count;
					existingGame.netto += game.netto;
				} else {
					aggregated.special.push({ ...game });
				}
			}
		}
	}

	aggregated.classic.forEach((game) => {
		game.avg_bet = game.rounds_count > 0 ? game.sum_bet / game.rounds_count : 0;
	});

	aggregated.special.forEach((game) => {
		game.avg_bet = game.rounds_count > 0 ? game.sum_bet / game.rounds_count : 0;
	});

	return aggregated;
}
