const state = {
	userList: {
		data: [],
		metadata: {
			filter: {},
			pagination: {
				order: "asc",
				order_by: "first_name",
				page: 1,
				per_page: 10,
				total_pages: 0,
				total_records: 0,
			},
		},
		isLoading: false,
	},
	userDetail: {
		data: undefined,
		isLoading: false,
	},
	userPersonalEditLoading: undefined,
	userPasswordEditLoading: undefined,
	userCasinoEditLoading: undefined,
	userSectionEditLoading: undefined,
	createUserLoading: undefined,
};

const getters = {
	userList: (state) => {
		return state["userList"];
	},
	userDetail: (state) => {
		return state["userDetail"];
	},
	filter(state) {
		return state.userList.metadata.filter;
	},
	pagination(state) {
		return state.userList.metadata.pagination;
	},
};

const actions = {
	async getUserList(
		{ commit, dispatch, getters },
		{ payload } = { payload: {} }
	) {
		commit("setUserListLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path: "/api/v1/manage/users/list",
				payload: {
					order_by: payload.order_by ?? getters.pagination.order_by,
					order_as: payload.order ?? getters.pagination.order,
					pagination: {
						limit: payload.per_page ?? getters.pagination.per_page,
						page: payload.page ?? getters.pagination.page,
					},
				},
				protectedPath: true,
			});
			commit("setUserListData", res.data);
			commit("setUserListLoading", false);
			return res.data.pagination;
		} catch (error) {
			if (error?.message === "canceled") throw error;
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load users list", type: "error" },
				{ root: true }
			);
			commit("setUserListLoading", false);
		}
	},

	async getUserDetail({ commit, dispatch }, user_id) {
		commit("setUserDetailLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path: "/api/v1/manage/users/detail/" + user_id,
				protectedPath: true,
			});
			commit("setUserDetailData", res.data);
			commit("setUserDetailLoading", false);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load user detail", type: "error" },
				{ root: true }
			);
			commit("setUserDetailData", undefined);
			commit("setUserDetailLoading", false);
		}
	},

	async createUser(
		{ commit, dispatch },
		{
			email,
			password,
			firstName,
			lastName,
			phoneNumber,
			allowedCasinos,
			allowedSections,
			isAdmin,
		}
	) {
		commit("setCreateUserLoading", true);
		try {
			let userCreationResponse = { status: -1, message: "" };
			await dispatch("apiCall/catPost", {
				path: "/api/v1/users/register",
				params: {
					email: email,
					password: password,
					first_name: firstName,
					last_name: lastName,
					phone_number: phoneNumber,
					allowed_casinos: allowedCasinos,
					allowed_sections: allowedSections,
					is_admin: isAdmin,
				},
				protectedPath: true,
			})
				.then(
					(res) => {
						userCreationResponse.status = res.status;
						userCreationResponse.message = res.data.message;
					},
					(rej) => {
						userCreationResponse.status = rej.response.status;
						userCreationResponse.message = rej.response.data.message;
						dispatch(
							"notification/setMessage",
							{
								message:
									"Error : " +
									rej.response.status +
									" " +
									rej.response.data.message,
								type: "error",
							},
							{ root: true }
						);
						console.warn(
							"create user rejected >",
							rej.response.data.message,
							"\nstatus >",
							rej.response.status,
							"\nwhole obj >",
							rej.response,
							"\n userCreationResponse>",
							userCreationResponse
						);
					}
				)
				.catch((erri) => {
					console.error("error happened > ", erri);
				});
			commit("setCreateUserLoading", false);
			dispatch(
				"notification/setMessage",
				{ message: "Created user", type: "success" },
				{ root: true }
			);

			return userCreationResponse;
		} catch (error) {
			console.error(" create user error > \n", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create user", type: "error" },
				{ root: true }
			);
			commit("setCreateUserLoading", false);
		}
	},

	async updateUserPersonal(
		{ commit, dispatch },
		{ userId, firstName, lastName, phoneNumber }
	) {
		commit("setUserPersonalEditLoading", true);
		try {
			await dispatch("apiCall/catPost", {
				path: "/api/v1/users/" + userId + "/edit/personal",
				params: {
					first_name: firstName,
					last_name: lastName,
					phone_number: phoneNumber,
				},
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: "Updated user info", type: "success" },
				{ root: true }
			);
			commit("setUserPersonalEditLoading", false);
		} catch (error) {
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update user info", type: "error" },
				{ root: true }
			);
			commit("setUserPersonalEditLoading", false);
		}
	},

	async updateUserPassword({ commit, dispatch }, { userId, password }) {
		commit("setUserPasswordEditLoading", true);
		try {
			await dispatch("apiCall/catPost", {
				path: "/api/v1/users/" + userId + "/change_password",
				params: { password: password },
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: "Updated user password", type: "success" },
				{ root: true }
			);
			commit("setUserPasswordEditLoading", false);
		} catch (error) {
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update user password", type: "error" },
				{ root: true }
			);
			commit("setUserPasswordEditLoading", false);
		}
	},

	async updateUserCasinos({ commit, dispatch }, { userId, allowedCasinos }) {
		commit("setUserCasinoEditLoading", true);
		try {
			await dispatch("apiCall/catPost", {
				path: "/api/v1/users/" + userId + "/edit/casinos",
				params: { allowed_casinos: allowedCasinos },
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: "Updated user casinos list", type: "success" },
				{ root: true }
			);
			commit("setUserCasinoEditLoading", false);
		} catch (error) {
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update user casino list", type: "error" },
				{ root: true }
			);
			commit("setUserCasinoEditLoading", false);
		}
	},

	async updateUserSections({ commit, dispatch }, { userId, allowedSections }) {
		commit("setUserSectionEditLoading", true);
		try {
			await dispatch("apiCall/catPost", {
				path: "/api/v1/users/" + userId + "/edit/sections",
				params: allowedSections,
				protectedPath: true,
			});
			dispatch(
				"notification/setMessage",
				{ message: "Updated user permissions", type: "success" },
				{ root: true }
			);
			commit("setUserSectionEditLoading", false);
		} catch (error) {
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update user permissions", type: "error" },
				{ root: true }
			);
			commit("setUserSectionEditLoading", false);
		}
	},
};

const mutations = {
	setUserListData(state, data) {
		state.userList.data = data;
	},
	setUserListLoading(state, value) {
		state.userList.isLoading = value;
	},
	setUserDetailData(state, data) {
		state.userDetail.data = data;
	},
	setUserDetailLoading(state, value) {
		state.userDetail.isLoading = value;
	},
	setCreateUserLoading(state, value) {
		state.createUserLoading = value;
	},
	setUserPersonalEditLoading(state, value) {
		state.userPersonalEditLoading = value;
	},
	setUserPasswordEditLoading(state, value) {
		state.userPasswordEditLoading = value;
	},
	setUserCasinoEditLoading(state, value) {
		state.userCasinoEditLoading = value;
	},
	setUserSectionEditLoading(state, value) {
		state.userSectionEditLoading = value;
	},
	pagination(state, payload) {
		state.userList.metadata.pagination = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
