import { state as defaultState } from "../common";
export const state = () => ({
	...defaultState(),
	list: {
		data: [],
		metadata: {
			config: [
				{ rKey: "game_id", value: "games", type: "list" },
				{ rKey: "currency", value: "currency", type: "list" },
				{
					rKey: "start_time",
					value: ["date_from", "date_to"],
					type: "range",
				},
				{
					rKey: "sum_bet",
					value: ["min_bet", "max_bet"],
					type: "range",
				},
				{
					rKey: "sum_win",
					value: ["min_win", "max_win"],
					type: "range",
				},
				{
					rKey: "rounds_count",
					value: ["min_games", "max_games"],
					type: "range",
				},
				{
					rKey: "player_id",
					value: "player_id",
					type: "eq",
				},
				{
					rKey: "session_mongo_id",
					value: "session_mongo_id",
					type: "eq",
				},
				{
					rKey: "player_mongo_id",
					value: "player_mongo_id",
					type: "eq",
				},
				{
					value: "pagination",
					type: "pagination",
				},
			],
			filter: {},
			pagination: {
				order: "+",
				order_by: "id",
				page: 1,
				per_page: 10,
			},
			query: {
				previous: null,
				next: null,
				first: null,
				last: null,
			},
		},
	},
	roundsList: {
		data: [],
		loading: false,
		metadata: {
			filter: {},
			pagination: {
				order: "-",
				order_by: "start_time",
				page: 1,
				per_page: 10,
			},
			query: {
				previous: null,
				next: null,
				first: null,
				last: null,
			},
		},
	},
});
