import { fromMinorUnits } from "../helpers";
import { permControl } from "../helpers";
import { constants } from "../constants";
import "../../definitions/statistics";

export class StatisticsConverter {
	/**
	 *
	 * @param {StatsObject} params
	 * @returns
	 */
	static fromDTO(params) {
		const avg_bet = fromMinorUnits(params?.avg_bet, params.minor_units ?? 2);

		let sum_bet;
		let sum_win;
		let netto;

		sum_bet = params.sum_bet ?? params.avg_bet * params.rounds_count;
		sum_win = params.sum_win ?? params.rtp * sum_bet;
		netto = params.netto ?? sum_win - sum_bet;

		sum_bet = fromMinorUnits(sum_bet, params.minor_units ?? 2);
		sum_win = fromMinorUnits(sum_win, params.minor_units ?? 2);
		netto = fromMinorUnits(netto, params.minor_units ?? 2);

		return {
			...params,
			sum_bet,
			sum_win,
			netto,
			avg_bet,
		};
	}

	/**\
	 * @param {[StatsObject[] | StatsObject]} args
	 */
	static globalize(userPerm, ...args) {
		//CAREFUL
		const notAnal = true;
		const formedArgs = args.flatMap((el) => {
			if (Array.isArray(el)) {
				return el;
			} else {
				return [el];
			}
		});
		const sum_bet = formedArgs.reduce(
			(agg, curr) => (agg += curr?.sum_bet ?? 0),
			0
		);
		const sum_win = formedArgs.reduce(
			(agg, curr) => (agg += curr?.sum_win ?? 0),
			0
		);
		const netto = formedArgs.reduce(
			(agg, curr) => (agg += curr?.netto ?? 0),
			0
		);
		const rounds_count = formedArgs.reduce(
			(agg, curr) => (agg += curr?.rounds_count ?? 0),
			0
		);
		const avg_bet =
			formedArgs.reduce(
				(agg, curr) =>
					(agg += (curr?.avg_bet ?? 0) * (curr?.rounds_count ?? 0)),
				0
			) / rounds_count;
		const rtp = sum_bet > 0 ? sum_win / sum_bet : 0;
		return {
			...formedArgs[0],
			sum_bet: notAnal ? sum_bet : Math.random() * 200000,
			sum_win: notAnal ? sum_win : Math.random() * 200000,
			netto: notAnal ? netto : Math.random() * 200000,
			rtp,
			rounds_count,
			avg_bet,
		};
	}
}
