import FilterFactory from "../../../constants/converters/filterFactory";
import { Round } from "../../../constants/converters/round";
import { Session } from "../../../constants/converters/session";
import qs from "qs";

const baseUrl = "/api/v2/step/casinos";
export default {
	async next({ dispatch, getters }) {
		if (!getters.query.next) {
			await dispatch("load", {
				url: getters.query.last,
				sessionId: getters.query.sessionId,
			});
		} else if (getters.query.next) {
			await dispatch("load", {
				url: getters.query.next,
				sessionId: getters.query.sessionId,
				failBehavior: () => {
					dispatch("last");
				},
			});
		}
	},
	async previous({ dispatch, getters }) {
		if (!getters.query.previous) {
			await dispatch("load", {
				url: getters.query.first,
				sessionId: getters.query.sessionId,
			});
		} else if (getters.query.previous) {
			await dispatch("load", {
				url: getters.query.previous,
				sessionId: getters.query.sessionId,
				failBehavior: () => {
					dispatch("first");
				},
			});
		}
	},
	async first({ dispatch, getters }) {
		await dispatch("load", {
			url: getters.query.first,
			sessionId: getters.query.sessionId,
		});
	},
	async last({ dispatch, getters }) {
		await dispatch("load", {
			url: getters.query.last,
			sessionId: getters.query.sessionId,
		});
	},
	async loadList({ dispatch, getters }, { sessionId, url }) {
		const filter = { ...getters.filter, session_mongo_id: sessionId };
		const pagination = getters.pagination;

		if (url) {
			await dispatch("load", {
				url,
				isNaturalLoad: true,
			});
			return;
		}

		await dispatch("load", {
			url: FilterFactory.from(getters.getQsConfig, {
				...filter,
				...pagination,
			}).build(),
			isNaturalLoad: true,
			sessionId,
		});
	},
	async load(
		{ dispatch, commit, rootGetters },
		{
			url,
			failBehavior = false,
			isNaturalLoad = false,
			sessionId,
			commitMap = { list: "list", query: "query", first: "first" },
		}
	) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `/api/v2/step/casinos/${rootGetters.currentCasino}/sessions/${sessionId}/cycles?${url}`,
					protectedPath: true,
				},
				{ root: true }
			);

			if (
				response.data.data &&
				response.data.data.length === 0 &&
				!isNaturalLoad
			) {
				commit(commitMap.query, { ...response.data.pagination });
				return;
			}

			if (
				response.data.data &&
				response.data.data.length <
					qs.parse(url, { ignoreQueryPrefix: true }).limit &&
				failBehavior
			) {
				failBehavior();
				return;
			}

			let data = response.data.data.map((el) =>
				Round.fromCycleDTO(el, rootGetters.currentCasino)
			);

			commit(commitMap.list, data);
			commit(commitMap.query, {
				current: url,
				...response.data.pagination,
				sessionId,
			});

			return response.data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			if (e.response.status !== 400) {
				console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
				dispatch(
					"notification/setMessage",
					{ message: "Failed to load sessions list", type: "error" },
					{ root: true }
				);
			}
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
				page: 1,
			});
		}
	},
	async loadListByPlayer({ dispatch, getters }, { playerId, url }) {
		const filter = { ...getters.filter, player_mongo_id: playerId };
		const pagination = getters.pagination;

		if (url) {
			await dispatch("load", {
				url,
				isNaturalLoad: true,
			});
			return;
		}

		await dispatch("load", {
			url: FilterFactory.from(getters.getQsConfig, {
				...filter,
				...pagination,
			}).build(),
			isNaturalLoad: true,
		});
	},
	async loadDetail({ dispatch, commit, rootGetters }, sessionId) {
		const url = `/api/v2/casinos/${rootGetters.currentCasino}/sessions/${sessionId}`;
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: url,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = Session.detailFromDTO(response.data);
			commit("detail", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching detail from ${url}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load session detail", type: "error" },
				{ root: true }
			);
			commit("detail", {});
		}
	},
};
