import { Dashboard } from "../../../constants/converters/dashboard";
const baseUrl = "/api/v1/statistics/casinos";
export default {
	async loadList(
		{ commit, dispatch, getters, rootGetters },
		{ payload, showLoading }
	) {
		try {
			if (showLoading) {
				commit("listLoading", true);
			} else {
				commit("listLoading", false);
			}
			const response = await dispatch(
				"apiCall/loganPost",
				{
					protectedPath: true,
					path: `${baseUrl}/${rootGetters.currentCasino}/dashboard`,
					payload,
				},
				{ root: true }
			);
			let data = response.data;
			commit("list", Dashboard.fromDTO(data));
			commit("updated", new Date());
			commit("listLoading", false);
			return response.data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			commit("listLoading", false);
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Failed to load dashboard data`,
					type: "error",
				},
				{ root: true }
			);
		}
	},
	async loadTops({ commit, dispatch, getters, rootGetters }, payload) {
		try {
			const defs = ["player", "session"];
			commit("topsLoading", true);
			const req = defs.map((end) => {
				return dispatch(
					"apiCall/loganPost",
					{
						protectedPath: true,
						path: `${baseUrl}/${rootGetters.currentCasino}/tops/${end}`,
						payload,
					},
					{ root: true }
				);
			});
			const res = await Promise.all(req).then((res) => {
				return res.map((el) => el.data);
			});

			commit("topPlayers", res[0]);
			commit("topSessions", res[1]);
			commit("topsLoading", false);
			// return response.data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			commit("topsLoading", false);
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Failed to load dashboard TOPS data`,
					type: "error",
				},
				{ root: true }
			);
		}
	},
};
