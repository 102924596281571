/* eslint-disable no-mixed-spaces-and-tabs */
export class Round {
	static fromDTO(dto, currentCasino) {
		let _orientation, _hand;
		switch (dto.hand) {
			case 1:
				_hand = "Right";
				break;
			case 2:
				_hand = "Left";
				break;
			default:
				_hand = "Unknown";
		}
		_orientation = dto.orientation === 1 ? "Landscape" : "Portrait";
		return {
			...dto,
			_link: Round.linkHistory(
				dto.session_mongo_id,
				dto.round_id,
				currentCasino
			),
			_orientation,
			_hand,
			bet: dto.bet / 10 ** dto.minor_units,
			netto: dto.netto / 10 ** dto.minor_units,
			win: dto.win / 10 ** dto.minor_units,
		};
	}

	static fromCycleDTO(dto, currentCasino) {
		return {
			...dto,
			_link: Round.linkHistory(
				dto?.session_mongo_ids?.[0],
				dto.id,
				currentCasino,
				true
			),
			end_time: dto.start_time,
			bet: dto.bet / 10 ** dto.minor_units,
			netto: dto.netto / 10 ** dto.minor_units,
			win: dto.win / 10 ** dto.minor_units,
		};
	}

	static fromObject(dto, currentCasino) {
		const flatMap = Object.entries(dto).flatMap(([search_key, items]) =>
			items.map((item) => ({ ...item, search_key }))
		);
		const data = flatMap.map((el) => Round.fromDTO(el, currentCasino));
		return data;
	}
	static linkHistory(sessionId, roundId, currentCasino, step = false) {
		if (step) {
			return [
				`/frame?session=${sessionId}&round=${roundId}&casino=${currentCasino}&mode=history&step=true`,
				"_blank",
				"location=0,menubar=0,resizable=1,width=1000,height=740",
			];
		}
		return [
			`/frame?session=${sessionId}&round=${roundId}&casino=${currentCasino}&mode=history`,
			"_blank",
			"location=0,menubar=0,resizable=1,width=1000,height=740",
		];
	}
	static replayLink(item, currentCasino, device, extras = {}, lang) {
		let deviceX, deviceY;

		if (device === 2) {
			deviceX = 390;
			deviceY = 844;
		} else if (device === 3) {
			deviceX = 768;
			deviceY = 1024;
		} else {
			deviceX = 1080;
			deviceY = 1920;
		}

		const width = item.orientation === 2 ? deviceX : deviceY;
		const height = item.orientation === 2 ? deviceY : deviceX;
		const langString = lang ? "&lang=" + lang : "";
		return [
			`/frame?session=${item.session_mongo_id}&round=${
				item.round_id
			}&casino=${currentCasino}&game=${
				item.game_id
			}${langString}&mode=replay&device=${extras.device ?? device}`,
			"_blank",
			`location=0,menubar=0,resizable=1,width=${extras.width ?? width},height=${
				extras.height ?? height
			}`,
		];
	}
}
