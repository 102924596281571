/**
 * @typedef {Object} StatsObject
 * @property {number} sum_bet
 * @property {number} sum_win
 * @property {number} rounds_count
 * @property {number} netto
 * @property {string} date_from
 * @property {number} rtp
 * @property {number} avg_bet
 * @property {number} minor_units
 * @property {number} currency
 * @property {string} [date_to]
 * @property {string} [flag]
 */

/**
 * @typedef {Object} CasinoData
 * @property {StatsObject[]} classic
 * @property {StatsObject[]} special
 */

/**
 * @typedef {Object} AggregateData
 * @property {{total: StatsObject, classic: StatsObject, special: StatsObject, free_round: StatsObject, joker_bonus: StatsObject}} global
 * @property {StatsObject[]} total
 * @property {StatsObject[]} free_round
 * @property {StatsObject[]} joker_bonus
 */

/**
 * @typedef {Object<string, CasinoData & AggregateData>} CasinoDictionary
 */

/**
 * @typedef {Object} Stats {{
 * @property {{total: StatsObject, classic: StatsObject, special: StatsObject, free_round: StatsObject, joker_bonus: StatsObject}} global
 *  @property {{ classic: StatsObject[], special: StatsObject[], total: StatsObject[], free_round: StatsObject[], joker_bonus: StatsObject[] }} aggregated
 *  @property { CasinoDictionary} per_casino
 * }}
 */
