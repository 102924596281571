import { JackpotGroup } from "../../../../constants/converters/jackpotGroup";
import { actions } from "../../common";
const baseUrl = "/api/v1/jackpots/groups";
export default {
	...actions(baseUrl, JackpotGroup),
	async loadList(
		{ commit, dispatch, getters },
		{ query = {}, payload = {} } = { query: {}, payload: {} }
	) {
		try {
			const q = { ...getters.pagination, ...query };
			const p = { ...getters.filter, ...payload };
			const response = await dispatch(
				"apiCall/loganPut",
				{
					path: baseUrl,
					query: {
						order: q.order,
						order_by: q.order_by,
						per_page: q.per_page,
						page: q.page,
					},
					payload: p,
					protectedPath: true,
				},
				{ root: true }
			);

			let data;

			if (!Object.keys(response.data).includes("data")) {
				data = response.data;
			} else {
				data = response.data.data;
			}

			data = data.map((item) => JackpotGroup.fromDTO(item));

			commit("list", data);
			return response.data?._meta_data?.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Failed to load bonus ${baseUrl.split("/").pop()} list`,
					type: "error",
				},
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async loadGroupsWithConfig(
		{ commit, dispatch },
		{ groupConfigId, queryParams = {} }
	) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `/api/v1/jackpots/group_configs/${groupConfigId}/groups`,
					params: queryParams,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data;

			commit("list", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching groups by group config`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load groups", type: "error" },
				{ root: true }
			);

			commit("list", []);
		}
	},

	async loadFilteredGroups(
		{ commit, dispatch, getters },
		{ payload = undefined, query = undefined } = {}
	) {
		try {
			const res = await dispatch(
				"apiCall/loganPut",
				{
					path: "/api/v1/jackpots/groups",
					payload,
					query,
					protectedPath: true,
				},
				{ root: true }
			);
			if (!Object.keys(res.data).includes("data")) {
				commit("filteredGroups", res.data);
				return;
			}

			commit("filteredGroups", res.data.data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching Jackpot Groups List`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load Jackpot Groups List", type: "error" },
				{ root: true }
			);
			commit("filteredGroups", []);
		}
	},

	async loadGames({ commit, dispatch }, groupId) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}/${groupId}/games`,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data;

			commit("games", data);
		} catch (e) {
			console.error(
				`Error ocurred while fetching detail from ${baseUrl}/${groupId}/games`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load games", type: "error" },
				{ root: true }
			);

			commit("games", []);
		}
	},
	async loadCasinos({ commit, dispatch }, groupId) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}/${groupId}/casinos`,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data;

			commit("casinos", data);
			return data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching detail from ${baseUrl}/${groupId}/casinos`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load casinos", type: "error" },
				{ root: true }
			);

			commit("casinos", []);
			return [];
		}
	},

	async loadDetail({ commit, dispatch }, id) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}/${id}`,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data;

			data = JackpotGroup.fromDTO(data.data);

			commit("detail", data);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching detail from ${baseUrl}/${id}`,
				e
			);

			dispatch(
				"notification/setMessage",
				{
					message: `Failed to load ${baseUrl.split("/").pop()} detail`,
					type: "error",
				},
				{ root: true }
			);
			commit("detail", {});
		}
	},
	async create({ dispatch }, data) {
		try {
			let payload = data;
			// if (convertClass !== null) {
			payload = JackpotGroup.toDTO(payload);
			// }
			const res = await dispatch(
				"apiCall/loganPost",
				{
					path: baseUrl,
					payload,
					protectedPath: true,
				},
				{ root: true }
			);

			// if (convertClass !== null) {
			// 	return convertClass.fromDTO(res.data);
			// }
			dispatch(
				"notification/setMessage",
				{ message: "Created bonus group", type: "success" },
				{ root: true }
			);

			return res.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while creating item at ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create bonus group", type: "error" },
				{ root: true }
			);

			return null;
		}
	},
	async update({ dispatch }, { id, data }) {
		try {
			let payload = data;
			// if (convertClass !== null) {
			payload = JackpotGroup.toDTO(payload);
			// }
			const res = await dispatch(
				"apiCall/loganPatch",
				{
					path: `${baseUrl}/${id}`,
					payload,
					protectedPath: true,
				},
				{ root: true }
			);
			// if (convertClass !== null) {
			dispatch(
				"notification/setMessage",
				{ message: "Updated bonus group", type: "success" },
				{ root: true }
			);
			return JackpotGroup.fromDTO(res.data);
			// }
			// return res.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while editing item at ${baseUrl}/${id}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to update bonus group", type: "error" },
				{ root: true }
			);

			return null;
		}
	},
};
