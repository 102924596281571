import { state as defaultState } from "../common";
export const state = () => ({
	...defaultState(),
	list: {
		data: [],
		metadata: {
			filter: {},
			config: [
				{
					rKey: "player_mongo_id",
					value: "player_mongo_id",
					type: "eq",
				},
				{
					value: "pagination",
					type: "pagination",
				},
			],
			pagination: {
				order: "+",
				order_by: "round_id",
				page: 1,
				per_page: 10,
			},
			query: {
				previous: null,
				next: null,
				first: null,
				last: null,
			},
		},
	},
});
