export class JackpotTicket {
	static fromDTO(params) {
		return {
			...params,
			amount: params.amount / 100,
		};
	}
	static toDTO(params) {
		return {
			...params,
			amount: params.amount * 100,
		};
	}
}
