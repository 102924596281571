const state = {
	sessionSearch: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	sessionSearch: (state) => {
		return state["sessionSearch"];
	},
};

const actions = {
	async loadSessionSearch(
		{ commit, dispatch, rootGetters },
		{ searchKey, noErr }
	) {
		commit("setSessionSearchLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path:
					"/api/v1/casinos/" + rootGetters.currentCasino + "/sessions/search",
				payload: { search_key: searchKey },
				protectedPath: true,
			});
			commit("setSessionSearchData", res.data);
			commit("setSessionSearchLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			commit("setSessionSearchData", undefined);
			commit("setSessionSearchLoading", false);
			if (noErr) return;
			console.error(" js load session search error > \n", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to get specified session", type: "error" },
				{ root: true }
			);
		}
	},
};

const mutations = {
	setSessionSearchData(state, data) {
		state.sessionSearch.data = data;
	},
	setSessionSearchLoading(state, value) {
		state.sessionSearch.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
