const state = {
	sessionDetail: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	sessionDetail: (state) => {
		return state["sessionDetail"];
	},
};

const actions = {
	async loadSessionDetail({ commit, dispatch, rootGetters }, sessionId) {
		commit("setSessionDetailLoading", true);
		try {
			const res = await dispatch("apiCall/loganGet", {
				path:
					"/api/v1/casinos/" +
					rootGetters.currentCasino +
					"/sessions/" +
					sessionId,
				protectedPath: true,
			});
			commit("setSessionDetailData", res.data);
			commit("setSessionDetailLoading", false);
		} catch (error) {
			commit("setSessionDetailData", undefined);
			commit("setSessionDetailLoading", false);
		}
	},
};

const mutations = {
	setSessionDetailData(state, data) {
		state.sessionDetail.data = data;
	},
	setSessionDetailLoading(state, value) {
		state.sessionDetail.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
