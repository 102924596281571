import { actions } from "../../common";
import { permControl } from "../../../../constants/helpers";

const baseUrl = "/api/v1/jackpots/themes";
export default {
	...actions(baseUrl),
	async loadList(
		{ commit, dispatch, getters, rootGetters },
		{ query = {}, payload = {} } = { query: {}, payload: {} }
	) {
		try {
			const q = { ...getters.pagination, ...query };
			const p = { ...getters.filter, ...payload };
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: baseUrl,
					query: {
						order: q.order,
						order_by: q.order_by,
						per_page: q.per_page,
						page: q.page,
					},
					payload: p,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data;

			commit("list", data);
			return response.data._meta_data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load bonus themes list", type: "error" },
				{ root: true }
			);

			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
	async create({ dispatch }, data) {
		try {
			let payload = data;
			const res = await dispatch(
				"apiCall/loganPut",
				{
					path: `${baseUrl}/${payload.name}`,
					protectedPath: true,
				},
				{ root: true }
			);
			dispatch(
				"notification/setMessage",
				{ message: "Created jackpot theme", type: "success" },
				{ root: true }
			);
			return res.data;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while creating item at ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to create jackpot theme", type: "error" },
				{ root: true }
			);

			return null;
		}
	},
	async exists({ dispatch }, themeName) {
		try {
			const res = await dispatch(
				"apiCall/loganGet",
				{
					path: `${baseUrl}/${themeName}`,
					protectedPath: true,
				},
				{ root: true }
			);
			return res.status === 200;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while getting item at ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load " + themeName, type: "error" },
				{ root: true }
			);

			return null;
		}
	},
};
