// import { JackpotGroup } from ";
import { actions } from "../common";
// const baseUrl = "/api/v1/casinos/1/players/filter";
const baseUrl = "/api/v1/changelog/list";
export default {
	...actions(baseUrl),
	async loadList(
		{ commit, dispatch, getters },
		{ query = {}, payload = {} } = { query: {}, payload: {} }
	) {
		try {
			const p = { ...getters.filter, ...payload };
			commit("filter", p);
			const response = await dispatch(
				"apiCall/loganPost",
				{
					path: baseUrl,
					payload: {
						filter: p,
						// order_by: getters.pagination.order_by,
						// order_as: getters.pagination.order,
						pagination: {
							per_page: getters.pagination.per_page,
							page: getters.pagination.page,
						},
					},
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data.data;

			commit("list", data);
			const pagi = response.data.pagination;
			const pagiRes = {
				...pagi,
				total_records:
					pagi?.total_records ?? pagi?.total_pages * pagi?.per_page,
			};
			return pagiRes;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
			dispatch(
				"notification/setMessage",
				{
					message: `Failed to load bonus ${baseUrl.split("/").pop()} list`,
					type: "error",
				},
				{ root: true }
			);
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
			});
		}
	},
};
