export default {
	casinos(state, val) {
		state.casinos = val;
	},
	games(state, val) {
		state.games = val;
	},
	allowedGames(state, val) {
		state.allowedGames = val;
	},
	gameStudios(state, val) {
		state.gameStudios = val;
	},
};
