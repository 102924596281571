var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex justify-center flex-column align-center",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "v-progress-circular",
        {
          staticClass: "l1",
          attrs: { indeterminate: "", size: "125", color: "primary" },
        },
        [
          _c(
            "v-progress-circular",
            {
              staticClass: "l2",
              attrs: { indeterminate: "", size: "100", color: "primary" },
            },
            [
              _c("v-progress-circular", {
                staticClass: "l3",
                attrs: { indeterminate: "", size: "75", color: "primary" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }