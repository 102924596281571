import { objToZlib } from "../helpers";
import qs from "qs";

export default class FilterFactory {
	constructor(config = [], filters) {
		this.values = filters;
		this.filters = { filter: {}, pagination: { token: {} } };
		this.addFromConfig(config, this.filters.filter);
	}

	addFilter(key, value, where) {
		if (value) {
			try {
				where[key] =
					this.values[value]?.length === 1
						? { eq: this.values[value][0] }
						: { in_: this.values[value] };
			} catch (error) {
				console.log("FilterFactory error: ", error);
			}
		}
	}

	addRangeFilter(key, rangeValues, where) {
		const [min, max] = [
			this.values[rangeValues[0]],
			this.values[rangeValues[1]],
		];
		if (min || max) {
			where[key] = {
				...(min && { gt: min }),
				...(max && { lt: max }),
			};
		}
	}

	addOperator(key, value, operator, where) {
		if (value) {
			where[key] = { [operator]: this.values[value] };
		}
	}

	addLogicalBlock(type, children, where) {
		if (!Array.isArray(children) || children.length === 0) return;

		where[type] = where[type] || {};

		children.forEach((child) => {
			this.addFromConfig([child], where[type]);
		});
	}

	addPagination(pagination) {
		if (pagination) {
			const { limit, per_page, order_by, order, pointers } = this.values;

			if (pointers) {
				this.addFromConfig(pointers, this.filters.pagination.token);
				this.filters.pagination.token = objToZlib(
					this.filters.pagination.token
				);
			}

			this.filters.pagination.limit = limit ?? per_page;
			this.filters.order_by = [`${order}${order_by}`];
			this.filters.pagination.offset = pagination.offset;
		}
	}

	build() {
		const filter = objToZlib(this.filters.filter);
		return qs.stringify({ ...this.filters, filter });
	}

	addFromConfig(config, where) {
		config.forEach(({ type, rKey, value, children }) => {
			if (type === "list") {
				this.addFilter(rKey, value, where);
			} else if (type === "range") {
				this.addRangeFilter(rKey, value, where);
			} else if (type === "pagination") {
				this.addPagination(value);
			} else if (type === "or" || type === "and") {
				this.addLogicalBlock(type, children, where);
			} else {
				this.addOperator(rKey, value, type, where);
			}
		});
	}

	static from(config, filters) {
		return new FilterFactory(config, filters);
	}

	static buildFrom(config, filters) {
		return new FilterFactory(config, filters).build();
	}
}
