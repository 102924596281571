var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      staticClass: "primary pa-0!important",
      class: { iOS: _vm.isIOS },
      staticStyle: { "background-color": "var(--v-background-base)" },
      attrs: { id: "inspire" },
    },
    [
      _c(
        "transition",
        {
          attrs: {
            name: "fade-transition",
            duration: { enter: 0, leave: 150 },
            appear: "",
            mode: "out-in",
            "leave-absolute": "",
          },
        },
        [_c("router-view", { staticClass: "pa-0 ma-0" })],
        1
      ),
      _c("ErrorMessage"),
      _vm.location != "/frame"
        ? _c(
            "v-footer",
            {
              staticClass: "py-0 black",
              attrs: { app: "", bottom: "", absolute: "", padless: "" },
            },
            [
              _c("kajot-footer", {
                class: { loginFooter: _vm.$route.path === "/login" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }