export default {
	casinos(state) {
		return state.casinos;
	},
	games(state) {
		return state.games;
	},
	allowedGames(state) {
		return state.allowedGames;
	},
	gameStudios(state) {
		return state.gameStudios;
	},
};
