var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "kajot-footer" } }, [
      _c("div", { staticClass: "d-flex justify-center" }, [
        _c("div", { attrs: { id: "develop" } }, [
          _vm._v(" © 2022 Development 777 s. r. o. ( "),
          _c(
            "a",
            {
              staticClass: "pa-0 ma-0",
              attrs: { href: "https://www.kajotgames.com" },
            },
            [_vm._v(" Kajot Games ")]
          ),
          _vm._v(" ) "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }