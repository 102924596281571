import { actions } from "../../common";
export default {
	...actions("/api/v1/meta/casinos"),
	async loadList({ commit, dispatch, getters }) {
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: "/api/v1/meta/casinos",
					protectedPath: true,
				},
				{ root: true }
			);
			let data = response.data;

			commit("list", data);
			// commit("pagination", response.data._meta_data.pagination);
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(
				`Error ocurred while fetching list from ${"/api/v1/meta/casinos"}`,
				e
			);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to load casinos list", type: "error" },
				{ root: true }
			);
			commit("list", []);
			// commit("pagination", {
			// 	total_pages: 0,
			// 	total_records: 0,
			// });
		}
	},
};
