export default {
	list(state) {
		return state.list.data;
	},
	filter(state) {
		return state.list.metadata.filter;
	},
	pagination(state) {
		return state.list.metadata.pagination;
	},
	detail(state) {
		return state.detail;
	},
};
