const state = {
	playerSearch: {
		data: undefined,
		isLoading: false,
	},
};

const getters = {
	playerSearch: (state) => {
		return state["playerSearch"];
	},
};

const actions = {
	async loadPlayerSearch(
		{ commit, dispatch, rootGetters },
		{ searchKey, noErr }
	) {
		commit("setPlayerSearchLoading", true);
		try {
			const res = await dispatch("apiCall/loganPost", {
				path:
					"/api/v1/casinos/" + rootGetters.currentCasino + "/players/search",
				payload: { search_key: searchKey },
				protectedPath: true,
			});
			commit("setPlayerSearchData", res.data);
			commit("setPlayerSearchLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			console.error(
				" PLAYER SEARCH. JS \n LOAD PLAYER SEARCH \n ERROR >",
				error
			);
			commit("setPlayerSearchData", undefined);
			commit("setPlayerSearchLoading", false);
			if (noErr) return;
			dispatch(
				"notification/setMessage",
				{ message: "Failed to get specified player", type: "error" },
				{ root: true }
			);
		}
	},
};

const mutations = {
	setPlayerSearchData(state, data) {
		state.playerSearch.data = data;
	},
	setPlayerSearchLoading(state, value) {
		state.playerSearch.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
