var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-slide-y-reverse-transition",
        [
          _vm.messagesToShow.length > 0
            ? _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ hover }) {
                        return [
                          _c(
                            "v-snackbar",
                            {
                              staticClass: "pa-0 mb-6",
                              attrs: {
                                bottom: "",
                                value: true,
                                right: "",
                                timeout: -1,
                                color: "transparent",
                                elevation: "0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "notification",
                                  style: `${
                                    hover
                                      ? `min-height: ${
                                          Math.min(
                                            _vm.messagesToShow.length,
                                            5
                                          ) * 60
                                        }px;
								max-height: ${Math.min(_vm.messagesToShow.length, 5) * 60}px;
								 min-width: min(560px, 90vw)`
                                      : ""
                                  }`,
                                },
                                [
                                  _c(
                                    "v-scroll-y-transition",
                                    { attrs: { group: "" } },
                                    _vm._l(
                                      _vm.messagesToShow,
                                      function (message, index) {
                                        return _c(
                                          "v-alert",
                                          _vm._b(
                                            {
                                              key: message.id,
                                              staticClass:
                                                "ma-0 slide notification",
                                              class: {
                                                unique:
                                                  message.noteType === "unique",
                                              },
                                              staticStyle: {
                                                "transform-origin":
                                                  "bottom center",
                                                "padding-top":
                                                  "17px !important",
                                                width: "min(560px, 90vw)",
                                              },
                                              style: {
                                                transform: hover
                                                  ? ""
                                                  : `scale(${
                                                      1 -
                                                      (_vm.messagesToShow
                                                        .length -
                                                        1 -
                                                        index) *
                                                        0.08
                                                    })`,
                                                bottom: `${
                                                  (_vm.messagesToShow.length -
                                                    1 -
                                                    index) *
                                                  (hover ? 70 : 20)
                                                }px`,
                                              },
                                              attrs: {
                                                dense: "",
                                                icon: false,
                                                dismissible: "",
                                                elevation: "12",
                                              },
                                              on: {
                                                input: (e) =>
                                                  _vm.removeFromErrors(
                                                    e,
                                                    message
                                                  ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "prepend",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              size: "20",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getIcon(
                                                                    message
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "close",
                                                    fn: function ({ toggle }) {
                                                      return [
                                                        _c(
                                                          "v-progress-circular",
                                                          {
                                                            attrs: {
                                                              size: "25",
                                                              value:
                                                                100 -
                                                                _vm.compTime(
                                                                  message
                                                                ),
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "21",
                                                                },
                                                                on: {
                                                                  click: toggle,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-close-circle "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            "v-alert",
                                            _vm.getType(message),
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(message.noteMessage) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3605198638
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }