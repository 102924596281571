import jwt_decode from "jwt-decode";
import { inflate } from "pako";
import { constants } from "../../constants/constants";
import router from "../../router";

const state = {
	user: {
		isLoggedIn: false,
		data: {
			first_name: "",
			last_name: "",
			email: "",
			user_id: "",
			is_admin: "",
			permissions: "",
		},
	},
	loginSuccess: false,
	drawerOn: true,
	drawerLoading: false,
	drawerTimeout: undefined,
	refresh_timeout: undefined,
};

const getters = {
	user: (state) => {
		return state["user"];
	},
	userPerm: (state, rootGetter) => {
		return rootGetter.allowedSections;
	},
	userFullName: (state) => {
		return (
			state["user"]["data"]["first_name"] +
			" " +
			state["user"]["data"]["last_name"]
		);
	},
	userEmail: (state) => {
		return state["user"]["data"]["email"];
	},
	userId: (state) => {
		return state["user"]["data"]["_id"];
	},
	userIsAdmin: (state) => {
		return state["user"]["data"]["is_admin"];
	},
	allowedCasinos: (state, rootGetter) => {
		const res = (rootGetter.permissionObject?.logan?.casinos ?? []).map(
			(el) => {
				return (
					rootGetter.casinoList?.data?.find(
						(casino) => casino.casino_id == parseInt(el)
					) ?? {}
				);
			}
		);
		return res?.sort((el, el2) => el.casino_id - el2.casino_id);
	},
	allowedCategories: (state, rootGetter) => {
		const removed = rootGetter["categories/list"]
			.filter((el) => el.removed)
			.map((el) => el.name);
		return Array.from(
			rootGetter.allowedCasinos.reduce((agg, curr) => {
				if (curr.categories.length > 0) {
					curr.categories.forEach((el) => agg.add(el));
				}
				return agg;
			}, new Set())
		).filter((el) => !removed.includes(el));
	},

	casinoCategoryMap(state, rootGetter) {
		return rootGetter.allowedCasinos.reduce((agg, curr) => {
			curr.categories.forEach((c) => {
				agg.has(c) ? agg.set(c, agg.get(c).concat([curr])) : agg.set(c, [curr]);
			});
			return agg;
		}, new Map());
	},
	categoryCasinoMap(state, rootGetter) {
		return Array.from(rootGetter.casinoCategoryMap).reduce(
			(agg, [category, casinos]) => {
				casinos.forEach((c) => {
					agg.set(c.casino_id, [...(agg.get(c.casino_id) || []), category]);
				});
				return agg;
			},
			new Map()
		);
	},
	allowedSections: (state, rootGetter) => {
		const res = rootGetter.permissionObject?.logan?.sections ?? {};
		return res;
	},
	allowedStudios: (state, rootGetter) => {
		const res = (rootGetter.permissionObject?.logan?.studios ?? []).map(
			(el) => {
				return (
					rootGetter["meta2/gameStudios"]?.find(
						(studio) => studio.name == el
					) ?? {}
				);
			}
		);
		return res?.sort((el, el2) => el.casino_id - el2.casino_id);
	},
	permissionObject: (state) => {
		return JSON.parse(
			new TextDecoder().decode(
				inflate(
					Buffer.from(
						state.user.data["permissions"] ?? "eJyrrgUAAXUA+Q==",
						"base64"
					)
				)
			)
		);
	},
	loginSuccess: (state) => {
		return state["loginSuccess"];
	},
	getDrawer: (state) => {
		return state["drawerOn"];
	},
	getDrawerLoading: (state) => {
		return state["drawerLoading"];
	},
};

const actions = {
	async login({ commit, dispatch, rootGetters }, { query }) {
		try {
			const res = await dispatch("apiCall/catGet", {
				path: "/api/v1/auth/authorize",
				params: query,
			});
			await dispatch("stateLoginData", res.data.access_token);
			commit("apiCall/setRefreshToken", res.data.refresh_token);
			commit("apiCall/setAccessToken", res.data.access_token);
			const authVersion = rootGetters.getAuthVersion;
			localStorage.setItem(
				constants.authLocalStorageKey,
				JSON.stringify({ ...res.data, authVersion: authVersion })
			);
			await dispatch("meta2/loadStudios", null, { root: true });
			await Promise.all([
				dispatch("getCasinoList", null, { root: true }),
				dispatch("meta2/loadAllowedGames", null, { root: true }),
			]);
			await dispatch("checkCurrentCasino", res.data.access_token);
		} catch (error) {
			console.error(
				"AUTH, actions, login, ERROR : ",
				error,
				"\n error response ",
				error.response
			);
			commit("setLoginSuccess", false);
			if (error.response?.data) {
				dispatch(
					"notification/setMessage",
					{ message: "Login error", type: "error" },
					{ root: true }
				);
			} else {
				dispatch(
					"notification/setMessage",
					{
						message: "Error during Authorization,\n see logs for more info",
						type: "error",
					},
					{ root: true }
				);
			}
		}
	},

	async stateLoginData({ commit }, accessToken) {
		await commit("login", jwt_decode(accessToken).identity);
	},

	async switchDrawer({ commit }, value) {
		commit("setDrawer", value);
		commit("setDrawerLoading");
	},

	async logout({ commit, dispatch }, saveData) {
		const resp = await dispatch("apiCall/catGet", {
			path: "/api/v1/auth/logout",
			protectedPath: true,
		});
		commit("logout");
		commit("apiCall/removeAccessToken", { root: true });
		commit("apiCall/removeRefreshToken", { root: true });
		setLocalstorageOnLeave(saveData);
		if (resp?.data) {
			window.location.href = `${resp.data}`;
		}
	},

	async kick({ commit }) {
		commit("logout");
		commit("apiCall/removeAccessToken", { root: true });
		commit("apiCall/removeRefreshToken", { root: true });
		setLocalstorageOnLeave();
		router.push({ name: "login" });
	},
};

function setLocalstorageOnLeave(data) {
	sessionStorage.removeItem(constants.breadcrumbsSessionStorageKey);
	localStorage.removeItem(constants.authLocalStorageKey);
	data &&
		localStorage.setItem(
			constants.authRedirectLocalStorageKey,
			JSON.stringify({
				name: data?.name,
				params: data?.params,
				query: data?.query,
			})
		);
	localStorage.getItem("nextNavTo", constants.authRedirectLocalStorageKey);
}

const mutations = {
	login(state, userData) {
		state.user.isLoggedIn = true;
		state.user.data = userData;
	},
	logout(state) {
		state.user.isLoggedIn = false;
		state.user.data = {};
	},
	setLoginSuccess(state, value) {
		state.loginSuccess = value;
	},
	setDrawerLoading(state) {
		clearTimeout(state.drawerLoading);
		state.drawerLoading = true;
		state.drawerTimeout = setTimeout(() => {
			state.drawerLoading = false;
		}, 350);
	},
	setDrawer(state, value) {
		const userSettings = JSON.parse(
			localStorage.getItem(constants.settingsLocalStorageKey)
		);
		localStorage.setItem(
			constants.settingsLocalStorageKey,
			JSON.stringify({ ...userSettings, ...{ mini_menu: value } })
		);
		state.drawerOn = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
