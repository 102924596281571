import FilterFactory from "../../../constants/converters/filterFactory";

const state = {
	sessionSearch: {
		data: undefined,
		isLoading: false,
	},
	config: [
		{
			type: "or",
			children: [
				{
					rKey: "session_mongo_id",
					value: "searchKey",
					type: "eq",
				},
				{
					rKey: "external",
					value: "searchKey",
					type: "eq",
				},
				{
					rKey: "internal",
					value: "searchKey",
					type: "eq",
				},
				{
					rKey: "token",
					value: "searchKey",
					type: "eq",
				},
				{
					rKey: "external_ticket_id",
					value: "searchKey",
					type: "eq",
				},
			],
		},
	],
};

const getters = {
	sessionSearch: (state) => {
		return state["sessionSearch"];
	},
};

const actions = {
	async loadSessionSearch(
		{ commit, dispatch, rootGetters },
		{ searchKey, noErr }
	) {
		commit("setSessionSearchLoading", true);
		try {
			const url = FilterFactory.from(state.config, {
				searchKey,
			}).build();
			console.log(url, "URL");

			const res = await dispatch("apiCall/loganGet", {
				path:
					"/api/v2/casinos/" +
					rootGetters.currentCasino +
					"/sessions" +
					`?${url}`,
				protectedPath: true,
			});
			commit("setSessionSearchData", res.data.data);
			commit("setSessionSearchLoading", false);
		} catch (error) {
			if (error.message === "canceled") {
				throw error;
			}
			commit("setSessionSearchData", undefined);
			commit("setSessionSearchLoading", false);
			if (noErr) return;
			console.error(" js load session search error > \n", error);
			dispatch(
				"notification/setMessage",
				{ message: "Failed to get specified session", type: "error" },
				{ root: true }
			);
		}
	},
};

const mutations = {
	setSessionSearchData(state, data) {
		state.sessionSearch.data = data;
	},
	setSessionSearchLoading(state, value) {
		state.sessionSearch.isLoading = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
