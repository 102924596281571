const state = {
	sessionId: undefined,
	sessionRoundListFilter: {
		order_by: "date",
		order_as: "desc",
		pagination: {
			limit: 20,
			page: 1,
		},
	},
	sessionRoundList: {
		data: {
			data: [],
			pagination: {
				limit: 0,
				page: 1,
				total_pages: 1,
				total_records: 0,
			},
		},
		isLoading: false,
	},
};

const getters = {
	sessionRoundListFilter: (state) => {
		return state["sessionRoundListFilter"];
	},
	sessionRoundListPage: (state) => {
		return state["sessionRoundListFilter"]["pagination"]["page"];
	},
	sessionRoundListEntries: (state) => {
		return state["sessionRoundListFilter"]["pagination"]["limit"];
	},
	sessionRoundListSorting: (state) => {
		return {
			orderBy: state["sessionRoundListFilter"]["order_by"],
			orderAs: state["sessionRoundListFilter"]["order_as"],
		};
	},
	sessionRoundList: (state) => {
		return state["sessionRoundList"];
	},
	sessionRoundListSessionId: (state) => {
		return state["sessionId"];
	},
};

const actions = {
	async setSessionRoundListPage({ commit, dispatch }, page) {
		commit("setSessionRoundListPage", page);
		dispatch("loadSessionRoundList");
	},

	async setSessionRoundListEntries({ commit, dispatch }, entries) {
		commit("setSessionRoundListEntries", entries);
		dispatch("loadSessionRoundList");
	},

	async setSessionRoundListSorting(
		{ commit, getters, dispatch },
		sortingParams
	) {
		if (
			sortingParams.orderBy !== getters["sessionRoundListSorting"].orderBy ||
			sortingParams.orderAs !== getters["sessionRoundListSorting"].orderAs
		) {
			commit("setSessionRoundListSorting", sortingParams);
			dispatch("loadSessionRoundList");
		}
	},

	async setSessionId({ commit }, sessionId) {
		commit("setupSessionId", sessionId);
	},

	async loadSessionRoundList({ commit, dispatch, getters, rootGetters }) {
		commit("setSessionRoundListLoading", true);
		const filter = getters.sessionRoundListFilter;
		try {
			const res = await dispatch("apiCall/loganPost", {
				path:
					"/api/v1/casinos/" +
					rootGetters.currentCasino +
					"/sessions/" +
					getters.sessionRoundListSessionId +
					"/rounds/filter",
				payload: filter,
				protectedPath: true,
			});
			await dispatch("addCurrencyToRoundList", res.data);
			commit("setSessionRoundListLoading", false);
		} catch (error) {
			commit("setSessionRoundListLoading", false);
		}
	},

	async addCurrencyToRoundList({ commit, getters }, roundList) {
		for (let round of roundList.data) {
			round.currency = getters.sessionDetail.data.currency;
		}
		commit("setSessionRoundListData", roundList);
	},
};

const mutations = {
	setSessionRoundListPage(state, value) {
		state.sessionRoundListFilter.pagination.page = value;
	},
	setSessionRoundListEntries(state, value) {
		state.sessionRoundListFilter.pagination.limit = value;
	},
	setSessionRoundListSorting(state, sortingParams) {
		state.sessionRoundListFilter.order_by = sortingParams.orderBy;
		state.sessionRoundListFilter.order_as = sortingParams.orderAs;
	},
	setSessionRoundListData(state, data) {
		state.sessionRoundList.data = data;
	},
	setSessionRoundListLoading(state, value) {
		state.sessionRoundList.isLoading = value;
	},
	setupSessionId(state, value) {
		state.sessionId = value;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
