function* getMsgId() {
	let val = 0;
	while (true) {
		yield val++;
	}
}
const generator = getMsgId();

const state = {
	duration: 4,
	messagesArr: [],
};

const getters = {
	arrayOfMessages() {
		return state.messagesArr;
	},
	errorDuration: (state) => {
		return state["duration"];
	},
};

const actions = {
	setMessage({ commit }, { message, type, duration }) {
		if (message != "" || type != "") {
			commit("pushMessageToArr", { message, type, duration });
		}
	},
	removeMessage({ commit }, message) {
		commit("removeFromArray", message);
	},
};

const mutations = {
	pushMessageToArr(state, { message, type, duration }) {
		const messageToPush = {
			noteMessage: message,
			noteType: type,
			duration: duration ?? state.duration,
			created: Date.now(),
			id: generator.next().value,
		};
		state.messagesArr.push(messageToPush);
		setTimeout(
			() =>
				state.messagesArr.splice(
					state.messagesArr.findIndex((el) => el == messageToPush),
					1
				),
			1000 * duration ?? state.duration
		);
	},

	removeFromArray(state, message) {
		state.messagesArr = state.messagesArr.filter((el) => el != message);
	},

	setDuration(state, value) {
		state.duration = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
