export default {
	list(state) {
		return state.list;
	},
	permGroups(state) {
		return state.groups;
	},
	casinoGroups(state) {
		return state.casinos;
	},
	studioGroups(state) {
		return state.studios;
	},
	detail(state) {
		return state.detail;
	},
};
